









































































































































































































































































































































import axios, { AxiosResponse } from 'axios';
import gql from 'graphql-tag';
import * as math from 'mathjs';
import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';

import {
  CategorizationStatus,
  Category,
  Connection,
  ConnectionCategory,
  ConnectionStatus,
  Contact,
  Maybe,
  MultiValueTransactionInput,
  NetworkContactInput,
  NetworkFeeCategoryInput,
  Providers,
  ReconciliationStatus,
  Transaction,
  TransactionLineView,
  TransactionLite,
  TransactionResult,
  TransactionView,
  TxnLineOperation,
  TxnType,
  Wallet,
} from '@/api-svc-types';
import TooltipSelect from '@/components/tooltip/TooltipSelect.vue';
import {
  looselyGetCategoryWithCode,
  multivalueTransactionDataFactory,
  splitToMultiValueTransactionItemInput,
} from '@/components/transactions/categorization/StandardCategorization/utilities';
import EditTransactionModal from '@/components/transactions/edit/EditTransactionModal.vue';
import inlineCategorization from '@/components/transactions/InlineCategorization.vue';
import TransactionTable from '@/components/transactions/TransactionTable.vue';
import UiPageHeader from '@/components/ui/UiPageHeader.vue';
import UiToggle from '@/components/ui/UiToggle.vue';
import Beta from '@/components/util/Beta.vue';
// import { RealtimeService } from '@/realtime/realtimeService';
import { CancelablePromise, CanceledError, makeCancelable } from '@/utils/CancelablePromise';
import { convertUnits, getMainUnitForCoin } from '@/utils/coinUtils';
import { parseParams } from '@/utils/endpointUrlUtil';
import { stringifyError } from '@/utils/error';
import { assertDefined, isDefined } from '@/utils/guards';
import { requestParentToNavigate } from '@/utils/iframeMessageRequester';

import { baConfig } from '../../../config';
import {
  ApiSvcTransactionStateTransitions,
  ApiSvcTransactionStateUpdateDTO,
  ApiSvcUpdateTransactionStatus200Response,
  TransactionsApi,
  TransactionsV2Api,
} from '../../../generated/api-svc';
import { BaseVue } from '../../BaseVue';
import CreateManualTransaction from '../../components/transactions/CreateManualTransaction.vue';
import NewTransactionBanner from '../../components/transactions/NewTransactionBanner.vue';
import SaveInline from '../../components/transactions/SaveInline.vue';
import TransactionTable2, { TxnVM } from '../../components/transactions/TransactionTable2.vue';
import UiButton from '../../components/ui/UiButton.vue';
import UiDataTable from '../../components/ui/UiDataTable.vue';
import UiDatePicker2 from '../../components/ui/UiDatePicker2.vue';
import UiDropdown from '../../components/ui/UiDropdown.vue';
import UiLoading from '../../components/ui/UiLoading.vue';
import UiModal from '../../components/ui/UiModal.vue';
import UiSelect from '../../components/ui/UiSelect.vue';
import UiSelect2 from '../../components/ui/UiSelect2.vue';
import UiTabs from '../../components/ui/UiTabs.vue';
import UiTextEdit from '../../components/ui/UiTextEdit.vue';
import UiTooltip from '../../components/ui/UiTooltip.vue';
import { WalletsQuery } from '../../queries/transactionsPageQuery';
import { ellipsis } from '../../utils/stringUtils';
import BulkEditTransactionModal from './BulkEditTransactionModal2.vue';

const wellKnownAddresses: Record<string, string> = {
  'ROSE:OASIS1QRMUFHKKYYF79S5ZA2R8YGA9GNK4T446DCY3A5ZM': 'Oasis common pool',
  'ROSE:OASIS1QQNV3PEUDZVEKHULF8V3HT29Z4CTHKHY7GKXMPH5': 'Oasis fee accumulator',
};

@Component({
  computed: {
    ApiSvcTransactionStateTransitions() {
      return ApiSvcTransactionStateTransitions;
    },
  },
  components: {
    TransactionTable,
    SaveInline,
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiTextEdit,
    UiDataTable,
    UiTabs,
    UiToggle,
    UiSelect2,
    CreateManualTransaction,
    inlineCategorization,
    BulkEditTransactionModal,
    EditTransactionModal,
    TransactionTable2,
    TooltipSelect,
    UiModal,
    Beta,
    UiDatePicker2,
    NewTransactionBanner,
    UiPageHeader,
  },
  apollo: {
    wallets: {
      query: WalletsQuery,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            orgId: this.$store.state.currentOrg.id,
          };
        } else {
          return false;
        }
      },
      loadingKey: 'isLoadingWallets',
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            isDisabled
            isDeleted
            category
            name
            feeAccountCode
            isDefault
            lastSyncSEC
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoadingConnections',
      update(data: { connections?: Connection[] }) {
        return (
          data.connections?.filter((x) => !x.isDeleted && x.category === ConnectionCategory.AccountingConnection) ?? []
        );
      },
    },
  },
})
export default class TransactionsNew4 extends BaseVue {
  // #region Core data / logic
  public transactionsLite: TransactionResult = {
    orgId: '',
    transactions: [],
  }; // populated via restEndpoint

  public previousPromise: CancelablePromise<any> | null = null;
  public previousCountsPromise: CancelablePromise<any> | null = null;

  public transactionCounts = { needsCategorization: 0, toBeReconciled: 0, all: 0 }; // populated via api call
  declare connections: Connection[]; // populated via apollo

  // private realtime: RealtimeService = new RealtimeService();

  @Ref('createTransactionModal')
  public readonly createTransactionModal!: typeof CreateManualTransaction;

  public newTxnCount = 0;
  public showTxnBanner = false;
  public hasTransactionLoadError = false;
  public isLoadingWallets = 0;
  public isLoadingCounts = 0;
  public isLoadingTransactions = 0;
  public isLoadingConnections = 0;
  public isLoadingExchangeRates = 0;
  public isLoadingUniqueValues = 0;
  public showFilters = true;
  public searchToken = '';
  public setDirtyTxns: string[] = [];
  public displayMode = 'min';
  public vars = {
    transactionFilter: {
      categorizationFilter: 'All',
      reconciliationFilter: 'Unreconciled',
      ignoreFilter: 'Unignored',
      walletsFilter: ['All'],
      searchTokens: undefined as string[] | undefined,
      errored: undefined as boolean | undefined,
      pivotDate: new Date().toISOString().substring(0, 10),
    },
    limit: '50',
    prevToken: undefined as string | undefined,
    nextToken: undefined as string | undefined,
  };

  public coinLookup = new Map<string, string>();
  public networkLookup = new Map<string, string>();
  public selectedTxns: TransactionLite[] = [];
  public apiBaseUrl = process.env.VUE_APP_API_URL;
  public visibleHeaders = [...this.headers.filter((h) => h.defaultVisibility).map((h) => h.id)];
  public ReconciliationStatus = ReconciliationStatus;
  public txnToDelete: TransactionLite | null = null;
  public txnToEdit: TransactionLite | null = null;
  public editDialog = false;
  public deleteDialog = false;
  public isSoftLoading = 0;
  public wallets: Wallet[] = [];
  public countTab: string | null = null;
  public isNew = false;
  public headerHeight = '0px';
  public currentRateCallIndex = 0;
  public batchSize = 5;
  public batchToken = Date.now();
  public txnsSvcURL = process.env.VUE_APP_TXN_SVC_URL;
  public txnsWatched: string[] = []; // txns we are waiting for responses from combine/uncombine events (refresh txns when all are returned)
  defaultStartDate = '2000-01-01T00:00:00Z';
  cachedRecordDateTime = this.defaultStartDate;
  firstRecordDateTime: string | null = null;

  public closeEditTransaction() {
    this.editDialog = false;
    this.txnToEdit = null;
  }

  public editTransaction(txn: TransactionLite) {
    this.txnToEdit = txn;
    this.editDialog = true;
  }

  public get calcWallets() {
    return this.wallets;
  }

  public get pivotDateVar() {
    return this.vars.transactionFilter?.pivotDate || undefined;
  }

  public get displayTxns() {
    return this.hasTransactionLoadError ? [] : (this.transactionsLite?.transactions ?? []).filter(isDefined);
  }

  get coins() {
    return [] as { ticker: string; unit: string; currencyId: string }[]; // this.transactionsLite?.coins ?? [];
  }

  public get connectionList(): Connection[] {
    const categories = this.categories;
    const contacts = this.contacts;

    let connections = this.connections ?? [];

    if (
      (contacts?.some((x: any) => !x.accountingConnectionId || x.accountingConnectionId === 'Manual') ?? false) ||
      (categories?.some((x: any) => !x.accountingConnectionId || x.accountingConnectionId === 'Manual') ?? false)
    ) {
      const manualAccountingConnection = {
        id: 'Manual',
        provider: Providers.Manual,
        status: ConnectionStatus.Ok,
      };
      connections = connections.concat(manualAccountingConnection);
    }

    if (connections && connections.length > 0) {
      return connections;
    } else {
      return [];
    }
  }

  public get headers() {
    const fmvHeaderName = `Fair Market Value (${this.fiat.name})`;
    return [
      {
        id: 'transactionType',
        label: 'Type',
        defaultVisibility: true,
        defaultWidth: '80px',
        sortable: true,
        filterable: true,
      },
      {
        id: 'id',
        label: 'Transaction Id',
        defaultVisibility: true,
        defaultWidth: '128px',
        sortable: true,
        filterable: true,
      },
      {
        id: 'date',
        label: `Date (${moment.tz(this.preferredTimezone).format('z')})`,
        defaultVisibility: true,
        defaultWidth: '152px',
        sortable: true,
        filterable: true,
        rangeFilter: true,
        type: 'date',
      },
      {
        id: 'wallets',
        label: 'Wallet',
        defaultVisibility: true,
        defaultWidth: '107px',
        sortable: true,
        filterable: false,
      },
      {
        id: 'from',
        label: 'From',
        defaultVisibility: true,
        defaultWidth: '142px',
        sortable: true,
        filterable: true,
        isStatic: true,
      },
      {
        id: 'to',
        label: 'To',
        defaultVisibility: true,
        defaultWidth: '142px',
        sortable: true,
        filterable: true,
        isStatic: true,
      },

      {
        id: 'tickers',
        label: 'Ticker',
        defaultVisibility: true,
        defaultWidth: '95px',
        filterable: true,
        sortable: true,
      },
      {
        id: 'amount',
        label: 'Amount',
        defaultVisibility: true,
        defaultWidth: '99px',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      { id: 'metadata', label: 'Metadata', defaultVisibility: false, defaultWidth: '250px' },
      {
        id: 'fmv',
        label: fmvHeaderName,
        defaultVisibility: true,
        defaultWidth: '120px',
        isStatic: true,
        filterable: true,
        rangeFilter: true,
        sortable: true,
      },
      {
        id: 'status',
        label: 'Status',
        defaultVisibility: true,
        defaultWidth: '72px', // 100px when sortable true
        sortable: true,
        filterable: true,
        isStatic: true,
        singleSelectFilter: true,
      },
    ];
  }

  get preferredTimezone() {
    const useOrgTimezone = this.$store.state.currentOrg.displayConfig?.useOrgTimezone ?? false;
    return useOrgTimezone ? this.$store.state.currentOrg.timezone : moment.tz.guess();
  }

  public get fiat() {
    const baseCurrency = this.$store.state.currentOrg.baseCurrency ?? 'USD';
    return (
      this.$store.getters['fiats/FIATS']?.find(
        (fiat: { name: string; symbol: string }) => fiat.name === baseCurrency
      ) ?? {
        name: baseCurrency,
        symbol: '$',
      }
    );
  }

  public get isLoading() {
    return !!this.isLoadingTransactions || !!this.isSoftLoading;
  }

  public get walletItems() {
    return [{ id: 'All', name: 'All Wallets' }, ...this.wallets.map((w) => ({ id: w.id, name: w.name }))];
  }

  public onDateChange(date: string) {
    if (this.isValidDate(date)) {
      this.vars.transactionFilter.pivotDate = date;
    } else {
      this.vars.transactionFilter.pivotDate = moment().utc().format('YYYY-MM-DD');
      this.cachedRecordDateTime = this.firstRecordDateTime ?? this.defaultStartDate;
    }

    this.refresh();
  }

  isDateDisabled(date: Date) {
    // use the first_record_date in date format
    const calendarTimeUnix = moment(date).valueOf();
    const minTimeUnix = moment(this.firstRecordDateTime ?? this.defaultStartDate).valueOf();
    const nowUnix = new Date().valueOf();

    return calendarTimeUnix > nowUnix || calendarTimeUnix < minTimeUnix;
  }

  public setPageToken(key: 'nextToken' | 'prevToken', value: string) {
    this.vars.nextToken = undefined;
    this.vars.prevToken = undefined;
    this.vars[key] = value;
    this.refresh();
  }

  isValidDate(dateString: string) {
    // Parse the date string into a Date object
    const dateObj = new Date(dateString);

    // Check if the date object is valid and the string matches the expected format
    return !isNaN(dateObj.getTime()) && /^\d{4}-\d{2}-\d{2}$/.test(dateString);
  }

  public async toggleFilters() {
    this.showFilters = !this.showFilters;
    await this.$nextTick();
  }

  public toggleUi(val: boolean) {
    if (val) {
      requestParentToNavigate('/transactionsv2', { new: true });
    }

    // used on the old web-app UI for transactions
    // this.$emit('toggleUi', val);
  }

  public get selectedTxnsSet(): Set<TransactionLite> {
    return new Set(this.selectedTxns);
  }

  public onSelectionChanged(txns: TransactionLite[]) {
    this.$set(this, 'selectedTxns', txns);
    this.showFilters = !(this.selectedTxns.length > 0);
  }

  public get categories(): Category[] {
    return this.$store.getters['categories/ENABLE_CATEGORIES'];
  }

  public get contacts(): Contact[] {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  public async refresh() {
    // apply this to activate the filters
    this.onSelectionChanged([]);
    this.showTxnBanner = false;
    await Promise.all([
      this.$store.dispatch('categories/getCategories', this.$store.state.currentOrg.id),
      this.$store.dispatch('contacts/getContacts', this.$store.state.currentOrg.id),
      this.callRestEndpoint(),
      this.callCountsEndpoint(),
    ]);
    this.callAllUniqueValuesEndpoint();
  }

  /**
   * fetch the contact information for a transaction
   *
   * passed through SaveInline to child components for auto-setting contact
   *
   * @param transaction the transaction that the contact is being fetched for
   * @return {string|undefined} the name or address of the contact for the transaction
   *
   */
  public getContact(transaction: Transaction): string | undefined {
    try {
      const selfAddresses: string[] = [];
      const networkId = transaction.networkId;
      this.$store.state.wallets.wallets?.map((wallet) => {
        wallet.addresses?.forEach((address) => {
          try {
            if (!address) throw new Error('no address');
            selfAddresses.push(address);
          } catch (error) {}
        });
      });

      let transactionContact;

      // get the transaction transfer logs
      const transferLogs = transaction.txnLogs?.filter(
        (log) => log?.type === 'transfer-log' && log.from?.address !== '0x0000000000000000000000000000000000000000'
      );

      if (!transferLogs) throw new Error('no transfer logs');

      // get the first transfer log
      const firstLog = transferLogs[0];

      if (!firstLog) return undefined;

      // get participant addresses from the logs
      const fromAddress = firstLog.from?.address;
      const toAddress = firstLog.to?.address;

      if (!fromAddress || !toAddress) return undefined;

      let contactAddress;

      // handle contact lookup
      if (!networkId) throw new Error('no networkId');

      switch (transaction.type) {
        case TxnType.Send:
          contactAddress = toAddress;
          transactionContact = this.lookupAddress(contactAddress, networkId, firstLog?.asset?.symbol);
          break;
        case TxnType.Receive:
          contactAddress = fromAddress;
          transactionContact = this.lookupAddress(contactAddress, networkId, firstLog?.asset?.symbol);
          break;

        default:
          // handle unknown transactions
          if (selfAddresses.map((address) => address.toLocaleLowerCase()).includes(fromAddress.toLocaleLowerCase())) {
            contactAddress = toAddress;
          } else {
            contactAddress = fromAddress;
          }
          transactionContact = this.lookupAddress(contactAddress, networkId, firstLog?.asset?.symbol);

          break;
      }

      return transactionContact;
    } catch (error) {}
  }

  public lookupAddress(address: string | null | undefined, networkId?: string, coin?: string | null) {
    const key = `${networkId?.toUpperCase() ?? 'UNKNOWN'}:${address?.toUpperCase() ?? ''}`;
    const contactKey = `${coin?.toUpperCase() ?? 'UNKNOWN'}:${address?.toUpperCase() ?? ''}`;
    return this.addressLookup[key] ?? this.addressLookup[contactKey] ?? (address ? ellipsis(address, 20) : 'Unknown');
  }

  public get addressLookup() {
    const contacts = this.contacts;
    const wallets = this.wallets;

    let result = wallets.reduce((a, x) => {
      x.addresses?.forEach((addr) => {
        if (x.name && addr) {
          const upperAddr = addr.toUpperCase();
          const network = x.networkId?.toUpperCase() ?? 'UNKNOWN';
          if (upperAddr.startsWith('OASIS') && !upperAddr.endsWith(':ESCROW') && !a[`${network}:${upperAddr}:ESCROW`]) {
            a[`${network}:${upperAddr}:ESCROW`] = '(Escrow) ' + x.name;
          }
          a[`${network}:${upperAddr}`] = x.name;
        }
      });
      return a;
    }, {} as Record<string, string>);

    result = contacts.reduce((a, x) => {
      for (const addr of x.addresses ?? []) {
        if (addr?.address) {
          a[`${addr?.networkId?.toUpperCase()}:${addr.address.toUpperCase()}`] = x?.name ?? '';
        }
      }
      return a;
    }, result);

    return { ...result, ...wellKnownAddresses };
  }

  public async execDeleteSelectedTransactions() {
    const combinedTxn = this.selectedTxns.find((txn: TransactionLite) => txn.isCombined);
    if (combinedTxn) {
      this.showErrorSnackbar(this.$tc('_cannotDeleteCombinedTransaction'));
      return;
    }

    const hasConfirmation = confirm('Are you sure you want to delete selected transactions?');
    if (!hasConfirmation) {
      return;
    }

    try {
      this.isSoftLoading++;
      const selectedIds = this.selectedTxns.map((m) => m.id);
      for (const s of this.selectedTxns) {
        const vars = {
          orgId: this.$store.state.currentOrg.id,
          id: s.id,
        };
        const res = await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!, $id: ID!) {
              deleteTransaction(orgId: $orgId, id: $id)
            }
          `,
          variables: vars,
        });

        if ((res.errors && res.errors.length > 0) || !res.data.deleteTransaction) {
          const errors = [];
          if (res.errors && res.errors.length > 0) {
            errors.push(...res.errors);
          }

          this.showErrorSnackbar(this.$tc('_deletedTransactionFailure') + ': ' + JSON.stringify(errors.join('<br />')));
        } else {
          this.showSuccessSnackbar(this.$tc('_deletedTransactionsSuccess', selectedIds.length));
          this.transactionsLite.transactions = this.transactionsLite.transactions.filter((m) => s.id !== m?.id);
        }
      }
    } catch (err) {
      this.showErrorSnackbar(this.$tc('_deletedTransactionFailure') + ': ' + stringifyError(err));
    } finally {
      this.onSelectionChanged([]);
      this.isSoftLoading--;
    }
  }

  public async unDeleteTransactions() {
    try {
      this.isSoftLoading++;
      const ids = this.selectedTxns.map((m) => m.id);

      const vars = {
        txnIds: ids,
      };

      const orgId = this.$store.state.currentOrg?.id;
      const url = `${baConfig.apiUrl}/orgs/${orgId}/transactions!undelete`;
      const res = await axios.put(url, vars, { withCredentials: true });

      if (res.status === 200 && res.data) {
        this.showSuccessSnackbar('Successfully Undeleted Transactions');
        this.refresh();
      }
    } catch (err) {
      this.showErrorSnackbar('Failed to undelete transactions' + ': ' + stringifyError(err));
    } finally {
      this.isSoftLoading--;
      this.onSelectionChanged([]);
    }
  }

  public combineSelectedTransactions() {
    if (this.selectedTxns.length < 2) {
      throw new Error("Can't combine less than 2 transactions");
    }

    this.isSoftLoading++;
    const txnIds = [...this.selectedTxns].map((m) => m.id || '');
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      txnIds,
    };
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $txnIds: [ID]!) {
            combineTransactions(orgId: $orgId, txnIds: $txnIds) {
              id
            }
          }
        `,
        variables: vars,
      })
      .then((res) => {
        if (res.errors && res.errors.length > 0) {
          const errors = [];
          if (res.errors && res.errors.length > 0) {
            errors.push(...res.errors);
          }

          this.showErrorSnackbar(
            this.$tc('_combinedTransactionsFailure', this.selectedTxns.length) +
              ': ' +
              JSON.stringify(errors.join('<br />'))
          );
        } else {
          this.showSuccessSnackbar(this.$tc('_combinedTransactionsSuccess', this.selectedTxns.length));
        }
        this.txnsWatched.push(res.data?.combineTransactions?.id);
        // this.refresh();
      })
      .catch((err) => {
        this.showErrorSnackbar(
          this.$tc('_combinedTransactionsFailure', this.selectedTxns.length) + ': ' + JSON.stringify(err.message)
        );
      })
      .finally(() => {
        this.onSelectionChanged([]);
        this.isSoftLoading--;
      });
  }

  public async setIgnoreSelectedTransactions(ignored: boolean) {
    const transistion = ignored ? 'ignore' : 'un-ignore';
    await this.updateTxnState(transistion);
  }

  public async updateTxnState(transition: ApiSvcTransactionStateTransitions) {
    const orgId = this.$store.state.currentOrg.id;
    const tApi = new TransactionsV2Api(undefined, baConfig.getFriendlyApiUrl());
    const transactionIds = [...this.selectedTxns].map((txn) => txn?.id).filter((id) => id);

    const callPromises: Record<string, Promise<AxiosResponse<ApiSvcUpdateTransactionStatus200Response, any>>> = {};
    let successVerbiage = '';

    try {
      this.isSoftLoading++;
      const errors: Record<string, string> = {};

      for (const txn of this.selectedTxns) {
        const txnId = txn.id;
        if (!txnId) {
          throw new Error('Txn has unexpected undefined id');
        }

        let shouldFire = false;
        switch (transition) {
          case 'mark-ready-to-sync': {
            successVerbiage = 'Marked Transaction Ready to Sync';
            if (
              txn.reconciliationStatus !== ReconciliationStatus.Reconciled &&
              txn.categorizationStatus === CategorizationStatus.Categorized
            ) {
              shouldFire = true;
            } else {
              errors[txnId] = 'Transaction must be categorized not already synced';
            }
            break;
          }
          case 'ignore': {
            successVerbiage = 'Ignored Transactions';
            if (!txn.ignored) {
              shouldFire = true;
            } else {
              errors[txnId] = 'Transaction already ignored';
            }
            break;
          }
          case 'mark-synced': {
            successVerbiage = 'Marked Transactions Synced';
            if (txn.reconciliationStatus !== ReconciliationStatus.Reconciled) {
              shouldFire = true;
            } else {
              errors[txnId] = 'Transaction already ignored';
            }
            break;
          }
          case 'un-mark-synced': {
            successVerbiage = 'Un-Marked Transactions Synced';
            // TODO-BABEL [pw]: This should actually be based off of state, not just reconciliation status
            if (txn.reconciliationStatus === ReconciliationStatus.Reconciled && txn.ignored !== true) {
              shouldFire = true;
            } else {
              errors[txnId] = 'Transaction must be synced and not ignored';
            }
            break;
          }
          case 'mark-not-ready-to-sync': {
            successVerbiage = 'Cancelled Transaction Syncing';
            // TODO-BABEL [pw]: MUST be based off state
            if (txn.reconciliationStatus !== ReconciliationStatus.Reconciled) {
              shouldFire = true;
            } else {
              errors[txnId] = 'Transaction already fully synced';
            }
            break;
          }
          case 'un-ignore': {
            successVerbiage = 'Unignored Transactions';
            if (txn.ignored) {
              shouldFire = true;
            } else {
              errors[txnId] = 'Transaction not ignored';
            }
            break;
          }
        }
        if (shouldFire) {
          const update: ApiSvcTransactionStateUpdateDTO = {
            update: transition,
          };

          const p = tApi.updateTransactionStatus(orgId, txnId, update, { withCredentials: true });
          // TODO-BABEL @tyler Let's create a standard bulk update component that can take a structure like this and
          //   tick off all items with a progress
          callPromises[txnId] = p;
        }
      }
      await Promise.all(Object.values(callPromises));
      this.showSuccessSnackbar(`Successfully ${successVerbiage}`);
      this.refresh();
    } catch (e) {
      this.showErrorSnackbar((e as Error).message);
    } finally {
      this.onSelectionChanged([]);
      this.isSoftLoading--;
    }
  }

  public async setReconcileSelectedTransactions(reconciliationStatus: ReconciliationStatus) {
    if (this.selectedTxns.length === 0) {
      throw new Error('Need at least one transaction to ignore');
    }

    const txnIds = [...this.selectedTxns].map((m) => m.id);

    const errors = [];

    try {
      this.isSoftLoading++;
      for (const txnId of txnIds) {
        // const vars = ;

        let mutation = null;

        if (reconciliationStatus === ReconciliationStatus.Reconciled) {
          mutation = gql`
            mutation ($orgId: ID!, $id: ID!) {
              markTransactionAsReconciled(orgId: $orgId, id: $id) {
                id
              }
            }
          `;
        } else {
          mutation = gql`
            mutation ($orgId: ID!, $id: ID!) {
              unreconcileTransaction(orgId: $orgId, id: $id) {
                id
              }
            }
          `;
        }

        const res = await this.$apollo.mutate({
          mutation,
          variables: {
            orgId: this.$store.state.currentOrg.id,
            id: txnId,
          },
        });

        if (res.errors && res.errors.length > 0) {
          if (res.errors && res.errors.length > 0) {
            errors.push(...res.errors);
          }
        }
      }
    } catch (err) {
      errors.push(stringifyError(err));
    } finally {
      this.onSelectionChanged([]);
      this.isSoftLoading--;
    }

    if (errors.length > 0) {
      const m =
        reconciliationStatus === 'Reconciled' ? '_reconcileTransactionsFailure' : '_unReconcileTransactionsFailure';
      this.showErrorSnackbar(this.$tc(m, this.selectedTxns.length) + ': ' + JSON.stringify(errors.join('<br />')));
      await this.refresh();
    } else {
      const m =
        reconciliationStatus === 'Reconciled' ? '_reconcileTransactionsSuccess' : '_unReconcileTransactionsSuccess';
      this.showSuccessSnackbar(this.$tc(m, this.selectedTxns.length));
      await this.refresh();
      this.transactionsLite.transactions?.forEach((txn) => {
        if (txn && txnIds.includes(txn.id)) {
          txn.reconciliationStatus = reconciliationStatus;
        }
      });
    }
  }

  public async uncategorizeTxns() {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      transactionIds: [...this.selectedTxns].map((txn) => txn?.id).filter((id) => id),
    };

    try {
      this.isSoftLoading++;
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation UncategorizeTransactions($orgId: ID!, $transactionIds: [ID!]!) {
            uncategorizeTransactions(orgId: $orgId, transactionIds: $transactionIds)
          }
        `,
        variables: vars,
      });

      if (res.data) {
        this.showSuccessSnackbar('Successfully Uncategorized Transactions');
        this.refresh().then(() => {
          this.transactionsLite.transactions?.forEach((txn) => {
            if (txn && vars.transactionIds.includes(txn.id)) {
              txn.categorizationStatus = CategorizationStatus.Uncategorized;
            }
          });
        });
      } else {
        throw Error('Problem Updating Transaction');
      }
    } catch (e) {
      this.showErrorSnackbar((e as Error).message);
    } finally {
      this.onSelectionChanged([]);
      this.isSoftLoading--;
    }
  }

  public ignoreTxn({ txn, ignore }: { txn: TransactionLite; ignore: boolean }) {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
      ignore,
    };

    this.isSoftLoading++;
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!, $ignore: Boolean!) {
            updateTransactionIgnoreStatus(orgId: $orgId, id: $id, ignore: $ignore) {
              id
            }
          }
        `,
        variables: vars,
      })
      .then(() => {
        this.refresh().then(() => {
          const t = this.transactionsLite.transactions?.find((txn) => txn?.id === vars.id);
          if (t) {
            t.ignored = vars.ignore;
          }
        });
      })
      .finally(() => this.isSoftLoading--);
  }

  public reconcileTxn(txn: TransactionLite) {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
    };

    this.isSoftLoading++;
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!) {
            markTransactionAsReconciled(orgId: $orgId, id: $id) {
              id
            }
          }
        `,
        variables: vars,
      })
      .then((d) => {
        if (d.data) {
          this.showSuccessSnackbar('Successfully Reconciled Transaction');
          this.refresh();
        } else {
          this.showErrorSnackbar('Problem Reconciled Transaction');
          this.refresh();
        }
      })
      .catch((err) => {
        this.showErrorSnackbar('Error Reconciling ' + err);
      })
      .finally(() => this.isSoftLoading--);
  }

  public unreconcileTxn(txn: TransactionLite) {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
    };

    this.isSoftLoading++;
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!) {
            unreconcileTransaction(orgId: $orgId, id: $id) {
              id
            }
          }
        `,
        variables: vars,
      })
      .then((d) => {
        if (d.data.unReconcileTransaction) {
          this.showSuccessSnackbar('Successfully Unreconciled Transaction');
          this.refresh().then(() => {
            const t = this.transactionsLite.transactions?.find((txn) => txn?.id === vars.id);
            if (t) {
              t.reconciliationStatus = 'Unreconciled';
            }
          });
        } else {
          this.showErrorSnackbar('Problem Unreconciling Transaction');
          this.refresh();
        }
      })
      .catch((err) => {
        this.showErrorSnackbar('Error Unreconciling ' + err);
      })
      .finally(() => this.isSoftLoading--);
  }

  public uncombineTxn(txn: TransactionView) {
    if (!txn.isCombinedParent) {
      this.showErrorSnackbar('Transaction must be combined');
      return;
    }
    if (
      txn.categorizationStatus === 'new' ||
      txn.categorizationStatus === 'ready-to-price' ||
      txn.categorizationStatus === 'failed-to-price' ||
      txn.categorizationStatus === 'priced' ||
      txn.categorizationStatus === 'ignored'
    ) {
      this.showErrorSnackbar('Transaction already categorized');
      return;
    }

    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
    };

    this.isSoftLoading++;
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!) {
            unCombineTransaction(orgId: $orgId, id: $id)
          }
        `,
        variables: vars,
      })
      .then((d) => {
        if (d.data.unCombineTransaction) {
          this.showSuccessSnackbar('Successfully Un-combined Transaction');
          // this.txnsWatched.push(txn.id);
          // this.refresh();
        } else {
          this.showErrorSnackbar('Problem Un-combining Transaction');
          this.refresh();
        }
      })
      .catch((err) => {
        this.showErrorSnackbar('Error Un-combining ' + err);
      })
      .finally(() => this.isSoftLoading--);
  }

  public deleteTxn(txn: TransactionLite) {
    if (txn.isCombined) {
      this.showErrorSnackbar(this.$tc('_cannotDeleteCombinedTransaction'));
      return;
    }
    this.txnToDelete = txn;
    this.deleteDialog = true;
  }

  public closeDelete() {
    this.txnToDelete = null;
    this.deleteDialog = false;
  }

  public execDeleteTxn() {
    if (this.txnToDelete === null) {
      this.showErrorSnackbar('Problem Deleting Transaction');
      return;
    }

    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: this.txnToDelete.id,
    };

    this.isSoftLoading++;
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!) {
            deleteTransaction(orgId: $orgId, id: $id)
          }
        `,
        variables: vars,
      })
      .then((d) => {
        if (d.data.deleteTransaction) {
          this.showSuccessSnackbar('Successfully Deleted Transaction');
          this.refresh();
          const txnIndex = this.transactionsLite.transactions?.findIndex((txn) => txn?.id === vars.id);
          if (txnIndex) this.transactionsLite.transactions?.splice(txnIndex, 1);
        } else {
          this.showErrorSnackbar('Problem Deleting Transaction');
          this.refresh();
        }
      })
      .catch((err) => {
        this.showErrorSnackbar('Error Deleting ' + err);
      })
      .finally(() => {
        this.closeDelete();
        this.isSoftLoading--;
      });
  }

  public async updateTransaction({
    txn,
    reconciliationStatus,
    categorizationStatus,
  }: {
    txn: TransactionLite;
    reconciliationStatus?: ReconciliationStatus;
    categorizationStatus?: CategorizationStatus;
  }) {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
      reconciliationStatus,
      categorizationStatus,
    };

    // reconciliationStatus: ReconciliationStatus,
    //   categorizationStatus: CategorizationStatus

    try {
      this.isSoftLoading++;
      const d = await this.$apollo.mutate({
        mutation: gql`
          mutation (
            $orgId: ID!
            $id: ID!
            $reconciliationStatus: ReconciliationStatus
            $categorizationStatus: CategorizationStatus
          ) {
            updateTransaction(
              orgId: $orgId
              id: $id
              reconciliationStatus: $reconciliationStatus
              categorizationStatus: $categorizationStatus
            ) {
              id
            }
          }
        `,
        variables: vars,
      });
      if (d.data.updateTransaction) {
        this.showSuccessSnackbar('Successfully Updated Transaction');
        this.refresh();
        this.refresh().then(() => {
          const t = this.transactionsLite.transactions?.find((txn) => txn?.id === vars.id);
          if (t) {
            t.categorizationStatus = vars.categorizationStatus ?? t.categorizationStatus;
            t.reconciliationStatus = vars.reconciliationStatus ?? t.reconciliationStatus;
          }
        });
      } else {
        this.showErrorSnackbar('Problem Updating Transaction');
        this.refresh();
      }
    } catch (err) {
      this.showErrorSnackbar('Error Updating ' + err);
    } finally {
      this.isSoftLoading--;
    }
  }

  createTransactionModalTrigger() {
    (this.createTransactionModal as any)?.trigger();
  }

  resetFilters() {
    this.countTab = null;
    this.vars.transactionFilter.categorizationFilter = 'All';
    this.vars.transactionFilter.reconciliationFilter = 'Unreconciled';
    this.vars.transactionFilter.ignoreFilter = 'Unignored';
    this.vars.transactionFilter.walletsFilter = ['All'];
    this.vars.transactionFilter.searchTokens = undefined;
    this.vars.transactionFilter.errored = undefined;
    this.vars.nextToken = undefined;
    this.vars.prevToken = undefined;
    this.$set(this, 'uniqueColumnValues', {});
    this.$set(this, 'filters', {});
    this.$set(this, 'sort', {});
    this.clearFilter();
    this.clearSort();
    this.searchToken = '';
  }

  public get countTabs() {
    return [
      {
        label: 'Needs Categorization',
        value: 'categorize',
        count: this.transactionCounts?.needsCategorization,
      },
      {
        label: 'To Be Reconciled',
        value: 'reconcile',
        count: this.transactionCounts?.toBeReconciled,
      },
      {
        label: 'All',
        value: 'all',
        count: this.transactionCounts?.all,
      },
    ];
  }

  public onWalletsChange(wallets: string[]) {
    // if wallets is empty, set to all
    // also set to all if all is the last selection
    // but if all is selected and another wallet is selected last, remove all
    if (wallets.length === 0) {
      this.vars.transactionFilter.walletsFilter = ['All'];
    } else if (wallets[wallets.length - 1] === 'All') {
      this.vars.transactionFilter.walletsFilter = ['All'];
    } else if (wallets.includes('All')) {
      this.vars.transactionFilter.walletsFilter = wallets.filter((w) => w !== 'All');
    } else {
      this.vars.transactionFilter.walletsFilter = wallets;
    }
  }

  public onDisplayMode(mode: string) {
    localStorage.setItem('displayMode', mode);
    window.pendo?.track('Transactions - Update Display Mode', { mode });
  }

  public onVisibleHeaders(headers: string[]) {
    localStorage.setItem('visibleHeaders', JSON.stringify(headers));
    window.pendo?.track('Transactions - Update Visible Headers', { headers });
  }

  public clearFilter() {
    (this.$refs?.transactionTable as any)?.clearFilter();
  }

  public clearSort() {
    (this.$refs?.transactionTable as any)?.clearSort();
  }

  async callAllUniqueValuesEndpoint() {
    if (this.isLoadingUniqueValues > 0) {
      return;
    }
    await Promise.all([
      this.callUniqueValuesEndpoint('from', ''),
      this.callUniqueValuesEndpoint('to', ''),
      this.callUniqueValuesEndpoint('tickers', ''),
      this.callUniqueValuesEndpoint('id', ''),
    ]);
    this.uniqueColumnValues.status = [
      'new',
      'ready-to-price',
      'failed-to-price',
      'priced',
      'categorized',
      'open-needs-review',
      'ready-to-sync',
      'syncing',
      'synced',
      'failed-to-sync',
      'marked-synced',
      'closed-needs-review',
      'ignored',
      'deleted',
    ];
    this.uniqueColumnValues.transactionType = ['Receive', 'Send', 'Trade', 'Transfer', 'ContractExecution'];
  }

  async callUniqueValuesEndpoint(field: string, startsWith: string) {
    if (field === 'status' || field === 'transactionType') {
      return;
    }
    this.isLoadingUniqueValues++;
    let mappedField = field;
    switch (field) {
      case 'from':
        mappedField = 'fromAddress';
        break;
      case 'to':
        mappedField = 'toAddress';
        break;
      case 'tickers':
        mappedField = 'amountCurrencyName';
        break;
      case 'id':
        mappedField = 'transactionId';
        break;
      case 'metadata':
        mappedField = 'metadata';
        break;
      case 'fmv':
        mappedField = 'fmv';
        break;
      case 'status':
        mappedField = 'status';
        break;
      case 'transactionType':
        mappedField = 'transactionType';
        break;
      default:
        mappedField = 'fromAddress';
        break;
    }
    const limit = 10;
    let dataUrl = `${this.txnsSvcURL}/orgs/${this.$store.state.currentOrg.id}/lookups?limit=${limit}&fieldName=${mappedField}`;
    if (startsWith) {
      dataUrl += `&startsWith=${startsWith}`;
    }
    const originalPromise = axios.get(dataUrl, {
      withCredentials: true,
    });
    const resp = await originalPromise;
    if (resp.status === 200) {
      this.$set(this.uniqueColumnValues, field, resp.data.values);
    }
    this.isLoadingUniqueValues--;
  }

  calcFilters(vars: any) {
    const mappedFilters = {} as any;
    const mappedSort = null as any;

    mappedFilters.fromAddresses = this.filters.from?.length ? this.filters.from : null;
    mappedFilters.toAddresses = this.filters.to?.length ? this.filters.to : null;
    mappedFilters.amountCurrencyNames = this.filters.tickers?.length ? this.filters.tickers : null;
    mappedFilters.transactionIds = this.filters.id?.length ? this.filters.id : null;
    mappedFilters.transactionTypes = this.filters.transactionType?.length ? this.filters.transactionType : null;
    if (vars?.transactionFilter.searchTokens)
      mappedFilters.transactionIds = [...(mappedFilters.transactionIds ?? []), ...vars?.transactionFilter.searchTokens];
    mappedFilters.walletIds =
      !vars?.transactionFilter.walletsFilter?.length || vars?.transactionFilter.walletsFilter?.[0] === 'All'
        ? null
        : vars?.transactionFilter.walletsFilter;
    const from = this.filters.date?.[0] ? this.filters.date[0] : '2000-01-01';
    const to =
      this.filters.date?.[1] &&
      moment
        .tz(this.filters.date[1], this.preferredTimezone)
        .isBefore(moment.tz(vars?.transactionFilter.pivotDate, this.preferredTimezone))
        ? this.filters.date[1]
        : vars?.transactionFilter.pivotDate;
    mappedFilters.dateRange = vars?.transactionFilter.pivotDate ? { from, to } : null;
    // operations
    mappedFilters.amountRange = this.filters.amount?.length
      ? { from: this.filters.amount[0], to: this.filters.amount[1] }
      : null;
    mappedFilters.fmvAmountRange = this.filters.fmv?.length
      ? { from: this.filters.fmv[0], to: this.filters.fmv[1] }
      : null;

    // const catFilters = (this.filters.status as string[])?.filter(
    //   (x) => x === 'Categorized' || x === 'Uncategorized'
    // ) ?? [];
    const catFilters =
      this.vars.transactionFilter.categorizationFilter !== 'All'
        ? [this.vars.transactionFilter.categorizationFilter]
        : null;
    const recFilters =
      this.vars.transactionFilter.reconciliationFilter !== 'All'
        ? [this.vars.transactionFilter.reconciliationFilter]
        : null;
    const ignoreFilters =
      this.vars.transactionFilter.ignoreFilter !== 'All' ? [this.vars.transactionFilter.ignoreFilter] : null;

    mappedFilters.state = this.filters.status?.[0] || null;

    // const recFilters = (this.filters.status as string[])?.filter((x) => x === 'Reconciled' || x === 'Unreconciled');
    mappedFilters.categorizationStatuses = catFilters?.length ? catFilters : null;
    mappedFilters.reconciliationStatuses = recFilters?.length ? recFilters : null;
    mappedFilters.ignoredStatuses = ignoreFilters?.length ? ignoreFilters : null;
    return mappedFilters;
  }

  async callCountsEndpoint() {
    this.isLoadingCounts++;
    const variables = () => {
      if (this.$store.state.currentOrg) {
        const vars = {
          ...this.vars,
          orgId: this.$store.state.currentOrg.id,
          limit: typeof this.vars.limit === 'string' ? Number(this.vars.limit) : this.vars.limit,
        };
        // the wallets filter can only be 10 items long maximum
        if (vars.transactionFilter?.walletsFilter?.length > 10) {
          vars.transactionFilter.walletsFilter = vars.transactionFilter.walletsFilter.slice(0, 10);
        }
        return vars;
      } else {
        return false;
      }
    };
    const vars = variables();
    try {
      if (this.previousCountsPromise) {
        this.previousCountsPromise.cancel();
      }
      const mappedFilters = this.calcFilters(vars);
      mappedFilters.categorizationStatuses = null;
      mappedFilters.reconciliationStatuses = null;
      mappedFilters.ignoredStatuses = ['Unignored'];

      const originalPromise = axios.post(
        `${this.txnsSvcURL}/orgs/${this.$store.state.currentOrg.id}/transactions/summary_v2`,
        { filters: mappedFilters },
        {
          withCredentials: true,
        }
      );
      const cancelablePromise = makeCancelable(originalPromise);
      this.previousCountsPromise = cancelablePromise;
      const resp = await cancelablePromise;
      this.transactionCounts.all = resp.data?.all || 0;
      this.transactionCounts.needsCategorization = resp.data?.needsCategorization || 0;
      this.transactionCounts.toBeReconciled = resp.data?.toBeReconciled || 0;

      const firstRecordDate = moment(resp.data.firstRecordDate).format('YYYY-MM-DD').toString();

      if (!this.firstRecordDateTime && firstRecordDate) {
        this.firstRecordDateTime = firstRecordDate;
        this.cachedRecordDateTime = firstRecordDate;
      } else if (+new Date(this.cachedRecordDateTime) > +new Date(firstRecordDate)) {
        this.cachedRecordDateTime = resp.data?.firstRecordDate ?? '2000-01-01T00:00:00Z';
      }

      this.isLoadingCounts--;
    } catch (error) {
      this.isLoadingCounts--;
      if (error instanceof CanceledError) {
        console.log('The count promise was canceled');
      } else {
        console.log('Some other error', error);
        this.showErrorSnackbar('Error Fetching Transaction counts ' + error);
        this.hasTransactionLoadError = true;
      }
    }
  }

  async callRestEndpoint() {
    this.isLoadingTransactions++;
    this.newTxnCount = 0;
    const variables = () => {
      if (this.$store.state.currentOrg) {
        const vars = {
          ...this.vars,
          orgId: this.$store.state.currentOrg.id,
          limit: typeof this.vars.limit === 'string' ? Number(this.vars.limit) : this.vars.limit,
        };
        // the wallets filter can only be 10 items long maximum
        if (vars.transactionFilter?.walletsFilter?.length > 10) {
          vars.transactionFilter.walletsFilter = vars.transactionFilter.walletsFilter.slice(0, 10);
        }
        return vars;
      } else {
        return false;
      }
    };

    const onResult = (data: TransactionResult) => {
      this.currentRateCallIndex = 0;
      const tApi = new TransactionsApi(undefined, baConfig.getFriendlyApiUrl());
      const txns = data?.transactions as TransactionView[];
      this.isLoadingExchangeRates = 1;
      const newToken = Date.now();
      this.batchToken = newToken;
      const dirtyTxns = [] as TransactionView[]; // txns.filter((t) => !t.exchangeRates || t.exchangeRates.exchangeRatesDirty);
      if (dirtyTxns.length === 0) {
        this.isLoadingExchangeRates = 0;
      } else {
      }
    };

    const vars = variables();
    if (vars) {
      try {
        if (this.previousPromise) {
          this.previousPromise.cancel();
        }

        const mappedFilters = this.calcFilters(vars) ?? {};

        let mappedSort = null as any;
        if (this.sort.id) {
          mappedSort = {};
          let sortId = null;
          switch (this.sort.id) {
            case 'date':
              sortId = 'timestamp';
              break;
            case 'amount':
              sortId = 'amount';
              break;
            case 'fmv':
              sortId = 'fmv_amount';
              break;
            case 'tickers':
              sortId = 'ticker';
              break;
            case 'transactionType':
              sortId = 'type';
              break;
            case 'status':
              sortId = 'state';
              break;
            case 'id':
              sortId = 'transaction_id';
              break;
            case 'from':
              sortId = 'from';
              break;
            case 'to':
              sortId = 'to';
              break;
            case 'wallets':
              sortId = 'wallet';
              break;
          }
          if (sortId) {
            mappedSort.column = sortId;
            mappedSort.direction = this.sort.asc ? 'ASC' : 'DESC';
          }
        }
        const originalPromise = axios.post(
          `${this.txnsSvcURL}/orgs/${this.$store.state.currentOrg.id}/transactions`,
          {
            limit: vars.limit,
            timezone: 'UTC',
            filters: mappedFilters,
            sortBy: mappedSort,
            nextToken: this.vars.nextToken,
            prevToken: this.vars.prevToken,
          },
          {
            withCredentials: true,
          }
        );
        const cancelablePromise = makeCancelable(originalPromise);
        this.previousPromise = cancelablePromise;
        const response = await cancelablePromise;
        const uResp = await this.callUniqueValuesEndpoint('from', '');

        if (response.status === 200) {
          this.transactionsLite = response.data;
          this.transactionsLite.transactions.forEach((txn) => {
            if (!txn) return;
            // sort the txn lines by walletId and fee operation
            // sort the txn lines placing the ones with feeAmount or operation equals 'FEE' at the end also secondary sort by walletId, do not mutate the original array
            txn.lines =
              (txn.lines?.slice()?.sort((a, b) => {
                if (a?.operation === 'FEE' && b?.operation !== 'FEE') {
                  return 1;
                } else if (a?.operation !== 'FEE' && b?.operation === 'FEE') {
                  return -1;
                } else if ((a?.walletId || '') > (b?.walletId || '')) {
                  return 1;
                } else if ((a?.walletId || '') < (b?.walletId || '')) {
                  return -1;
                } else {
                  return 0;
                }
              }) as any[]) ?? [];
          });
          onResult(response.data);
        }
        this.isLoadingTransactions--;
      } catch (error) {
        this.isLoadingTransactions--;
        if (error instanceof CanceledError) {
          console.log('The promise was canceled');
        } else {
          console.log('Some other error', error);
          this.showErrorSnackbar('Error Fetching Transactions ' + error);
          this.hasTransactionLoadError = true;
        }
      }
    }
  }

  async mounted() {
    this.callCountsEndpoint();

    const queryParams = this.$route.query;
    if (!Object.keys(queryParams).length) await this.callRestEndpoint();
    else this.applyRouteFilters(queryParams);

    this.callAllUniqueValuesEndpoint();
    await this.$nextTick();
    this.displayMode = localStorage.getItem('displayMode') ?? 'min';
    const visibleHeaders = localStorage.getItem('visibleHeaders');
    if (visibleHeaders) {
      this.visibleHeaders = [
        ...JSON.parse(visibleHeaders),
        ...this.headers.filter((x) => x.isStatic).map((x) => x.id),
      ].reduce((a, x) => {
        if (!a.includes(x)) {
          a.push(x);
        }
        return a;
      }, [] as string[]);
      if (this.visibleHeaders.includes('amounts')) {
        this.visibleHeaders.push('amount', 'tickers');
      }
      if (this.visibleHeaders.includes('type')) {
        this.visibleHeaders.push('transactionType');
      }
    }
  }

  @Watch('transactionsLite')
  public updateCoinLookup() {
    this.coinLookup = new Map(); // this.transactionsLite
    //   ? new Map(this.transactionsLite?.coins?.map((c: any) => [c.currencyId, c.ticker]))
    //   : new Map();
    this.networkLookup = new Map(); // this.transactionsLite
    //   ? new Map(this.transactionsLite?.coins?.map((c: any) => [c.currencyId, c.networkId]))
    //   : new Map();
  }

  private searchDebounceTimer?: number;
  @Watch('searchToken')
  public onSearchTokenChange() {
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }
    this.searchDebounceTimer = window.setTimeout(() => {
      this.searchDebounceTimer = undefined;
      this.vars.transactionFilter.searchTokens =
        this.searchToken.trim() !== '' ? this.searchToken.split(' ') : undefined;
    }, 1000);
  }

  @Watch('countTab')
  public onCountTabChanged() {
    if (!this.countTab) return;
    if (this.countTab === 'categorize') {
      this.vars.transactionFilter.categorizationFilter = 'Uncategorized';
      this.vars.transactionFilter.reconciliationFilter = 'Unreconciled';
    } else if (this.countTab === 'reconcile') {
      this.vars.transactionFilter.categorizationFilter = 'Categorized';
      this.vars.transactionFilter.reconciliationFilter = 'Unreconciled';
    } else if (this.countTab === 'all') {
      this.vars.transactionFilter.categorizationFilter = 'All';
      this.vars.transactionFilter.reconciliationFilter = 'All';
    }
  }

  @Watch('vars')
  @Watch('vars.limit')
  @Watch('vars.transactionFilter.categorizationFilter')
  @Watch('vars.transactionFilter.reconciliationFilter')
  @Watch('vars.transactionFilter.ignoreFilter')
  @Watch('vars.transactionFilter.walletsFilter')
  @Watch('vars.transactionFilter.searchTokens')
  @Watch('vars.transactionFilter.errored')
  @Watch('vars.transactionFilter.pivotDate')
  async filtersUpdated() {
    window.pendo?.track('Transaction - Filters Updated', {
      categorizationFilter: this.vars.transactionFilter.categorizationFilter,
      reconciliationFilter: this.vars.transactionFilter.reconciliationFilter,
      ignoreFilter: this.vars.transactionFilter.ignoreFilter,
      walletsFilter: !!this.vars.transactionFilter.walletsFilter?.length,
      searchTokens: !!this.vars.transactionFilter.searchTokens?.length,
      errored: this.vars.transactionFilter.errored,
      pivotDate: this.vars.transactionFilter.pivotDate,
      limit: this.vars.limit,
    });
    this.vars.nextToken = undefined;
    this.vars.prevToken = undefined;
    this.callRestEndpoint();
    this.callCountsEndpoint();
  }

  @Watch('vars.paginationToken')
  async paginationUpdated() {
    await this.callRestEndpoint();
  }

  @Watch('$store.state.currentOrg.id')
  async orgIdUpdated() {
    this.callCountsEndpoint();
    this.resetFilters();
  }
  // #endregion

  // #region inline categorization
  public inlineTransaction: TxnVM | null = null;
  public inlineCostBasisType = 'exchangeRate';

  // current not used, just using defaultFeeCategoryId directly in txnTable
  public get feeCategories(): NetworkFeeCategoryInput[] {
    return this.$store.state.currentOrg.accountingConfig?.defaultFeeCategoryIds;
  }

  public get feeContacts(): NetworkContactInput[] {
    return this.$store.state.currentOrg.accountingConfig?.networkContactIds;
  }

  public async assignToContact({
    address,
    contactId,
    networkId,
  }: {
    address: string;
    contactId: string;
    networkId: string;
  }) {
    const contact = this.contacts.find((c) => c.id === contactId);
    if (!contact) return;
    await this.saveContactAddress(contact, address, networkId);
  }

  // copy of the function in transactionTable (needs to be refactored to here and not duplicated)
  public isValidInline(txn: any) {
    return txn.lines.every(
      (x: any) =>
        x.category &&
        x.contact &&
        txn.accountingConnectionId &&
        (x.feeCategory || x.operation === TxnLineOperation.Fee || !x.feeAssetId) &&
        (x.feeContact || x.operation === TxnLineOperation.Fee || !x.feeAssetId)
    );
  }

  // copy of the function in transactionTable (needs to be refactored to here and not duplicated)
  public isDirty(txn: any) {
    return txn.lines.some(
      (x: any) =>
        ((x.contact || x.category || x.feeCategory || x.feeContact) &&
          txn.categorizationStatus === CategorizationStatus.Uncategorized) ||
        x.dirty
    );
  }

  public get showConfirmAll() {
    return this.displayTxns?.some(
      (txn: any) => this.isDirty(txn) && this.isValidInline(txn) && txn.useLines && txn.canInline
    );
  }

  public async callTxnDetailsEndpoint(id: string) {
    const resp = await axios.get(
      `${this.txnsSvcURL}/orgs/${this.$store.state.currentOrg.id}/transactions/${id}/details`,
      {
        withCredentials: true,
      }
    );
    return resp.data;
  }

  public async callTxnPriceEndpoint(id: string) {
    const resp = await axios.post(
      `https://api2.bitwave.io/v2/orgs/${this.$store.state.currentOrg.id}/transactions/${id}/price`,
      undefined,
      {
        withCredentials: true,
      }
    );
    return resp.data;
  }

  public async confirmAllInline() {
    const txns = this.displayTxns?.filter(
      (txn: any) => txn.useLines && txn.canInline && this.isDirty(txn) && this.isValidInline(txn)
    );
    const detailPromises = txns?.map((txn: any) => this.callTxnDetailsEndpoint(txn.id));
    const detailResults = await Promise.all(detailPromises ?? []);
    const details = new Map();
    detailResults.forEach((d: any) => details.set(d.transactionId, d));

    txns?.forEach((txn: any) => this.inlineCategorize2(txn, details.get(txn.id)));
  }

  public async saveContactAddress(contact: Contact, address: string, networkId: string) {
    this.isSoftLoading++;
    let addresses = (contact?.addresses as any[]) ?? [];
    // addresses.push({ coin: ticker, address });
    addresses.push({ networkId, address });
    addresses = addresses.filter((item, index, array) => {
      // return array.findIndex((obj) => obj.coin === item.coin && obj.address === item.address) === index;

      return array.findIndex((obj) => obj.networkId === item.networkId && obj.address === item.address) === index;
    });
    try {
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $contactId: ID!, $addresses: [ContactAddressInput!]!) {
            setContactCoinAddresses(orgId: $orgId, contactId: $contactId, addresses: $addresses)
          }
        `,
        variables: {
          orgId: this.$store.state.currentOrg.id,
          contactId: contact.id,
          addresses: addresses.map((x) => ({ ...x, __typename: undefined, networkId: x.networkId ?? 'eth' })),
        },
      });
    } catch (e) {
      this.showErrorSnackbar('An error occurred');
      console.error(e);
      return;
    } finally {
      this.isSoftLoading--;
    }
  }

  public async assignCategoryToContact({
    contactId,
    revenue,
    categoryId,
  }: {
    contactId: string;
    revenue: boolean;
    categoryId: string | null;
  }) {
    const contact = this.contacts.find((c) => c.id === contactId);
    if (!contact) return;
    await this.updateDefaultCategory(contact, revenue, categoryId);
  }

  public updateDefaultCategory(contact: Contact, revenue: boolean, categoryId: string | null) {
    this.isSoftLoading++;
    const variables = {
      orgId: this.$store.state.currentOrg.id,
      contact: {
        id: contact.id,
        enabled: contact.enabled,
        defaultRevenueCategoryId: null,
        defaultExpenseCategoryId: null,
      },
    } as {
      orgId: string;
      contact: {
        id: string;
        enabled: boolean;
        defaultRevenueCategoryId: string | null;
        defaultExpenseCategoryId: string | null;
      };
    };
    if (revenue === true) {
      if (categoryId) variables.contact.defaultRevenueCategoryId = categoryId;
      variables.contact.defaultExpenseCategoryId = contact.defaultExpenseCategoryId ?? null;
    } else {
      if (categoryId) variables.contact.defaultExpenseCategoryId = categoryId;
      variables.contact.defaultRevenueCategoryId = contact.defaultRevenueCategoryId ?? null;
    }
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $contact: UpdateContactInput!) {
            updateContact(orgId: $orgId, contact: $contact) {
              name
              enabled
              defaultExpenseCategoryId
              defaultRevenueCategoryId
            }
          }
        `,
        // Parameters
        variables,
      })
      .then((res) => {
        this.refresh();
        this.isSoftLoading--;
      })
      .catch((e) => {
        console.log(e);
        this.isSoftLoading--;
      });
  }

  public async inlineCategorize2(txn: TxnVM, details?: any) {
    this.inlineTransaction = txn;
    if (!details) {
      details = await this.callTxnDetailsEndpoint(txn.id);
    }
    // const price = await this.callTxnPriceEndpoint(txn.id);
    this.confirmInlineCategorizeTxn(details);
  }

  public confirmInlineCategorizeTxn(details: any) {
    console.log('confirmInlineCategorizeTxn', details);
    const transaction = this.inlineTransaction;
    if (!transaction) return;
    // if (!transaction.editEtag) {
    //   this.showErrorSnackbar('Transaction Edit Etag is missing. Please refresh the page and try again.');
    //   return;
    // }
    const exchangeRates = [];
    for (const er of details?.exchangeRates ?? []) {
      let exRate: any = {
        coin: er?.from,
        unit: getMainUnitForCoin(er?.from),
        fiat: er?.to,
        rate: er?.rate,
        // source: er?.source,
      };
      if (transaction.transactionType === 'ContractExecution') {
        exRate = { ...exRate, priceId: er?.priceId };
      }
      exchangeRates.push(exRate);
    }

    let cb: any;
    if (details?.exchangeRates) {
      if (this.inlineCostBasisType === 'exchangeRate') {
        cb = {
          exchangeRate: this.convertBigNumberScalar(exchangeRates[0].rate).toNumber(),
          costBasisType: 'ExchangeRate',
          valid: true,
          exchangeRates: exchangeRates,
        };
      }
    }
    const splits: any[] = [];
    const paidFees = this.calcPaidFees(transaction);
    const lines = this.calcLines(
      transaction,
      this.categories,
      this.connectionList.find((connection) => connection.id === transaction.accountingConnectionId) ?? null
    );

    // group inlineTransaction.txnLines by contact
    const groupedLines = transaction?.lines?.reduce((a, x: any) => {
      if (!x.contact) return a;
      if (x.category && x.amount) {
        a[x.contact] = [...(a[x.contact] || []), x];
      }
      return a;
    }, {} as any);

    Object.keys(groupedLines).forEach((key) => {
      const lineGroup = groupedLines[key];
      const contact = key;
      splits.push({
        contact: this.contacts.find((c) => c.id === contact),
        lines: lineGroup.map((txnLine: TransactionLineView) => {
          const line = lines.find(
            (x) => x.amount.abs().toString() === this.convertBigNumberScalar(txnLine.amount).toString()
          );
          assertDefined(line);
          const category = this.categories.find((c) => c.id === txnLine.category);
          return {
            ...line,
            category,
            txnLineId: txnLine.line,
            walletId: txnLine.walletId,
          };
        }),
      });
    });
    const baseCurrency = this.$store.state.currentOrg.baseCurrency;
    const splitsFinal = splits.map((split) =>
      splitToMultiValueTransactionItemInput(
        split,
        this.txnAmountTotal(transaction).toNumber(),
        baseCurrency,
        cb,
        transaction.transactionType ?? ''
      )
    );
    const { valid, ...transactionData } = multivalueTransactionDataFactory(splitsFinal, exchangeRates);
    assertDefined(transactionData);
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      txnId: transaction.id ?? '',
      transactionData: {
        ...transactionData,
        accountingConnectionId: transactionData.multivalue.items[0].contactId.split('.')[0],
        // editEtag: transaction.editEtag,
      },
    };
    return this.categorizeTransaction(vars);
  }

  public txnAmountTotal(txn: TxnVM): math.BigNumber {
    let amountTotal = math.bignumber(0);

    const amounts = txn.lines.filter((x) => x.operation !== TxnLineOperation.Fee);

    assertDefined(amounts);
    for (const m of amounts) {
      assertDefined(m);
      const { amount, amountCurrencyId, amountCurrencyName, operation } = m;
      const coin = amountCurrencyName;
      const unit = getMainUnitForCoin(coin);
      assertDefined(coin);
      assertDefined(unit);
      let realAmount = amount;
      if (
        (operation === TxnLineOperation.Fee ||
          operation === TxnLineOperation.Withdraw ||
          operation === TxnLineOperation.Sell) &&
        amount[0] !== '-'
      ) {
        realAmount = `-${amount}`;
      }
      const val = this.convertBigNumberScalar(realAmount);
      const converted = convertUnits(coin, unit, unit, val);
      assertDefined(converted);
      amountTotal = amountTotal.plus(converted);
    }
    return amountTotal;
  }

  async categorizeTransaction(vars: {
    orgId: string;
    txnId: string;
    transactionData: {
      multivalue: MultiValueTransactionInput;
      // editEtag: string;
    };
  }): Promise<void> {
    try {
      this.isSoftLoading++;
      await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $txnId: ID!, $transactionData: TransactionData!) {
            categorizeTransaction(orgId: $orgId, txnId: $txnId, transactionData: $transactionData) {
              id
            }
          }
        `,
        // Parameters
        variables: vars,
      });
      this.isSoftLoading--;
      this.showSuccessSnackbar('Successfully Categorized Transaction');
      this.setDirtyTxns.push(vars.txnId);
      this.refresh();
    } catch (e) {
      this.isSoftLoading--;
      const message = 'Problem saving item: ' + stringifyError(e, { hideErrorName: true });
      // this.$store.commit(MUT_SNACKBAR, {
      //   color: 'error',
      //   message,
      // });
    }
  }

  public calcPaidFees(txn: TxnVM) {
    const fees = txn.lines.filter((x) => x.operation === TxnLineOperation.Fee);
    return fees;
  }

  public calcLines(txn: TxnVM, categories: Category[], connection: Connection | null): { amount: math.BigNumber }[] {
    const prePopLines: any[] = [];
    const linesWithoutFees = txn.lines.filter((x) => x.operation !== TxnLineOperation.Fee);
    const linesWithFees = txn.lines.filter((x) => x.operation === TxnLineOperation.Fee);

    if (txn?.lines?.length) {
      //  we are going to try to pre populate the txn amounts and fees separately
      //  we will try to fill in the fee contact and category
      linesWithoutFees.forEach((a: any) => {
        const l = this.prepopulateLineFromAmount(a);
        prePopLines.push(l);
      });
      linesWithFees.forEach((f: any) => {
        const l = this.prepopulateLineFromAmount(f);
        const maybeCategory = looselyGetCategoryWithCode(categories, connection?.feeAccountCode);
        const fee = {
          category: maybeCategory,
          amount: l.amount as math.BigNumber,
          ticker: l.ticker,
          description: `Fee for txn ${txn.id}.`,
        };
        prePopLines.push(fee);
      });
    }
    //  this is the final catch all
    if (prePopLines.length === 0) {
      const zero = math.bignumber(0);
      prePopLines.push({ amount: zero });
    }
    return prePopLines;
  }

  public prepopulateLineFromAmount(line: Maybe<TransactionLineView>) {
    assertDefined(line);
    const { amountCurrencyId, amount, amountCurrencyName, operation } = line;
    const coin = amountCurrencyName;
    const mainUnit = getMainUnitForCoin(coin);
    assertDefined(coin);
    assertDefined(mainUnit);
    let realAmount = amount;
    if (
      (operation === TxnLineOperation.Fee ||
        operation === TxnLineOperation.Withdraw ||
        operation === TxnLineOperation.Sell) &&
      amount[0] !== '-'
    ) {
      realAmount = `-${amount}`;
    }
    const val = this.convertBigNumberScalar(realAmount);

    const conv = convertUnits(coin, mainUnit, mainUnit, val);
    return {
      amount: conv,
      ticker: coin,
    };
  }

  public getCoinByCurrencyId(ci: string, unit?: string) {
    const coin = { ...this.coins.find((coin) => coin?.currencyId === ci) } as any;

    switch (unit) {
      case '1':
        coin.unit = 'Satoshi';
        break;
      case '2':
        coin.unit = 'Bitcoin';
        break;
      case '10':
        coin.unit = 'Wei';
        break;
      case '11':
        coin.unit = 'Ether';
        break;
      case '20':
        coin.unit = 'EOS';
        break;
      case '999999':
        coin.unit = 'Unknown';
        break;
      default: {
        break;
      }
    }
    return coin;
  }

  // #endregion

  // #region bulk categorization
  public showDoneModal = false;

  get allowBulkCategorize() {
    return true; // bulkcategorize was the flag
  }

  public closeDoneModal(extraAction?: string) {
    this.showDoneModal = false;
    if (extraAction === 'refresh') {
      this.setDirtyTxns = [...this.setDirtyTxns, ...this.selectedTxns.map((item) => item.id ?? '')];
      this.onSelectionChanged([]);
      this.refresh();
    }
  }

  public bulkEditTransaction() {
    this.showDoneModal = true;
  }
  // #endregion

  // #region filter and sorting
  public uniqueColumnValues: { [id: string]: Array<string | number> } = {};
  public filters: any = {};
  public sort: any = {};

  onSort(sort: any) {
    this.sort = sort;
    this.vars.nextToken = undefined;
    this.vars.prevToken = undefined;
    this.callRestEndpoint();
  }

  onFilter(filters: any) {
    this.filters = filters;
    this.vars.nextToken = undefined;
    this.vars.prevToken = undefined;
    this.callRestEndpoint();
    this.callCountsEndpoint();
  }

  onSearch(value: { search: string; column: string }) {
    this.callUniqueValuesEndpoint(value.column, value.search);
  }

  onPaginateClick(type: 'earlier' | 'later', token: string) {
    const paginateFn = () => {
      this.onSelectionChanged([]);
      this.onSelectionChanged(this.selectedTxns);

      if (type === 'earlier') {
        this.setPageToken('prevToken', token);
      } else {
        this.setPageToken('nextToken', token);
      }

      this.callRestEndpoint();
    };

    if (this.selectedTxns.length > 0) {
      const hasConfirmation = confirm(
        `You have (${this.selectedTxns.length}) selected transactions. This action will reset selected transactions.`
      );
      if (!hasConfirmation) return;
    }

    paginateFn();
  }

  // #endregion

  public highlightRow(id: string) {
    const transactionTable = this.$refs.transactionTable as any;
    const table = transactionTable?.$refs?.dataTable as any;
    table?.highlightRow(id);
  }

  @Watch('$store.state.notifications')
  public onNotificationsChanged(notifications: any) {
    if (!notifications?.length) return;
    const notification = notifications[notifications.length - 1];
    if (notification?.data?.orgId !== this.$store.state.currentOrg.id && !notification?.data?.state) return;
    // if a transaction we are watching has a notification we will refresh i.e. if we combine a txn we get the new id and wait for the notification then refresh if we the ones who started the action
    if (this.txnsWatched.length) {
      this.txnsWatched = this.txnsWatched.filter((x) => x !== notification.data.transactionId);
      if (!this.txnsWatched.length) {
        this.refresh();
        return;
      }
    }
    if (
      notification.name === 'TxnRecorded' ||
      notification.name === 'ParentTxnRecorded' ||
      (notification.name === 'TxnUnCombined' && notification?.data?.state !== 'deleted')
    ) {
      this.showTxnBanner = true;
      this.newTxnCount++;
      return;
    }
    const txn = this.transactionsLite?.transactions?.find((t: any) => t.id === notification.data.transactionId);
    if (!txn) return;
    txn.state = notification?.data?.state;
    this.highlightRow(txn.id);
  }

  applyRouteFilters(params: any) {
    const routeFilters = parseParams(params) as { txFilter: Record<string, any>; filters?: Record<string, any> };
    const { txFilter, filters } = routeFilters;

    if (txFilter) {
      delete txFilter.searchTokens;
      this.vars.transactionFilter = { ...this.vars.transactionFilter, ...txFilter };
    }

    if (filters) {
      this.onFilter(filters);
    }
  }

  // #endregion
}
