<template>
  <v-container fluid grid-list-xl>
    <v-layout wrap align-start>
      <v-flex
        :xs6="showDefaultCategorization"
        :xs12="!showDefaultCategorization"
        v-if="wallet"
        :class="{ 'tw-border-r tw-border-gray-300 tw-pr-6': showDefaultCategorization }"
      >
        <v-layout row wrap>
          <v-flex xs12>
            <v-text-field :label="$t('_name')" v-model="name"></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field label="ID" v-model="wallet.id" :disabled="true"></v-text-field>
          </v-flex>
          <v-flex xs12 v-if="checkFeatureFlag('subsidiaries')">
            <v-autocomplete
              v-model="subsidiaryId"
              :items="subs"
              label="Subsidiary"
              item-text="name"
              item-value="id"
              persistent-hint
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field :label="$t('_description')" v-model="description"></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-checkbox label="Disabled" v-model="disabled"></v-checkbox>
          </v-flex>
          <v-flex v-if="wallet.networkId === 'sol'" xs12 class="pa-1">
            <v-checkbox v-model="solanaInflationStakingRewardsEnabled" label="Staking Rewards Enabled" />
          </v-flex>
          <v-flex v-if="wallet.networkId === 'sol' && solanaInflationStakingRewardsEnabled" xs12 class="pa-1">
            <ui-date-picker2
              value-type="timestamp"
              :value="solanaInflationStakingRewardsStartTime"
              label="Staking Rewards Start Date"
              @input="onDateChange('solanaInflationStakingRewardsStartTime', $event)"
              class="tw-flex-1"
            />
          </v-flex>
          <v-flex v-if="wallet.networkId === 'sol' && solanaInflationStakingRewardsEnabled" xs12 class="pa-1">
            <ui-date-picker2
              value-type="timestamp"
              :value="solanaInflationStakingRewardsEndTime"
              label="Staking Rewards End Date"
              @input="onDateChange('solanaInflationStakingRewardsEndTime', $event)"
              class="tw-flex-1"
            />
          </v-flex>
          <v-flex xs12>
            <span v-if="wallet.type === 9">Account Based Blockchain Wallet</span>
          </v-flex>
          <v-flex xs12 v-if="wallet.type === 999">
            <v-select
              :items="nonGroupedWallets"
              item-value="id"
              label="Wallets and Groups"
              v-model="childWallets"
              multiple
              chips
            >
              <template slot="selection" slot-scope="data">
                <v-chip
                  close
                  @input="data.parent.selectItem(data.item)"
                  :selected="data.selected"
                  class="chip--select-multi"
                  :key="JSON.stringify(data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template slot="item" slot-scope="data">
                <template>
                  <v-list-tile-action>
                    <v-checkbox :input-value="childWallets.indexOf(data.item.id) > -1"></v-checkbox>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ data.item.name }}</v-list-tile-title>
                  </v-list-tile-content>
                </template>
              </template>
            </v-select>
          </v-flex>
          <v-flex v-if="hasAddresses" xs12>
            <hr class="tw-mt-6" />
          </v-flex>
          <v-flex v-if="hasAddresses" xs12 sm12 md6 class="pt-3">
            <h3 class="tw-text-base theme--light">Addresses</h3>
            <ul style="list-style-type: none">
              <li v-for="(address, index) in wallet.addresses" v-bind:key="`address-${index}`">
                {{ address }}
              </li>
            </ul>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex v-if="showDefaultCategorization" xs6 class="tw-pl-6">
        <!-- <v-layout>
          <v-flex xs12>
            <h3 class="tw-text-base">Categorization Defaults</h3>
          </v-flex>
        </v-layout> -->
        <v-layout row wrap>
          <v-flex xs12>
            <v-autocomplete
              v-model="defaultFields.accountingConnection"
              :items="validAccountingConnections"
              label="Accounting Connection"
              item-text="provider"
              item-value="id"
              persistent-hint
            />
          </v-flex>
          <v-flex xs6>
            <v-autocomplete
              v-model="defaultFields.defaultInflowCategory"
              :items="categories"
              label="Inflow Category"
              item-text="name"
              item-value="id"
              persistent-hint
            >
              <template slot="item" slot-scope="data">
                <v-list-tile-content>
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <div class="tw-flex tw-flex-col">
                      <div>{{ data.item.code }} {{ data.item.name }}</div>
                      <sub>{{ data.item.type }}</sub>
                    </div>
                  </div>
                </v-list-tile-content>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs6>
            <v-autocomplete
              v-model="defaultFields.defaultInflowContact"
              :items="contacts"
              label="Inflow Contact"
              item-text="name"
              item-value="id"
              persistent-hint
            >
              <template slot="item" slot-scope="data">
                <v-list-tile-content>
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <div class="tw-flex tw-flex-col">
                      <div>{{ data.item.code }} {{ data.item.name }}</div>
                      <sub>{{ data.item.type }}</sub>
                    </div>
                  </div>
                </v-list-tile-content>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs6>
            <v-autocomplete
              v-model="defaultFields.defaultOutflowCategory"
              :items="categories"
              label="Outflow Category"
              item-text="name"
              item-value="id"
              persistent-hint
            >
              <template slot="item" slot-scope="data">
                <v-list-tile-content>
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <div class="tw-flex tw-flex-col">
                      <div>{{ data.item.code }} {{ data.item.name }}</div>
                      <sub>{{ data.item.type }}</sub>
                    </div>
                  </div>
                </v-list-tile-content>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs6>
            <v-autocomplete
              v-model="defaultFields.defaultOutflowContact"
              :items="contacts"
              label="Outflow Contact"
              item-text="name"
              item-value="id"
              persistent-hint
            >
              <template slot="item" slot-scope="data">
                <v-list-tile-content>
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <div class="tw-flex tw-flex-col">
                      <div>{{ data.item.code }} {{ data.item.name }}</div>
                      <sub>{{ data.item.type }}</sub>
                    </div>
                  </div>
                </v-list-tile-content>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs6>
            <v-autocomplete
              v-model="defaultFields.defaultFeeCategory"
              :items="categories"
              label="Fee Category"
              item-text="name"
              item-value="id"
              persistent-hint
            >
              <template slot="item" slot-scope="data">
                <v-list-tile-content>
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <div class="tw-flex tw-flex-col">
                      <div>{{ data.item.code }} {{ data.item.name }}</div>
                      <sub>{{ data.item.type }}</sub>
                    </div>
                  </div>
                </v-list-tile-content>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs6>
            <v-autocomplete
              v-model="defaultFields.defaultFeeContact"
              :items="contacts"
              label="Fee Contact"
              item-text="name"
              item-value="id"
              persistent-hint
            >
              <template slot="item" slot-scope="data">
                <v-list-tile-content>
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <div class="tw-flex tw-flex-col">
                      <div>{{ data.item.code }} {{ data.item.name }}</div>
                      <sub>{{ data.item.type }}</sub>
                    </div>
                  </div>
                </v-list-tile-content>
              </template>
            </v-autocomplete>
          </v-flex>
        </v-layout>
      </v-flex>

      <div class="flex xs12 tw--mt-2" v-if="checkFeatureFlag('soda-report')">
        <ui-select-2
          class="tw-w-full"
          :items="walletRoles"
          :label="$tc('_walletRole')"
          keyField="id"
          labelField="name"
          searchable
          upsert
          keyOnly
          :value="selectedWalletRoleId"
          v-model="walletRoleId"
          @enterKey="addWalletRole"
        >
        </ui-select-2>
      </div>

      <!-- show wallet.metadata -->
      <div class="flex xs12 tw--mt-2" v-if="metadata && Object.keys(metadata).length">
        <label>Metadata:</label>

        <div
          v-for="(metadataKey, index) of Object.keys(metadata)"
          :key="index"
          class="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-pt-2"
        >
          <div class="tw-bg-neutral-100 tw-p-2 tw-w-1/3">{{ metadataKey }}:</div>
          <div>
            {{ metadata[metadataKey] }}
          </div>
        </div>
      </div>
    </v-layout>
    <v-flex class="text-xs-right mt-4">
      <v-btn
        @click="save"
        color="primary"
        :loading="saving"
        :disabled="!checkFormValid"
        v-if="checkScope(scopeLiterals.WalletsCreate)"
        >Update</v-btn
      >
    </v-flex>
  </v-container>
</template>

<script>
import gql from 'graphql-tag';

import { WalletService } from '@/services/walletService';
import { isAccountingProvider } from '@/utils/accountingProviders';

import { baConfig } from '../../../config';
import { OrganizationsApi } from '../../../generated/api-svc';
import UiDatePicker2 from '../ui/UiDatePicker2.vue';
import UiSelect2 from '../ui/UiSelect2.vue';

export default {
  props: ['wallet', 'close', 'connections', 'showDefaultCategorization'],
  components: {
    UiDatePicker2,
    UiSelect2,
  },
  data() {
    return {
      name: '',
      description: '',
      disabled: false,
      defaultFields: {
        accountingConnection: null,
        defaultInflowContact: null,
        defaultInflowCategory: null,
        defaultOutflowContact: null,
        defaultOutflowCategory: null,
        defaultFeeContact: null,
        defaultFeeCategory: null,
      },
      saving: false,
      childWallets: [],
      solanaInflationStakingRewardsEnabled: false,
      solanaInflationStakingRewardsStartTime: null,
      solanaInflationStakingRewardsEndTime: null,
      subs: [],
      subsidiaryId: null,
      walletRoles: [],
      metadata: null,
      walletRoleId: null,
      selectedWalletRoleId: null,
    };
  },
  watch: {
    wallet() {
      if (this.wallet) {
        this.name = this.wallet.name;
        this.description = this.wallet.description;
        this.disabled = this.wallet.disabled || false;
        this.childWallets = this.wallets?.filter((w) => w.groupId === this.wallet.id).map((w) => w.id);

        if (this.wallet.flags && this.wallet.networkId === 'sol') {
          this.solanaInflationStakingRewardsEnabled = this.wallet.flags.solanaInflationStakingRewardsEnabled;
          this.solanaInflationStakingRewardsStartTime = this.wallet.flags.solanaInflationStakingRewardsStartTime;
          this.solanaInflationStakingRewardsEndTime = this.wallet.flags.solanaInflationStakingRewardsEndTime;
        }
      }

      this.metadata = this.wallet?.metadata;
      this.walletRoleId = this.wallet?.walletRoleId;
    },
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    wallets: {
      query: gql`
        query GetWallets($orgId: ID!) {
          wallets(orgId: $orgId) {
            id
            name
            description
            disabled
            type
            deviceType
            address
            addresses
            path
            enabledCoins
            bulkSend {
              enabled
              canEnable
            }
            groupId
            metadata
            walletRoleId
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      errorPolicy: 'ignore',
    },
  },
  methods: {
    resetForm() {
      this.name = '';
      this.description = '';
      this.saving = false;
      this.subsidiaryId = null;

      for (const key of Object.keys(this.defaultFields)) {
        this.defaultFields[key] = null;
      }
    },
    async getWalletRoles() {
      const orgId = this.$store.state.currentOrg.id;
      // init wallet service
      const walletSvc = new WalletService(this.checkFeatureFlag('soda-report'));
      const resp = await walletSvc.getWalletRoles(orgId);
      this.walletRoles = resp;
    },
    async save() {
      this.saving = true;
      try {
        const orgId = this.$store.state.currentOrg.id;
        const initialChilds = this.wallets?.filter((w) => w.groupId === this.wallet.id).map((w) => w.id);
        const isGroup = this.wallet.type === 999;
        const addWallets = isGroup ? this.childWallets.filter((newWallet) => !initialChilds.includes(newWallet)) : [];
        const removeWallets = isGroup
          ? initialChilds.filter((oldWallet) => !this.childWallets.includes(oldWallet))
          : [];

        const variables = {
          orgId,
          walletId: this.wallet.id,
          name: this.name,
          description: this.description,
          disabled: this.disabled,
          addWallets,
          removeWallets,
          flags: undefined,
          subsidiaryId: this.subsidiaryId,
        };

        if (this.wallet.networkId === 'sol') {
          variables.flags = {
            solanaInflationStakingRewardsEnabled: this.solanaInflationStakingRewardsEnabled,
            solanaInflationStakingRewardsStartTime: this.solanaInflationStakingRewardsStartTime ?? undefined,
            solanaInflationStakingRewardsEndTime: this.solanaInflationStakingRewardsEndTime ?? undefined,
          };
        }

        for (const key of Object.keys(this.defaultFields)) {
          if (this.defaultFields[key]) variables[key] = this.defaultFields[key];
        }

        if (this.walletRoleId) {
          variables.walletRoleId = this.walletRoleId;
        }

        // set metadata
        variables.metadata = this.metadata;

        await this.$apollo.mutate({
          mutation: gql`
            mutation (
              $orgId: ID!
              $walletId: ID!
              $name: String
              $description: String
              $disabled: Boolean
              $addWallets: [String]
              $removeWallets: [String]
              $flags: WalletFlagsInput
              $subsidiaryId: String
              $accountingConnection: String
              $defaultInflowContact: String
              $defaultInflowCategory: String
              $defaultOutflowContact: String
              $defaultOutflowCategory: String
              $defaultFeeContact: String
              $defaultFeeCategory: String
              $metadata: JSON
              $walletRoleId: String
            ) {
              updateWallet(
                orgId: $orgId
                walletId: $walletId
                name: $name
                description: $description
                disabled: $disabled
                addWallets: $addWallets
                removeWallets: $removeWallets
                flags: $flags
                subsidiaryId: $subsidiaryId
                accountingConnection: $accountingConnection
                defaultInflowContact: $defaultInflowContact
                defaultInflowCategory: $defaultInflowCategory
                defaultOutflowContact: $defaultOutflowContact
                defaultOutflowCategory: $defaultOutflowCategory
                defaultFeeContact: $defaultFeeContact
                defaultFeeCategory: $defaultFeeCategory
                metadata: $metadata
                walletRoleId: $walletRoleId
              ) {
                id
              }
            }
          `,
          variables,
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.saving = false;
        this.$root.$emit('refresh-wallets');
        if (this.close) this.close();
      }
    },
    onDateChange(field, date) {
      // if (date === null || this._isValidDate(date)) {
      this[field] = date;
      // }
    },

    _isValidDate(dateString) {
      // Parse the date string into a Date object
      const dateObj = new Date(dateString);
      // Check if the date object is valid and the string matches the expected format
      return !isNaN(dateObj.getTime()) && /^\d{4}-\d{2}-\d{2}$/.test(dateString);
    },
    async addWalletRole(walletRoleName) {
      if (!walletRoleName) return;
      const orgId = this.$store.state.currentOrg.id;
      const walletSvc = new WalletService(this.checkFeatureFlag('soda-report'));
      const walletRoleId = await walletSvc.createRole(orgId, walletRoleName);
      if (walletRoleId) {
        this.walletRoles = [
          ...this.walletRoles,
          {
            id: walletRoleId,
            name: walletRoleName,
          },
        ];

        // default selected wallet role id
        this.selectedWalletRoleId = walletRoleId;
      }
    },
  },
  computed: {
    checkFormValid() {
      return this.name.trim().length > 0;
    },
    nonGroupedWallets() {
      if (!this.wallets) return [];
      const initialChilds = this.wallets?.filter((w) => w.groupId === this.wallet.id).map((w) => w.id);
      return this.wallets?.filter(
        (wallet) =>
          ((!wallet.groupId && wallet.id !== this.wallet.id) || initialChilds.includes(wallet.id)) &&
          wallet.id !== this.wallet.groupId
      );
    },
    shouldShowManualAccountingConnection() {
      const manualContactExist = this.$store.getters['contacts/ENABLED_CONTACTS']?.some((c) => c.source === 'Manual');
      return (
        manualContactExist || this.$store.getters['categories/ENABLE_CATEGORIES']?.some((c) => c.source === 'Manual')
      );
    },
    validAccountingConnections() {
      if (!this.connections) return [];

      const ac = this.connections.filter((c) => !c.isDisabled && isAccountingProvider(c.provider));

      const manualAccountingConnection = {
        id: 'Manual',
        provider: 'Bitwave',
        status: 'OK',
      };

      if (this.shouldShowManualAccountingConnection) {
        return [manualAccountingConnection].concat(ac);
      }

      return ac;
    },
    categories() {
      if (!this.defaultFields.accountingConnection) {
        return [];
      }
      return this.$store.getters['categories/ENABLE_CATEGORIES'].filter(
        (c) => c.accountingConnectionId === this.defaultFields.accountingConnection
      );
    },
    contacts() {
      if (!this.defaultFields.accountingConnection) {
        return [];
      }
      return this.$store.getters['contacts/ENABLED_CONTACTS'].filter(
        (c) => c.accountingConnectionId === this.defaultFields.accountingConnection
      );
    },
    hasAddresses() {
      return this.wallet.addresses && this.wallet.addresses.length > 0;
    },
  },
  async mounted() {
    // load wallet roles
    await this.getWalletRoles();

    if (this.wallet) {
      this.name = this.wallet.name;
      this.description = this.wallet.description;
      this.disabled = this.wallet.disabled || false;
      this.childWallets = this.wallets?.filter((w) => w.groupId === this.wallet.id).map((w) => w.id);
      if (this.wallet.flags && this.wallet.networkId === 'sol') {
        this.solanaInflationStakingRewardsEnabled = this.wallet.flags.solanaInflationStakingRewardsEnabled;
        this.solanaInflationStakingRewardsStartTime = this.wallet.flags.solanaInflationStakingRewardsStartTime;
        this.solanaInflationStakingRewardsEndTime = this.wallet.flags.solanaInflationStakingRewardsEndTime;
      }
      this.subsidiaryId = this.wallet.subsidiaryId;

      for (const key of Object.keys(this.defaultFields)) {
        if (this.wallet[key]) this.defaultFields[key] = this.wallet[key];
      }

      this.metadata = this.wallet?.metadata;
      this.walletRoleId = this.wallet?.walletRoleId;
    }

    if (this.checkFeatureFlag('subsidiaries', this.features)) {
      this.isLoadingSubs = true;
      try {
        const orgsApi = new OrganizationsApi(undefined, baConfig.getFriendlyApiUrl());
        const resp = await orgsApi.listSubsidiaries(this.orgId, { withCredentials: true });
        if (resp.status === 200) {
          this.subs = resp.data;
        } else {
          throw new Error('Problem loading subs');
        }
      } catch (e) {
        this.showErrorSnackbar('failed to load subsidiaries');
      } finally {
        this.isLoadingSubs = false;
      }
    }
  },
};
</script>
