









































































import axios from 'axios';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { baConfig } from '@/../config';
import DataApiManager from '@/api/dataCoreApiManager';
import { BaseVue } from '@/BaseVue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import { getEndpointUrl } from '@/utils/endpointUrlUtil';

import UiButton from '../ui/UiButton.vue';
import UiModal from '../ui/UiModal.vue';
import UiTextEdit from '../ui/UiTextEdit.vue';

@Component({
  components: { UiButton, UiModal, UiTextEdit, UiSelect2 },
})
export default class ImportRatesModal extends BaseVue {
  public isCreatingFeed = false;
  public isCreatingDataSource = false;
  public isExistingDataSource = false;
  public isLoadingDataSource = false;
  public isLoadingDataSources = false;
  public loadedDataSources: any = [];
  // public dataSource: string | null = null;
  public dataSource: { id: string; name: string } | null = null;
  public createDataSourceId = '';
  public createWalletId = '';
  public createWalletAddress = '';
  public networkId = '';
  public createFeedType = 'transaction';
  public gcpProject = '';
  public dataset = '';
  public bqTable = '';
  public bqRegion = '';
  public showConfirmationModal = false;

  @Prop({ default: false })
  public open!: boolean;

  @Prop({ required: true })
  public rateTableId!: string;

  svcURL = baConfig.api2Url || '';
  tableName = null;
  isLoading = false;

  // This function imports the rates from your data source, it first calls the function to get gcp details then uses those to call the data source load end point
  public async ImportRates() {
    if (!this.dataSource) {
      this.showErrorSnackbar('No data source selected.');
      return;
    }

    // Call fetchDataSourceDetails to ensure gcpProject, dataset, and bqTable are available
    await this.fetchDataSourceDetails();

    console.log(
      `Data Source - GCP Project: ${this.gcpProject}, Dataset: ${this.dataset}, Region: ${this.bqRegion}, Table: ${this.bqTable}`
    );

    if (!this.gcpProject || !this.dataset || !this.bqTable) {
      this.showErrorSnackbar('Data source details not available. Please fetch data source details first.');
      return;
    }
    if (!this.dataSource) return;
    this.isLoading = true;

    try {
      const orgId = this.$store.state.currentOrg.id;
      const endpointURL = getEndpointUrl(this.svcURL, ['v2', 'orgs', orgId, 'rate-tables', this.rateTableId, 'load']);
      // Add required fields to the request body
      const requestBody = {
        name: this.tableName,
        gcpProject: this.gcpProject, // Assuming this.gcpProject is defined in your component/state
        dataset: this.dataset, // Assuming this.dataset is defined in your component/state
        bqTable: this.bqTable, // Assuming this.bqTable is defined in your component/state
      };

      const resp = await axios.post(endpointURL, requestBody, { withCredentials: true });

      this.isLoading = false;

      // Check if errors array is empty
      if (resp.status === 200) {
        this.showSuccessSnackbar('Rate Table loaded successfully!');
        this.closeModal(false);
      } else {
        this.showErrorSnackbar('Rate Table could not be loaded due to errors.');
        this.closeModal(true);
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      this.showErrorSnackbar('Unexpected error when trying to load the rate table');
      this.closeModal(true);
    }
  }

  public async confirmImportRates() {
    this.showConfirmationModal = false; // Close the confirmation modal
    await this.ImportRates(); // Call the existing ImportRates function
  }

  // This function is called by the import rates function and is used to obtain gcpproject, gcpdataset, gcptable details from which rates should be imported
  public async fetchDataSourceDetails() {
    // Early check if the dataSource is null or doesn't have an id
    if (!this.dataSource) {
      this.showErrorSnackbar('No data source selected.');
      return;
    }

    // Debugging snackbar to confirm the function is entered
    this.showSuccessSnackbar('Fetching data source details...');

    this.isLoading = true; // Show loading state
    try {
      const orgId = this.$store.state.currentOrg.id; // Retrieve current organization ID
      const endpointURL = getEndpointUrl(this.svcURL, [
        'v3',
        'orgs',
        orgId,
        'data-sources',
        this.dataSource, // Use the selected data source ID
      ]); // Construct the endpoint URL using getEndpointUrl

      // Make the API call
      const resp = await axios.get(endpointURL, { withCredentials: true });

      // Handle success response
      if (resp.status === 200 && resp.data) {
        const { bqRegion, bqProjectId, bqDatasetId, bqTableName } = resp.data;

        // Store values into class properties
        this.gcpProject = bqProjectId;
        this.dataset = bqDatasetId;
        this.bqTable = bqTableName;
        this.bqRegion = bqRegion;

        // Notify the user
        console.log(
          `Data Source Retrieved Successfully - GCP Project: ${this.gcpProject}, Dataset: ${this.dataset}, Region: ${this.bqRegion}, Table: ${this.bqTable}`
        );
      } else {
        this.showErrorSnackbar('Failed to retrieve data source details.');
      }
    } catch (error) {
      console.error(error);
      this.showErrorSnackbar('Unexpected error while fetching data source details.');
    } finally {
      this.isLoading = false; // Hide loading state
    }
  }

  async mounted() {
    this.loadDataSources();
  }

  async loadDataSources() {
    this.isLoadingDataSources = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.handlersDatasourcehdlDataSourceHTTPHandlerList(this.orgId, undefined, undefined, {
        withCredentials: true,
      });
      if (resp.status === 200) {
        this.loadedDataSources = [];
        for (const l of resp.data.items ?? []) {
          this.loadedDataSources.push({
            name: l.name,
            id: l.id,
          });
        }
      } else {
        const e = new Error('Failed to load data sources. Status Code:' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoadingDataSources = false;
    }
  }

  public closeModal(failed?: boolean) {
    if (this.isLoading) return;

    this.tableName = null;
    this.$emit('close', { failed });
  }
}
