<template>
  <v-layout class="elevation-3" color="white">
    <v-flex xs12 v-if="isLoading > 0">
      <v-progress-linear indeterminate color="green"></v-progress-linear>
    </v-flex>
    <v-flex xs12 class="pa-1" v-else>
      <v-container>
        <v-form ref="invoicingForm" v-model="valid" lazy-validation>
          <v-text-field v-model="fromEmail" label="From Email" required :rules="[rules.required]"></v-text-field>
          <v-text-field v-model="fromName" label="From Name"></v-text-field>
          <v-text-field v-model="bitcoinAddress" label="Bitcoin Address"></v-text-field>
          <v-text-field v-model="bitcoinXPub" label="Bitcoin X Pub"></v-text-field>
          <v-text-field v-model="bitcoinXPubPath" label="Bitcoin X Pub Path"></v-text-field>
          <v-text-field v-model="ethAddress" label="Eth Address"></v-text-field>
          <v-text-field v-model="zecAddress" label="Zec Address"></v-text-field>
          <v-text-field v-model="usdcAddress" label="USDC Address (Ethereum network)"></v-text-field>
          <v-text-field v-model="usdtAddress" label="USDT Address (Ethereum network)"></v-text-field>
          <v-text-field v-model="avaxAddress" label="AVAX Address (Avalanche network)"></v-text-field>
          <v-text-field v-model="alotAddress" label="ALOT Address (Avalanche network)"></v-text-field>

          <div class="ma-4" />
          <div class="form-btn">
            <v-btn
              @click="save"
              color="success"
              :disabled="!canSave()"
              v-if="checkScope(scopeLiterals.InvoicingSettingsUpdate)"
            >
              Save
            </v-btn>
          </div>
        </v-form>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';

import { MUT_SET_INVOICING_SETTINGS, MUT_SNACKBAR } from '../../store';
export default {
  data() {
    return {
      isLoading: false,
      valid: false,
      fromEmail: '',
      fromName: '',
      bitcoinAddress: '',
      bitcoinXPub: '',
      bitcoinXPubPath: '',
      ethAddress: '',
      zecAddress: '',
      usdcAddress: '',
      usdtAddress: '',
      avaxAddress: '',
      alotAddress: '',
      rules: {
        required: (v) => (!!v && v.trim().length > 0) || 'Field is required',
      },
    };
  },
  methods: {
    canSave() {
      if (
        this.fromEmail &&
        this.fromEmail.trim() !== '' &&
        ((this.ethAddress && this.ethAddress.trim() !== '') ||
          (this.zecAddress && this.zecAddress.trim() !== '') ||
          (this.bitcoinAddress && this.bitcoinAddress.trim() !== '') ||
          (this.bitcoinXPub && this.bitcoinXPub.trim() !== '') ||
          (this.bitcoinXPubPath && this.bitcoinXPubPath.trim() !== '') ||
          (this.usdcAddress && this.usdcAddress.trim() !== '') ||
          (this.usdtAddress && this.usdtAddress.trim() !== '') ||
          (this.avaxAddress && this.avaxAddress.trim() !== '') ||
          (this.alotAddress && this.alotAddress.trim() !== ''))
      ) {
        return true;
      }
      return false;
    },
    async save() {
      this.isLoading = true;
      const invoicingSettings = {
        fromEmail: this.fromEmail && this.fromEmail.trim(),
        fromName: this.fromName && this.fromName.trim(),
        bitcoinAddress: this.bitcoinAddress && this.bitcoinAddress.trim(),
        bitcoinXPub: this.bitcoinXPub && this.bitcoinXPub.trim(),
        bitcoinXPubPath: this.bitcoinXPubPath && this.bitcoinXPubPath.trim(),
        ethAddress: this.ethAddress && this.ethAddress.trim(),
        zecAddress: this.zecAddress && this.zecAddress.trim(),
        usdcAddress: this.usdcAddress && this.usdcAddress.trim(),
        usdtAddress: this.usdtAddress && this.usdtAddress.trim(),
        avaxAddress: this.avaxAddress && this.avaxAddress.trim(),
        alotAddress: this.alotAddress && this.alotAddress.trim(),
      };

      const vars = {
        orgId: this.$store.state.currentOrg.id,
        invoicingSettings,
      };
      const resp = await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $invoicingSettings: InvoicingSettingsInput) {
            updateOrg(
              orgId: $orgId
              name: null
              timezone: null
              preferredExchange: null
              baseCurrency: null
              invoicingSettings: $invoicingSettings
            ) {
              id
            }
          }
        `,
        variables: vars,
      });
      this.isLoading = false;
      if (resp && !resp.errors) {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'success',
          message: 'Successfully updated',
        });
        this.$store.commit(MUT_SET_INVOICING_SETTINGS, invoicingSettings);
        await this.$store.dispatch('updateOrgs');
      } else {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'success',
          message: 'Problem updating invoice settings: ' + resp.errors.join('<br />'),
        });
      }
    },
  },
  mounted() {
    const invoicingSettings = this.$store.state.currentOrg.invoicingSettings;
    this.fromEmail = invoicingSettings.fromEmail;
    this.fromName = invoicingSettings.fromName;
    this.bitcoinAddress = invoicingSettings.bitcoinAddress;
    this.bitcoinXPub = invoicingSettings.bitcoinXPub;
    this.bitcoinXPubPath = invoicingSettings.bitcoinXPubPath;
    this.ethAddress = invoicingSettings.ethAddress;
    this.zecAddress = invoicingSettings.zecAddress;
    this.usdcAddress = invoicingSettings.usdcAddress;
    this.usdtAddress = invoicingSettings.usdtAddress;
    this.avaxAddress = invoicingSettings.avaxAddress;
    this.alotAddress = invoicingSettings.alotAddress;
  },
};
</script>

<style>
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/*.stripe-card {*/
/*width: 300px;*/
/*border: 1px solid grey;*/
/*}*/
.stripe-card.complete {
  border-color: green;
}
</style>
