/* eslint-disable no-use-before-define */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigNumber: any;
  JSON: any;
  DateTime: any;
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  orgs?: Maybe<Array<Maybe<Org>>>;
  org?: Maybe<Org>;
  connections?: Maybe<Array<Maybe<Connection>>>;
  wallets?: Maybe<Array<Maybe<Wallet>>>;
  transactions?: Maybe<TransactionsResult>;
  transactionCounts?: Maybe<TransactionCounts>;
  transactionsLite?: Maybe<TransactionsResultLite>;
  transaction?: Maybe<Transaction>;
  me?: Maybe<User>;
  invites?: Maybe<Array<Maybe<OrgInvitation>>>;
  price?: Maybe<Price>;
  test?: Maybe<Test>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  report?: Maybe<Report>;
  reports?: Maybe<Array<Maybe<ReportPointer>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  remoteCategories?: Maybe<Array<Maybe<Category>>>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  bills?: Maybe<Array<Maybe<Bill>>>;
  nextReceiveAddress?: Maybe<Scalars['String']>;
  walletTransaction?: Maybe<WalletTransaction>;
  feeEstimates?: Maybe<Array<Maybe<FeeEstimate>>>;
  roots?: Maybe<Array<Maybe<Root>>>;
  exchanges?: Maybe<Array<Maybe<Exchange>>>;
  fiats?: Maybe<Array<Maybe<FiatType>>>;
  createBulkPaymentPreview?: Maybe<BulkPayment>;
  bulkPayments?: Maybe<Array<Maybe<BulkPaymentRecord>>>;
  bulkPayment?: Maybe<BulkPaymentRecord>;
  getImport?: Maybe<Import>;
  imports?: Maybe<Array<Maybe<Import>>>;
  taxes?: Maybe<Taxes>;
  authTokens?: Maybe<Array<Maybe<AuthToken>>>;
  staking?: Maybe<Staking>;
  exchangeRates?: Maybe<ExchangeRates>;
  features?: Maybe<Array<Maybe<FeatureFlag>>>;
  scopes?: Maybe<Array<Maybe<Scalars['String']>>>;
  userScopes?: Maybe<Array<Maybe<Scalars['String']>>>;
  defi?: Maybe<DeFi>;
  isAddressValid?: Maybe<IsValidAddress>;
  rule?: Maybe<TransferRule>;
  rules?: Maybe<Array<Maybe<TransferRule>>>;
  rulesPaginated?: Maybe<RulesPageResult>;
  search?: Maybe<Search>;
  metadata?: Maybe<Array<Maybe<Metadata>>>;
  historicPrices: HistoricPriceResult;
  availableTokens?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Maybe<Role>>>;
  scopeDefinitions?: Maybe<Array<Maybe<ScopeDefinition>>>;
  jobs?: Maybe<Array<Maybe<Job>>>;
  register: RegisterResponse;
  pricingRules?: Maybe<Array<PricingRule>>;
  defaultPricingRule?: Maybe<DefaultPricingRule>;
  getSystemJobs?: Maybe<SystemJobsResponse>;
  getOrgOpsPreCategorizedTransactions?: Maybe<Array<Maybe<PreCategorizeTransactionOutput>>>;
  tokenFilteringRulesConfig?: Maybe<TokenFilteringRulesConfig>;
  auditLogs?: Maybe<AuditLogResponse>;
  auditLog?: Maybe<AuditLogEntry>;
};

export type QueryOrgsArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryOrgArgs = {
  id: Scalars['ID'];
};

export type QueryConnectionsArgs = {
  orgId: Scalars['ID'];
};

export type QueryWalletsArgs = {
  orgId: Scalars['ID'];
  loadFairValue?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTransactionsArgs = {
  orgId: Scalars['ID'];
  transactionFilter?: InputMaybe<TransactionFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
};

export type QueryTransactionCountsArgs = {
  orgId: Scalars['ID'];
  walletId?: InputMaybe<Scalars['ID']>;
  ignoreFilter?: InputMaybe<IgnoreFilter>;
  pivotDate?: InputMaybe<Scalars['String']>;
};

export type QueryTransactionsLiteArgs = {
  orgId: Scalars['ID'];
  transactionFilter?: InputMaybe<TransactionFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
};

export type QueryTransactionArgs = {
  orgId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type QueryPriceArgs = {
  orgId: Scalars['ID'];
  coin: Coins;
  timestampSec?: InputMaybe<Scalars['Int']>;
};

export type QueryTasksArgs = {
  orgId: Scalars['ID'];
};

export type QueryReportArgs = {
  orgId: Scalars['ID'];
  reportId: Scalars['ID'];
};

export type QueryReportsArgs = {
  orgId: Scalars['ID'];
};

export type QueryCategoriesArgs = {
  orgId: Scalars['ID'];
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRemoteCategoriesArgs = {
  orgId: Scalars['ID'];
  connectionId: Scalars['ID'];
};

export type QueryContactsArgs = {
  orgId: Scalars['ID'];
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type QueryInvoicesArgs = {
  orgId: Scalars['ID'];
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
  addresses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  invoiceType?: InputMaybe<InvoiceType>;
};

export type QueryBillsArgs = {
  orgId: Scalars['ID'];
};

export type QueryNextReceiveAddressArgs = {
  orgId: Scalars['ID'];
  walletId: Scalars['ID'];
  coin: Coins;
};

export type QueryWalletTransactionArgs = {
  orgId: Scalars['ID'];
  walletId: Scalars['ID'];
  coin: Coins;
  txnId: Scalars['ID'];
};

export type QueryFeeEstimatesArgs = {
  coin: Coins;
};

export type QueryRootsArgs = {
  orgId: Scalars['ID'];
};

export type QueryCreateBulkPaymentPreviewArgs = {
  orgId: Scalars['ID'];
  name: Scalars['String'];
  coin: Coins;
  csv: Scalars['String'];
};

export type QueryBulkPaymentsArgs = {
  orgId: Scalars['ID'];
};

export type QueryBulkPaymentArgs = {
  orgId: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryGetImportArgs = {
  orgId: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryImportsArgs = {
  orgId: Scalars['ID'];
};

export type QueryAuthTokensArgs = {
  orgId: Scalars['ID'];
};

export type QueryFeaturesArgs = {
  orgId: Scalars['ID'];
};

export type QueryScopesArgs = {
  orgId: Scalars['ID'];
};

export type QueryUserScopesArgs = {
  orgId: Scalars['ID'];
};

export type QueryIsAddressValidArgs = {
  networkId: Scalars['String'];
  address: Scalars['String'];
};

export type QueryRuleArgs = {
  orgId: Scalars['ID'];
  ruleId: Scalars['ID'];
};

export type QueryRulesArgs = {
  orgId: Scalars['ID'];
};

export type QueryRulesPaginatedArgs = {
  orgId: Scalars['ID'];
  paginationTokenOpt?: InputMaybe<Scalars['String']>;
  pageLimit?: InputMaybe<Scalars['Int']>;
};

export type QueryMetadataArgs = {
  orgId: Scalars['ID'];
  connectionId?: InputMaybe<Scalars['ID']>;
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type QueryHistoricPricesArgs = {
  orgId: Scalars['ID'];
  coin: Scalars['String'];
  fromTimestampSec?: InputMaybe<Scalars['Int']>;
  toTimestampSec?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  pageToken?: InputMaybe<Scalars['String']>;
};

export type QueryAvailableTokensArgs = {
  orgId: Scalars['ID'];
};

export type QueryRolesArgs = {
  orgId: Scalars['ID'];
};

export type QueryJobsArgs = {
  orgId: Scalars['ID'];
};

export type QueryRegisterArgs = {
  orgId: Scalars['ID'];
  filter: RegisterFilter;
};

export type QueryPricingRulesArgs = {
  orgId: Scalars['ID'];
};

export type QueryDefaultPricingRuleArgs = {
  orgId: Scalars['ID'];
};

export type QueryGetSystemJobsArgs = {
  orgId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  paginationOption?: InputMaybe<Scalars['String']>;
};

export type QueryGetOrgOpsPreCategorizedTransactionsArgs = {
  orgId: Scalars['ID'];
};

export type QueryTokenFilteringRulesConfigArgs = {
  orgId: Scalars['ID'];
};

export type QueryAuditLogsArgs = {
  orgId: Scalars['ID'];
  filter?: InputMaybe<AuditLogFilter>;
  paginationToken?: InputMaybe<Scalars['String']>;
  pageLimit?: InputMaybe<Scalars['Int']>;
};

export type QueryAuditLogArgs = {
  id: Scalars['ID'];
  withDetails?: InputMaybe<Scalars['Boolean']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  scopes: Array<Maybe<Scalars['String']>>;
  editable: Scalars['Boolean'];
};

export type ScopeDefinition = {
  __typename?: 'ScopeDefinition';
  scope: Scalars['String'];
  friendlyEntity: Scalars['String'];
  friendlySubEntity?: Maybe<Scalars['String']>;
  permissionType: PermissionType;
  description: Scalars['String'];
  requiredOrgFlags?: Maybe<Array<Maybe<Scalars['String']>>>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum PermissionType {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
  Other = 'other',
}

export type ExchangeRates = {
  __typename?: 'ExchangeRates';
  lookup?: Maybe<ExchangeRateLookup>;
};

export type ExchangeRatesLookupArgs = {
  orgId: Scalars['ID'];
  fromSymbol: Scalars['String'];
  dateTimeSEC: Scalars['Int'];
};

export type Taxes = {
  __typename?: 'Taxes';
  runScenario?: Maybe<TaxScenarioResult>;
};

export type TaxesRunScenarioArgs = {
  orgId: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  strategy?: InputMaybe<TaxStrategyType>;
  config?: InputMaybe<TaxConfigInput>;
  overrideExchangeRates?: InputMaybe<Array<InputMaybe<ExchangeRateOverride>>>;
  impair?: InputMaybe<Scalars['Boolean']>;
  ignoreNFTs?: InputMaybe<Scalars['Boolean']>;
};

export type Staking = {
  __typename?: 'Staking';
  getHistory?: Maybe<StakingHistory>;
};

export type StakingGetHistoryArgs = {
  orgId?: InputMaybe<Scalars['String']>;
  targetId: Scalars['String'];
  holderId?: InputMaybe<Scalars['String']>;
  beforeDateSEC?: InputMaybe<Scalars['Int']>;
};

export type DeFi = {
  __typename?: 'DeFi';
  roi?: Maybe<DeFiRoi>;
};

export type DeFiRoiArgs = {
  orgId: Scalars['ID'];
};

export type DeFiRoi = {
  __typename?: 'DeFiRoi';
  details?: Maybe<Array<Maybe<PlatformDeFiDetails>>>;
};

export type PlatformDeFiDetails = {
  __typename?: 'PlatformDeFiDetails';
  platformId: Scalars['String'];
  assetIdentifier: Scalars['String'];
  lines: Array<Maybe<DeFiRoiLine>>;
  totalDeposits: Array<Maybe<DeFiRoiAsset>>;
  totalWithdrawals: Array<Maybe<DeFiRoiAsset>>;
  finalBalance: Array<Maybe<DeFiRoiAsset>>;
  claimedRewards?: Maybe<Scalars['BigNumber']>;
  totalDays: Scalars['Float'];
  apy: Scalars['BigNumber'];
};

export type DeFiRoiAsset = {
  __typename?: 'DeFiRoiAsset';
  asset: Scalars['String'];
  value: Scalars['BigNumber'];
};

export type DeFiAssetLine = {
  __typename?: 'DeFiAssetLine';
  asset: Scalars['String'];
  startingBalance: Scalars['BigNumber'];
  contribution: Scalars['BigNumber'];
  endingBalance: Scalars['BigNumber'];
  baseAssetStartingBalance: Scalars['BigNumber'];
  baseAssetContribution: Scalars['BigNumber'];
  baseAssetEndingBalance: Scalars['BigNumber'];
};

export type DeFiRoiLine = {
  __typename?: 'DeFiRoiLine';
  lineDateSEC: Scalars['Int'];
  daysSinceLastChange?: Maybe<Scalars['Int']>;
  daysSincePositionOpen: Scalars['Float'];
  assets: Array<Maybe<DeFiAssetLine>>;
  claimedRewards: Scalars['BigNumber'];
  unclaimedRewards: Scalars['BigNumber'];
  hpr?: Maybe<Scalars['BigNumber']>;
  aggregateLineReturn?: Maybe<Scalars['BigNumber']>;
  apy?: Maybe<Scalars['BigNumber']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCategory?: Maybe<OpResult>;
  createWallet?: Maybe<Wallet>;
  deleteWallet?: Maybe<Result>;
  createRoot?: Maybe<Wallet>;
  categorizeTransaction?: Maybe<Transaction>;
  createOrganization?: Maybe<ResponseCreateOrganization>;
  updateTransaction?: Maybe<Transaction>;
  updateTransactionMemo?: Maybe<Transaction>;
  updateTransactionIgnoreStatus?: Maybe<Transaction>;
  markTransactionAsReconciled?: Maybe<Transaction>;
  uncategorizeTransactions?: Maybe<Scalars['Boolean']>;
  preCategorizeOpsTransaction?: Maybe<Scalars['Boolean']>;
  acceptTrade?: Maybe<Scalars['Boolean']>;
  updateExternalTaxDetails?: Maybe<Scalars['Boolean']>;
  updateAccountingSetup?: Maybe<Org>;
  runInitialBalanceExport?: Maybe<ReportPointer>;
  updateOrg?: Maybe<Org>;
  updateOrgAccountingConfig?: Maybe<Org>;
  inviteUser?: Maybe<OpResult>;
  runReport?: Maybe<Report>;
  createConnection?: Maybe<Scalars['String']>;
  setupConnection?: Maybe<OpResult>;
  syncConnection?: Maybe<OpResult>;
  revokeToken?: Maybe<DisconnectResponse>;
  disconnectConnection?: Maybe<DisconnectResponse>;
  reconnectToken?: Maybe<ReconnectTokenResponse>;
  updateAccountingConnection?: Maybe<Scalars['String']>;
  updateCategory?: Maybe<Category>;
  disableCategories?: Maybe<OpResult>;
  createBill?: Maybe<Bill>;
  payBills?: Maybe<Scalars['Boolean']>;
  reconcileTransactions?: Maybe<Array<Maybe<ReconciliationResult>>>;
  unreconcileTransaction?: Maybe<Transaction>;
  unReconcileTransaction?: Maybe<Scalars['Boolean']>;
  combineTransactions?: Maybe<Transaction>;
  unCombineTransaction?: Maybe<Scalars['Boolean']>;
  createTransaction?: Maybe<WalletTransaction>;
  deleteTransaction?: Maybe<Scalars['Boolean']>;
  createManualTransaction?: Maybe<CreateTransactionResponse>;
  signTransaction?: Maybe<WalletTransaction>;
  updateUser?: Maybe<OpResult>;
  updateOrgUser?: Maybe<User>;
  acceptInvitation?: Maybe<Scalars['Boolean']>;
  setContactCoinAddresses?: Maybe<Scalars['Boolean']>;
  createUrlTokenForInvoice?: Maybe<Scalars['String']>;
  subscribe?: Maybe<SubscriptionResponse>;
  updateSubscription?: Maybe<SubscriptionResponse>;
  updateTaxSetup?: Maybe<Org>;
  updateWallet?: Maybe<Wallet>;
  updateWalletValidationStatus?: Maybe<OpResult>;
  runTaxMatch?: Maybe<OpResult>;
  createBulkPayment?: Maybe<OpResult>;
  updateBulkPayment?: Maybe<OpResult>;
  deleteBulkPayment?: Maybe<OpResult>;
  createContact?: Maybe<Scalars['String']>;
  updateContact?: Maybe<Contact>;
  disableContacts?: Maybe<OpResult>;
  createImport?: Maybe<Import>;
  validateImport?: Maybe<Scalars['ID']>;
  runImport?: Maybe<Scalars['ID']>;
  createAuthToken?: Maybe<AuthTokenCreate>;
  deleteAuthToken?: Maybe<Scalars['Boolean']>;
  createRule?: Maybe<OpResult>;
  updateRule?: Maybe<OpResult>;
  deleteRule?: Maybe<Scalars['Boolean']>;
  toggleRuleStatus?: Maybe<Scalars['Boolean']>;
  saveRole?: Maybe<OpResult>;
  createPricingRule?: Maybe<OpResult>;
  updatePricingRule?: Maybe<OpResult>;
  createDefaultPricingRule?: Maybe<OpResult>;
  runSystemJob?: Maybe<Scalars['String']>;
  runRulesForOrg?: Maybe<Scalars['Boolean']>;
  updateTokenFilterRule?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateCategoryArgs = {
  orgId: Scalars['ID'];
  category: CreateCategoryInput;
};

export type MutationCreateWalletArgs = {
  orgId: Scalars['ID'];
  wallet: WalletInput;
  prems: Array<InputMaybe<WalletPermissionInput>>;
};

export type MutationDeleteWalletArgs = {
  orgId: Scalars['ID'];
  walletId: Scalars['ID'];
};

export type MutationCreateRootArgs = {
  orgId: Scalars['ID'];
  root: RootInput;
};

export type MutationCategorizeTransactionArgs = {
  orgId: Scalars['ID'];
  txnId: Scalars['ID'];
  transactionData: TransactionData;
};

export type MutationCreateOrganizationArgs = {
  requestCreateOrganizations?: InputMaybe<RequestCreateOrganizations>;
};

export type MutationUpdateTransactionArgs = {
  orgId: Scalars['ID'];
  id: Scalars['ID'];
  ignore?: InputMaybe<Scalars['Boolean']>;
  memo?: InputMaybe<Scalars['String']>;
  reconciliationStatus?: InputMaybe<ReconciliationStatus>;
  categorizationStatus?: InputMaybe<CategorizationStatus>;
};

export type MutationUncategorizeTransactionsArgs = {
  orgId: Scalars['ID'];
  transactionIds: Array<Scalars['ID']>;
};

export type MutationPreCategorizeOpsTransactionArgs = {
  orgId: Scalars['ID'];
  txnId: Scalars['ID'];
  transactionData: TransactionData;
};

export type MutationAcceptTradeArgs = {
  orgId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationUpdateExternalTaxDetailsArgs = {
  orgId: Scalars['ID'];
  id: Scalars['ID'];
  externalTaxDetails: ExternalTaxDetailsInput;
};

export type MutationUpdateAccountingSetupArgs = {
  orgId: Scalars['ID'];
  accountingSetup?: InputMaybe<AccountingSetupInput>;
};

export type MutationRunInitialBalanceExportArgs = {
  orgId: Scalars['ID'];
  beforeDate: Scalars['String'];
};

export type MutationUpdateOrgArgs = {
  orgId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  userRoles?: InputMaybe<Array<InputMaybe<UserRoleInput>>>;
  assertedIdentity?: InputMaybe<Scalars['String']>;
  preferredExchange?: InputMaybe<Scalars['String']>;
  baseCurrency?: InputMaybe<Fiat>;
  sso?: InputMaybe<SsoInput>;
  invoicingSettings?: InputMaybe<InvoicingSettingsInput>;
  taxConfig?: InputMaybe<TaxConfigInput>;
  specificIdentificationStrategyRanges?: InputMaybe<Array<InputMaybe<SpecificIdentificationStrategyInput>>>;
  displayConfig?: InputMaybe<DisplayConfigInput>;
  hardCloseDate?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateOrgAccountingConfigArgs = {
  orgId: Scalars['ID'];
  networkContact?: InputMaybe<NetworkContactInput>;
  defaultFeeCategoryId?: InputMaybe<Scalars['String']>;
  defaultAccountsPayableCategoryId?: InputMaybe<Scalars['String']>;
  defaultAccountsReceivableCategoryId?: InputMaybe<Scalars['String']>;
  allowTxnInference?: InputMaybe<Scalars['Boolean']>;
  collapseAcrossWallets?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInviteUserArgs = {
  orgId: Scalars['ID'];
  invite: InviteInput;
};

export type MutationRunReportArgs = {
  orgId: Scalars['ID'];
  reportDetails: ReportDetailsInput;
};

export type MutationCreateConnectionArgs = {
  orgId: Scalars['ID'];
  create: CreateConnectionInput;
};

export type MutationSetupConnectionArgs = {
  orgId: Scalars['ID'];
  connectionId: Scalars['ID'];
  accountCode: Scalars['String'];
  feeAccountCode: Scalars['String'];
  newAccountCode?: InputMaybe<Scalars['String']>;
  newFeeAccountCode?: InputMaybe<Scalars['String']>;
};

export type MutationSyncConnectionArgs = {
  orgId: Scalars['ID'];
  connectionId: Scalars['ID'];
};

export type MutationRevokeTokenArgs = {
  orgId: Scalars['ID'];
  connectionId: Scalars['ID'];
};

export type MutationDisconnectConnectionArgs = {
  orgId: Scalars['ID'];
  connectionId: Scalars['ID'];
};

export type MutationReconnectTokenArgs = {
  orgId: Scalars['ID'];
  connectionId: Scalars['ID'];
};

export type MutationUpdateAccountingConnectionArgs = {
  orgId: Scalars['ID'];
  connectionId: Scalars['ID'];
  accountCode?: InputMaybe<Scalars['String']>;
  feeAccountCode?: InputMaybe<Scalars['String']>;
  connectionSpecificFields?: InputMaybe<AccountingConnectionSpecificFields>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateCategoryArgs = {
  orgId: Scalars['ID'];
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
};

export type MutationDisableCategoriesArgs = {
  orgId: Scalars['ID'];
};

export type MutationCreateBillArgs = {
  orgId: Scalars['ID'];
  bill: BillInput;
};

export type MutationPayBillsArgs = {
  orgId: Scalars['ID'];
  bills: Array<InputMaybe<BillInput>>;
  fee: Scalars['Float'];
};

export type MutationReconcileTransactionsArgs = {
  orgId: Scalars['ID'];
  transactionIds: Array<InputMaybe<Scalars['String']>>;
  forceReconcile?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUnReconcileTransactionArgs = {
  orgId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationCombineTransactionsArgs = {
  orgId: Scalars['ID'];
  txnIds: Array<InputMaybe<Scalars['ID']>>;
};

export type MutationUnCombineTransactionArgs = {
  orgId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationCreateTransactionArgs = {
  orgId: Scalars['ID'];
  walletId: Scalars['ID'];
  coin: Coins;
  create: CreateTransactionInput;
};

export type MutationDeleteTransactionArgs = {
  orgId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationCreateManualTransactionArgs = {
  orgId: Scalars['ID'];
  sourceId: Scalars['ID'];
  txn: TxnCreateTransaction;
};

export type MutationSignTransactionArgs = {
  orgId: Scalars['ID'];
  walletId: Scalars['ID'];
  coin: Coins;
  taskId: Scalars['ID'];
  txnId: Scalars['ID'];
  signature: SignatureInput;
};

export type MutationUpdateUserArgs = {
  userId: Scalars['ID'];
  user: UserInput;
};

export type MutationUpdateOrgUserArgs = {
  orgId: Scalars['ID'];
  userId: Scalars['ID'];
  orgUser: OrgUserInput;
};

export type MutationAcceptInvitationArgs = {
  inviteId: Scalars['ID'];
};

export type MutationSetContactCoinAddressesArgs = {
  orgId: Scalars['ID'];
  contactId: Scalars['ID'];
  addresses: Array<ContactAddressInput>;
};

export type MutationCreateUrlTokenForInvoiceArgs = {
  orgId: Scalars['ID'];
  invoiceId: Scalars['ID'];
};

export type MutationSubscribeArgs = {
  orgId: Scalars['ID'];
  paymentToken: Scalars['String'];
  details?: InputMaybe<SubscriptionDetailsInput>;
  coupon?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateSubscriptionArgs = {
  orgId: Scalars['ID'];
  paymentToken: Scalars['String'];
};

export type MutationUpdateTaxSetupArgs = {
  orgId: Scalars['ID'];
  taxSetup?: InputMaybe<TaxSetupInput>;
};

export type MutationUpdateWalletArgs = {
  orgId: Scalars['ID'];
  walletId: Scalars['ID'];
  multisendContractAddress?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  addWallets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  removeWallets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MutationUpdateWalletValidationStatusArgs = {
  orgId: Scalars['ID'];
  walletId: Scalars['ID'];
  validationStatus?: InputMaybe<ValidateWalletRequest>;
};

export type MutationRunTaxMatchArgs = {
  orgId: Scalars['ID'];
};

export type MutationCreateBulkPaymentArgs = {
  orgId: Scalars['ID'];
  name: Scalars['String'];
  coin: Coins;
  csv: Scalars['String'];
};

export type MutationUpdateBulkPaymentArgs = {
  orgId: Scalars['ID'];
  id: Scalars['ID'];
  bulkPaymentStatus?: InputMaybe<BulkPaymentStatus>;
  paymentTransactionId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteBulkPaymentArgs = {
  orgId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationCreateContactArgs = {
  orgId: Scalars['ID'];
  contact: CreateContactInput;
};

export type MutationUpdateContactArgs = {
  orgId: Scalars['ID'];
  contact: UpdateContactInput;
};

export type MutationDisableContactsArgs = {
  orgId: Scalars['ID'];
};

export type MutationCreateImportArgs = {
  orgId: Scalars['ID'];
};

export type MutationValidateImportArgs = {
  orgId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationRunImportArgs = {
  orgId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationCreateAuthTokenArgs = {
  orgId: Scalars['ID'];
  description: Scalars['String'];
  permissions: Array<InputMaybe<AuthTokenPermissions>>;
};

export type MutationDeleteAuthTokenArgs = {
  orgId: Scalars['ID'];
  clientId: Scalars['ID'];
};

export type MutationCreateRuleArgs = {
  orgId: Scalars['ID'];
  rule: Rule;
};

export type MutationUpdateRuleArgs = {
  orgId: Scalars['ID'];
  ruleId: Scalars['ID'];
  rule: Rule;
};

export type MutationDeleteRuleArgs = {
  orgId: Scalars['ID'];
  ruleId: Scalars['ID'];
};

export type MutationToggleRuleStatusArgs = {
  orgId: Scalars['ID'];
  ruleId: Scalars['ID'];
  disabled: Scalars['Boolean'];
};

export type MutationSaveRoleArgs = {
  orgId: Scalars['ID'];
  role: SaveRole;
};

export type MutationCreatePricingRuleArgs = {
  orgId: Scalars['ID'];
  rule: PricingRuleInput;
};

export type MutationUpdatePricingRuleArgs = {
  orgId: Scalars['ID'];
  rule: PricingRuleInput;
};

export type MutationCreateDefaultPricingRuleArgs = {
  orgId: Scalars['ID'];
  rule: DefaultPricingRuleInput;
};

export type MutationRunSystemJobArgs = {
  orgId: Scalars['ID'];
  systemJobId: Scalars['String'];
  action: Scalars['String'];
  walletId?: InputMaybe<Scalars['String']>;
  startSEC?: InputMaybe<Scalars['String']>;
  endSEC?: InputMaybe<Scalars['String']>;
};

export type MutationRunRulesForOrgArgs = {
  orgId: Scalars['ID'];
};

export type MutationUpdateTokenFilterRuleArgs = {
  orgId: Scalars['ID'];
  details?: InputMaybe<TokenFilterRule>;
};

export enum Coins {
  Btc = 'BTC',
  Eth = 'ETH',
  Eos = 'EOS',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Dcr = 'DCR',
  Zen = 'ZEN',
  Zec = 'ZEC',
  Cdt = 'CDT',
  Bnb = 'BNB',
  Xzc = 'XZC',
  Salt = 'SALT',
  Poly = 'POLY',
  Usd = 'USD',
  Bat = 'BAT',
  Oxt = 'OXT',
  Dash = 'DASH',
  Avax = 'AVAX',
}

export enum Fiat {
  Usd = 'USD',
  Gbp = 'GBP',
  Jpy = 'JPY',
  Eur = 'EUR',
  Sgd = 'SGD',
  Chf = 'CHF',
  Cad = 'CAD',
  Krw = 'KRW',
  Nok = 'NOK',
  Dkk = 'DKK',
  Nzd = 'NZD',
  Isk = 'ISK',
  Hkd = 'HKD',
  Cny = 'CNY',
  Pln = 'PLN',
  Aud = 'AUD',
  Ars = 'ARS',
  Brl = 'BRL',
  Clp = 'CLP',
  Cop = 'COP',
  Czk = 'CZK',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Mxn = 'MXN',
  Myr = 'MYR',
  Php = 'PHP',
  Sek = 'SEK',
  Thb = 'THB',
  Vnd = 'VND',
  Zar = 'ZAR',
  Rub = 'RUB',
  Ron = 'RON',
  Zwd = 'ZWD',
}

export enum Units {
  Unknown = 'Unknown',
  Satoshi = 'Satoshi',
  Bitcoin = 'Bitcoin',
  Wei = 'Wei',
  Ether = 'Ether',
  Eos = 'EOS',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Dcr = 'DCR',
  Zen = 'ZEN',
  Zec = 'ZEC',
  Cdt = 'CDT',
  Bnb = 'BNB',
  Xzc = 'XZC',
  Salt = 'SALT',
  Poly = 'POLY',
  Usd = 'USD',
  Bat = 'BAT',
}

export enum Permissions {
  Read = 'Read',
  Write = 'Write',
  Admin = 'Admin',
}

export enum CategorizationStatus {
  Categorized = 'Categorized',
  Uncategorized = 'Uncategorized',
}

export enum ReconciliationStatus {
  Unreconciled = 'Unreconciled',
  Reconciled = 'Reconciled',
}

export enum CategorizationFilter {
  All = 'All',
  Categorized = 'Categorized',
  Uncategorized = 'Uncategorized',
}

export enum ReconciliationFilter {
  All = 'All',
  Unreconciled = 'Unreconciled',
  Reconciled = 'Reconciled',
}

export enum IgnoreFilter {
  All = 'All',
  Ignored = 'Ignored',
  Unignored = 'Unignored',
}

export enum EmailContactPreference {
  OnNewTaskAssigned = 'OnNewTaskAssigned',
}

export type ExchangeRateLookup = {
  __typename?: 'ExchangeRateLookup';
  found: Scalars['Boolean'];
  toSymbol: Scalars['String'];
  coin?: Maybe<Scalars['String']>;
  coinUnit?: Maybe<Scalars['String']>;
  fiat?: Maybe<Scalars['String']>;
  rate?: Maybe<ExchangeRate>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  emailContactPreferences?: Maybe<Array<Maybe<EmailContactPreference>>>;
  assertedIdentity?: Maybe<Scalars['String']>;
  tosAcceptance?: Maybe<TosAcceptance>;
};

export type TosAcceptance = {
  __typename?: 'TOSAcceptance';
  acceptedOn?: Maybe<Scalars['Int']>;
  tosVersion?: Maybe<Scalars['String']>;
};

export type UserPointer = {
  __typename?: 'UserPointer';
  userId: Scalars['ID'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export type ContactAddress = {
  __typename?: 'ContactAddress';
  coin?: Maybe<Coins>;
  address: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  defaultExpenseCategoryId?: Maybe<Scalars['String']>;
  defaultRevenueCategoryId?: Maybe<Scalars['String']>;
  networkId?: Scalars['String'];
};

export type AccountingSetup = {
  __typename?: 'AccountingSetup';
  initialSyncMethod: InitialSyncMethods;
  isFinished: Scalars['Boolean'];
  isReconciled?: Maybe<Scalars['Boolean']>;
  balanceExportStartDate?: Maybe<Scalars['String']>;
  balanceExportReportId?: Maybe<Scalars['String']>;
};

export type AccountingConfig = {
  __typename?: 'AccountingConfig';
  multiCurrency?: Maybe<Scalars['Boolean']>;
  separateFeeTransactions?: Maybe<Scalars['Boolean']>;
  networkContactIds?: Maybe<NetworkContact>;
  defaultFeeCategoryId?: Maybe<Scalars['String']>;
  defaultAccountsReceivableCategoryId?: Maybe<Scalars['String']>;
  defaultAccountsPayableCategoryId?: Maybe<Scalars['String']>;
  allowTxnInference?: Maybe<Scalars['Boolean']>;
  collapseAcrossWallets?: Maybe<Scalars['Boolean']>;
};

export type Permission = {
  __typename?: 'Permission';
  user?: Maybe<User>;
  permission?: Maybe<Permissions>;
};

export type WalletBalance = {
  __typename?: 'WalletBalance';
  balanceId?: Maybe<Scalars['String']>;
  balances?: Maybe<Array<Maybe<Amount>>>;
  totalFiatValue?: Maybe<FiatValue>;
};

export type Wallet = {
  __typename?: 'Wallet';
  id?: Maybe<Scalars['String']>;
  connectionId?: Maybe<Scalars['String']>;
  exchangeConnectionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  deviceType?: Maybe<Scalars['String']>;
  networkId?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addresses?: Maybe<Array<Maybe<Scalars['String']>>>;
  path?: Maybe<Scalars['String']>;
  balance?: Maybe<WalletBalance>;
  enabledCoins?: Maybe<Array<Maybe<Coins>>>;
  bulkSend?: Maybe<WalletBulkSettings>;
  groupId?: Maybe<Scalars['String']>;
  isSyncEnabled?: Maybe<Scalars['Boolean']>;
  isBalanceMonitoringOnly?: Maybe<Scalars['Boolean']>;
  isSyncEnabledUser?: Maybe<Scalars['Boolean']>;
  isSyncEnabledSystem?: Maybe<Scalars['Boolean']>;
  lastSuccessfulSyncSEC?: Maybe<Scalars['Int']>;
  lastSuccessfulBalanceCheckSEC?: Maybe<Scalars['Int']>;
  latestBalanceCheck?: Maybe<BalanceCheckResult>;
  createdSEC?: Maybe<Scalars['Int']>;
  subsidiaryId?: Maybe<Scalars['String']>;
  flags?: Maybe<WalletFlags>;
};

export type WalletFlags = {
  solanaInflationStakingRewardsEnabled?: Maybe<Scalars['Boolean']>;
  solanaInflationStakingRewardsStartTime?: Maybe<Scalars['Int']>;
  solanaInflationStakingRewardsEndTime?: Maybe<Scalars['Int']>;
  syncStartDateSEC?: Maybe<'Int'>;
  syncEndDateSEC?: Maybe<'Int'>;
  syncerVersion?: Maybe<'Int'>;
  upsertV2?: Maybe<'Boolean'>;
  trackingMode?: Maybe<'String'>;
};

export type BalanceCheckResult = {
  __typename?: 'BalanceCheckResult';
  metaSuccess?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Array<Maybe<BalanceCompareResult>>>;
  serviceName?: Maybe<Scalars['String']>;
  metaIsNegative?: Maybe<Scalars['Boolean']>;
  balanceId?: Maybe<Scalars['String']>;
};

export type BalanceCompareResult = {
  __typename?: 'BalanceCompareResult';
  controlSource?: Maybe<Scalars['String']>;
  controlTimestamp?: Maybe<Scalars['Int']>;
  testSource?: Maybe<Scalars['String']>;
  testTimestamp?: Maybe<Scalars['Int']>;
  totalFiatValue?: Maybe<Scalars['BigNumber']>;
  totalFiatDelta?: Maybe<Scalars['BigNumber']>;
  lineDeltas?: Maybe<Array<Maybe<LineDelta>>>;
  succeedsThresholdCheck?: Maybe<Scalars['Boolean']>;
  isNegative?: Maybe<Scalars['Boolean']>;
  warnings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LineDelta = {
  __typename?: 'LineDelta';
  ticker?: Maybe<Scalars['String']>;
  succeededCheck?: Maybe<Scalars['Boolean']>;
  isNegative?: Maybe<Scalars['Boolean']>;
  localValue?: Maybe<Scalars['BigNumber']>;
  localFiatValue?: Maybe<Scalars['BigNumber']>;
  remoteValue?: Maybe<Scalars['BigNumber']>;
  remoteFiatValue?: Maybe<Scalars['BigNumber']>;
  warnings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WalletBulkSettings = {
  __typename?: 'WalletBulkSettings';
  ethereumMultiSendContractAddress?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  canEnable?: Maybe<Scalars['Boolean']>;
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  rate?: Maybe<Scalars['Float']>;
  displayRate?: Maybe<Scalars['String']>;
  dateTime?: Maybe<Scalars['Int']>;
};

export type Amount = {
  __typename?: 'Amount';
  displayValue?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['BigNumber']>;
  coin?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  fiatValue?: Maybe<FiatValue>;
};

export enum TransactionStatus {
  New = 'New',
  Unreconciled = 'Unreconciled',
  Reconciled = 'Reconciled',
}

export type FiatValue = {
  __typename?: 'FiatValue';
  value?: Maybe<Scalars['Float']>;
  displayValue?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<ExchangeRate>;
  currency?: Maybe<Scalars['String']>;
};

export type CategorizationData = {
  __typename?: 'CategorizationData';
  contactId: Scalars['ID'];
  categoryId: Scalars['ID'];
  description: Scalars['String'];
  costBasis?: Maybe<CostBasis>;
};

export type PreCategorizeTransactionOutput = {
  __typename?: 'PreCategorizeTransactionOutput';
  orgId?: Maybe<Scalars['String']>;
  txnId?: Maybe<Scalars['String']>;
  transactionData?: Maybe<TransactionDataOutput>;
};

export type TransactionDataOutput = {
  __typename?: 'TransactionDataOutput';
  multivalue?: Maybe<MultiValueTransactionOutput>;
  opsPreTransaction?: Maybe<PreCategorizedOpsTransactionOutput>;
};

export type PreCategorizedOpsTransactionOutput = {
  __typename?: 'PreCategorizedOpsTransactionOutput';
  name: Scalars['String'];
  payment: MultiValueTransactionOutput;
  networkId: Scalars['String'];
  tokenContract: Scalars['String'];
  tokenTicker: Scalars['String'];
  status: PaymentStatusOutput;
  transactionId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type PaymentStatusOutput = {
  __typename?: 'PaymentStatusOutput';
  created: Scalars['String'];
  sent?: Maybe<Scalars['String']>;
  synced?: Maybe<Scalars['Boolean']>;
};

export type MultiValueTransactionOutput = {
  __typename?: 'MultiValueTransactionOutput';
  items: Array<MultiValueTransactionItemOutput>;
  exchangeRates: Array<ExchangeRateOutput>;
  metadataIds?: Maybe<Array<Scalars['ID']>>;
};

export type MultiValueTransactionItemOutput = {
  __typename?: 'MultiValueTransactionItemOutput';
  contactId: Scalars['String'];
  transactionType: TransactionType;
  lines: Array<MultiValueTransactionLineOutput>;
  metadataIds?: Maybe<Array<Scalars['ID']>>;
};

export type MultiValueTransactionLineOutput = {
  __typename?: 'MultiValueTransactionLineOutput';
  categoryId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sourceTicker: Scalars['String'];
  sourceAmount: Scalars['String'];
  fiat: Scalars['String'];
  fiatAmount: Scalars['String'];
  metadataIds?: Maybe<Array<Scalars['ID']>>;
  address?: Maybe<Scalars['String']>;
  forceZeroGainLoss?: Maybe<Scalars['Boolean']>;
};

export type ExchangeRateOutput = {
  __typename?: 'ExchangeRateOutput';
  coin: Scalars['String'];
  unit: Scalars['String'];
  fiat: Scalars['String'];
  rate: Scalars['BigNumber'];
  source?: Maybe<ExchangeRateSource>;
};

export enum CategoryType {
  Expense = 'Expense',
  Revenue = 'Revenue',
  Equity = 'Equity',
  Liability = 'Liability',
  Asset = 'Asset',
  Bank = 'Bank',
  Other = 'Other',
}

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CategoryType>;
  accountingConnectionId?: Maybe<Scalars['ID']>;
  lastUpdatedSEC?: Maybe<Scalars['Int']>;
};

export type StakingHistory = {
  __typename?: 'StakingHistory';
  totalDeposits: Array<Maybe<ValueDetail>>;
  totalWithdrawals: Array<Maybe<ValueDetail>>;
  totalRecognizedRevenue: Array<Maybe<ValueDetail>>;
};

export enum ContactType {
  None = 'None',
  Customer = 'Customer',
  Vendor = 'Vendor',
  Employee = 'Employee',
}

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  name: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  type: ContactType;
  source?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  addresses: Array<ContactAddress>;
  accountingConnectionId?: Maybe<Scalars['ID']>;
  defaultExpenseCategoryId?: Maybe<Scalars['String']>;
  defaultRevenueCategoryId?: Maybe<Scalars['String']>;
};

export type CostBasis = {
  __typename?: 'CostBasis';
  cost: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  currency: Scalars['String'];
  invoiceId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  costBasisType: CostBasisType;
};

export type SellTransactionDetail = {
  __typename?: 'SellTransactionDetail';
  dateTimeSec?: Maybe<Scalars['Int']>;
  source: Scalars['String'];
  sourceAmount: Scalars['Float'];
  target: Fiat;
  targetAmount: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  proceedsAccount?: Maybe<Scalars['ID']>;
  fee: Scalars['Float'];
  feeContact?: Maybe<Scalars['ID']>;
};

export type SimpleTransactionDetail = {
  __typename?: 'SimpleTransactionDetail';
  contactId: Scalars['ID'];
  contact?: Maybe<Contact>;
  categoryId: Scalars['ID'];
  category?: Maybe<Category>;
  description: Scalars['String'];
  costBasis?: Maybe<CostBasis>;
};

export type StakingTransactionDetail = {
  __typename?: 'StakingTransactionDetail';
  contactId: Scalars['ID'];
  contact?: Maybe<Contact>;
  categoryId: Scalars['ID'];
  category?: Maybe<Category>;
  description: Scalars['String'];
  costBasis?: Maybe<CostBasis>;
  stakingTxnType?: Maybe<StakingTxnType>;
};

export type AssetStakingTransactionDetail = {
  __typename?: 'AssetStakingTransactionDetail';
  type: AssetStakingTxnType;
  stakedAssets: Array<Maybe<ValueDetail>>;
  withdrawnAssets?: Maybe<Array<Maybe<ValueDetail>>>;
  totalHoldings?: Maybe<Array<Maybe<ValueDetail>>>;
  recognizedRevenue?: Maybe<Array<Maybe<MultiValueTransactionItem>>>;
  poolDetails?: Maybe<AssetStakingPoolDetails>;
  block: BlockPointer;
  holdingAddress: AddressPointer;
  stakingTarget: AddressPointer;
  exchangeRates: Array<Maybe<ExchangeRateDetails>>;
  fee?: Maybe<DetailedMultiFee>;
};

export type DeFiTransferTransactionDetail = {
  __typename?: 'DeFiTransferTransactionDetail';
  stakingTarget: AddressPointer;
  fromAddress: AddressPointer;
  toAddress: AddressPointer;
  fromTotalHoldings?: Maybe<Array<Maybe<ValueDetail>>>;
  toTotalHoldings?: Maybe<Array<Maybe<ValueDetail>>>;
  withdrawnAssets?: Maybe<Array<Maybe<ValueDetail>>>;
  recognizedRevenue?: Maybe<Array<Maybe<MultiValueTransactionItem>>>;
  poolDetails?: Maybe<AssetStakingPoolDetails>;
  block: BlockPointer;
  exchangeRates: Array<Maybe<ExchangeRateDetails>>;
  fee?: Maybe<DetailedMultiFee>;
};

export type DetailedMultiFee = {
  __typename?: 'DetailedMultiFee';
  feeItems?: Maybe<Array<Maybe<FeeItem>>>;
  feeContactId: Scalars['String'];
};

export type FeeItem = {
  __typename?: 'FeeItem';
  amount: ValueDetail;
  costBasis: CostBasis;
};

export type BlockPointer = {
  __typename?: 'BlockPointer';
  networkId: Scalars['String'];
  blockId: Scalars['String'];
};

export type ValueDetail = {
  __typename?: 'ValueDetail';
  value: Scalars['BigNumber'];
  symbol: Scalars['String'];
};

export type AmountLite = {
  __typename?: 'AmountLite';
  value: Scalars['BigNumber'];
  currencyId: Scalars['String'];
  unit: Scalars['String'];
  coin: Scalars['String'];
};

export type AddressValuePointer = {
  __typename?: 'AddressValuePointer';
  value: Scalars['BigNumber'];
  address: AddressPointer;
};

export type AssetStakingPoolDetails = {
  __typename?: 'AssetStakingPoolDetails';
  addressPoolTokens: Scalars['BigNumber'];
  totalPoolTokens: Scalars['BigNumber'];
  percentOfPool: Scalars['BigNumber'];
};

export type BillPayTransactionDetail = {
  __typename?: 'BillPayTransactionDetail';
  billId: Scalars['ID'];
  contactId: Scalars['ID'];
  categoryId: Scalars['ID'];
  description: Scalars['String'];
  costBasis?: Maybe<CostBasis>;
};

export type TransferTransactionDetail = {
  __typename?: 'TransferTransactionDetail';
  notes?: Maybe<Scalars['String']>;
  feeCostBasis?: Maybe<CostBasis>;
  feeContactId?: Maybe<Scalars['String']>;
};

export type ExternalTaxDispositionDetails = {
  __typename?: 'ExternalTaxDispositionDetails';
  dispositionProceedsInBaseCurrency: Scalars['BigNumber'];
  dispositionDate: Scalars['String'];
};

export type ExternalTaxAcquisitionDetails = {
  __typename?: 'ExternalTaxAcquisitionDetails';
  acquisitionCostInBaseCurrency: Scalars['BigNumber'];
  acquisitionDate: Scalars['String'];
};

export type AccountTransferTransactionDetail = {
  __typename?: 'AccountTransferTransactionDetail';
  description: Scalars['String'];
  otherAccountId: Scalars['ID'];
  feeContactId?: Maybe<Scalars['ID']>;
  direction: TransferDirection;
  exchangeRates?: Maybe<Array<Maybe<ExchangeRateDetails>>>;
  dispositionDetails?: Maybe<ExternalTaxDispositionDetails>;
  acquisitionDetails?: Maybe<ExternalTaxAcquisitionDetails>;
};

export type DispositionDetailsInput = {
  dispositionProceedsInBaseCurrency: Scalars['BigNumber'];
  dispositionDate: Scalars['String'];
};

export type AcquisitionDetailsInput = {
  acquisitionCostInBaseCurrency: Scalars['BigNumber'];
  acquisitionDate: Scalars['String'];
};

export type ExternalTaxDetailsInput = {
  dispositionDetails?: InputMaybe<DispositionDetailsInput>;
  acquisitionDetails?: InputMaybe<AcquisitionDetailsInput>;
};

export enum AuthTokenPermissions {
  ReadAll = 'ReadAll',
  WriteAll = 'WriteAll',
}

export type AuthToken = {
  __typename?: 'AuthToken';
  clientId: Scalars['String'];
  description: Scalars['String'];
  permissions: Array<Maybe<AuthTokenPermissions>>;
};

export type AuthTokenCreate = {
  __typename?: 'AuthTokenCreate';
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type DetailedFee = {
  __typename?: 'DetailedFee';
  fee?: Maybe<Amount>;
  cost?: Maybe<CostBasis>;
  feePayeeId?: Maybe<Scalars['String']>;
  walletId?: Maybe<Scalars['String']>;
};

export type TradeTransactionDetail = {
  __typename?: 'TradeTransactionDetail';
  acquiredAssets: Array<Maybe<TradeAsset>>;
  disposedAssets: Array<Maybe<TradeAsset>>;
  exchangeRates?: Maybe<Array<Maybe<ExchangeRateDetails>>>;
  tradeFee?: Maybe<TradeFee>;
  systemCreated?: Maybe<Scalars['Boolean']>;
  carryForwardCostBasis?: Maybe<Scalars['Boolean']>;
};

export type TradeAsset = {
  __typename?: 'TradeAsset';
  asset: Amount;
  fairMarketValue: CostBasis;
};

export type TradeFee = {
  __typename?: 'TradeFee';
  assets: Array<Maybe<TradeAsset>>;
  feeContactId: Scalars['String'];
  walletId: Maybe<Scalars['String']>;
};

export type SimpleTradeTransactionDetail = {
  __typename?: 'SimpleTradeTransactionDetail';
  acquired: Amount;
  disposed: Amount;
  acquiredCost: CostBasis;
  disposedCost: CostBasis;
  fee?: Maybe<DetailedFee>;
  systemCreated?: Maybe<Scalars['Boolean']>;
};

export type DetailedTransactionDetail = {
  __typename?: 'DetailedTransactionDetail';
  notes?: Maybe<Scalars['String']>;
  items: Array<Maybe<DetailedTransactionItem>>;
  costBasis: CostBasis;
};

export type DetailedTransactionItem = {
  __typename?: 'DetailedTransactionItem';
  contactId?: Maybe<Scalars['String']>;
  lines?: Maybe<Array<Maybe<DetailedTransactionLine>>>;
};

export type DetailedTransactionLine = {
  __typename?: 'DetailedTransactionLine';
  categoryId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  amount?: Maybe<SimpleAmountDto>;
};

export type MultiValueTransactionDetail = {
  __typename?: 'MultiValueTransactionDetail';
  notes?: Maybe<Scalars['String']>;
  items: Array<Maybe<MultiValueTransactionItem>>;
  exchangeRates?: Maybe<Array<Maybe<ExchangeRateDetails>>>;
  metadata?: Maybe<Array<Metadata>>;
};

export type MultiValueTransactionItem = {
  __typename?: 'MultiValueTransactionItem';
  contactId: Scalars['String'];
  transactionType: TransactionType;
  lines: Array<Maybe<MultiValueTransactionLine>>;
  metadata?: Maybe<Array<Metadata>>;
};

export type MultiValueTransactionLine = {
  [x: string]: any;
  __typename?: 'MultiValueTransactionLine';
  categoryId: Scalars['String'];
  sourceValue: Value;
  description?: Maybe<Scalars['String']>;
  coin?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  coinAmount?: Maybe<Scalars['String']>;
  fiat: Scalars['String'];
  fiatAmount: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
  txnLineId?: Maybe<Scalars['Int']>;
  walletId?: Maybe<Scalars['String']>;
  forceZeroGainLoss?: Maybe<Scalars['Boolean']>;
};

export type SimpleAmountDto = {
  __typename?: 'SimpleAmountDTO';
  value?: Maybe<Scalars['String']>;
  coin?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type AccountingDetails = {
  __typename?: 'AccountingDetails';
  simple?: Maybe<SimpleTransactionDetail>;
  sell?: Maybe<SellTransactionDetail>;
  billPay?: Maybe<BillPayTransactionDetail>;
  trade?: Maybe<TradeTransactionDetail>;
  simpleTrade?: Maybe<SimpleTradeTransactionDetail>;
  transfer?: Maybe<TransferTransactionDetail>;
  accountTransfer?: Maybe<AccountTransferTransactionDetail>;
  staking?: Maybe<StakingTransactionDetail>;
  assetStaking?: Maybe<AssetStakingTransactionDetail>;
  deFiTransfer?: Maybe<DeFiTransferTransactionDetail>;
  detailed?: Maybe<DetailedTransactionDetail>;
  multivalue?: Maybe<MultiValueTransactionDetail>;
  invoice?: Maybe<InvoiceTransactionDetail>;
  advanceDeFi?: Maybe<AdvanceDeFiTransactionDetail>;
  exchangeRates?: Maybe<Array<Maybe<ExchangeRateDetails>>>;
};

export type ConfirmationStatus = {
  __typename?: 'ConfirmationStatus';
  confirmations?: Maybe<Scalars['Int']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  requiredConfirmations?: Maybe<Scalars['Int']>;
};

export enum TxnType {
  Send = 'Send',
  Receive = 'Receive',
  Trade = 'Trade',
  Transfer = 'Transfer',
  ContractExecution = 'ContractExecution',
  Unknown = 'Unknown',
}

export enum TaxReconciliationStatus {
  Rec = 'Rec',
}

export type TaxInfo = {
  __typename?: 'TaxInfo';
  ticker: Scalars['String'];
  wallets: Array<Maybe<Scalars['String']>>;
  amount: Scalars['BigNumber'];
  cost?: Maybe<Scalars['Float']>;
  saleValue?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['BigNumber']>;
  gainLoss?: Maybe<Scalars['BigNumber']>;
  isGainLossComplete?: Maybe<Scalars['Boolean']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  id?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  editEtag?: Maybe<Scalars['String']>;
  type?: Maybe<TxnType>;
  networkId?: Maybe<Scalars['String']>;
  accountingDetails?: Maybe<Array<Maybe<AccountingDetails>>>;
  categorizationStatus?: Maybe<CategorizationStatus>;
  reconciliationStatus?: Maybe<ReconciliationStatus>;
  categorizationSummary?: Maybe<Scalars['String']>;
  wallet?: Maybe<Array<Maybe<Wallet>>>;
  amounts?: Maybe<Array<Maybe<Amount>>>;
  amountsIncludingFees?: Maybe<Array<Maybe<Amount>>>;
  fullAmountSet?: Maybe<Array<Maybe<Amount>>>;
  fullAmountSetWithoutFees?: Maybe<Array<Maybe<Amount>>>;
  paidFees?: Maybe<Array<Maybe<Amount>>>;
  ignore?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  confirmation?: Maybe<ConfirmationStatus>;
  from?: Maybe<Array<Maybe<TransactionActor>>>;
  to?: Maybe<Array<Maybe<TransactionActor>>>;
  fees?: Maybe<Array<Maybe<Amount>>>;
  details?: Maybe<Array<Maybe<TransactionDetail>>>;
  exchangeRates?: Maybe<Array<Maybe<ExchangeRateDetails>>>;
  taxInfo?: Maybe<TaxInfo>;
  taxReconciliationStatus?: Maybe<ReconciliationStatus>;
  hasMatchedInvoices?: Maybe<Scalars['Boolean']>;
  matchedInvoices?: Maybe<Array<Maybe<Invoice>>>;
  isBlockchain?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  viewLinks?: Maybe<Array<Maybe<TxnViewLink>>>;
  isCombined?: Maybe<Scalars['Boolean']>;
  isCombinedSubTransaction?: Maybe<Scalars['Boolean']>;
  txnLogs?: Maybe<Array<Maybe<TxnLog>>>;
  errored?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  blocks?: Maybe<Array<Maybe<Scalars['String']>>>;
  txnLines?: Maybe<Array<Maybe<TxnLine>>>;
  methods?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountingConnectionId?: Maybe<Scalars['ID']>;
  opsTransaction?: Maybe<Scalars['Boolean']>;
};

export type TransactionView = {
  __typename?: 'TransactionView';
  id: Scalars['String'];
  categorizationStatus: Scalars['String'];
  reconciliationStatus: Scalars['String'];
  timestamp: Scalars['String'];
  txViewLink: Scalars['String'];
  transactionType?: Maybe<TxnType>;
  lines: Array<TransactionLineView>;
  ignored: Scalars['Boolean'];
  state: Scalars['String'];
  metadata: Scalars['String'];
  editEtag: Scalars['String'];
  isCombinedParent: Scalars['Boolean'];
};

export type TransactionLite = {
  __typename?: 'TransactionLite';
  id?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int']>;
  type?: Maybe<TxnType>;
  fullAmountSet?: Maybe<Array<Maybe<AmountLite>>>;
  fullAmountSetWithoutFees?: Maybe<Array<Maybe<AmountLite>>>;
  paidFees?: Maybe<Array<Maybe<AmountLite>>>;
  categorizationStatus?: Maybe<CategorizationStatus>;
  reconciliationStatus?: Maybe<ReconciliationStatus>;
  accountingDetails?: Maybe<Array<Maybe<AccountingDetails>>>;
  categorizationSummary?: Maybe<Scalars['String']>;
  walletAmounts?: Maybe<Array<Maybe<WalletAmounts>>>;
  exchangeRates?: Maybe<ExchangeRateObject>;
  ignored?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  from?: Maybe<Array<Maybe<TransactionActorLite>>>;
  to?: Maybe<Array<Maybe<TransactionActorLite>>>;
  hasMatchedInvoices?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  viewLinks?: Maybe<Array<Maybe<TxnViewLink>>>;
  isCombined?: Maybe<Scalars['Boolean']>;
  isCombinedSubTransaction?: Maybe<Scalars['Boolean']>;
  errored?: Maybe<Scalars['Boolean']>;
  methods?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountingConnectionId?: Maybe<Scalars['ID']>;
  txnLines?: Maybe<Array<TxnLineLite>>;
  metadata?: Maybe<Scalars['JSON']>;
  ticker?: Maybe<Scalars['String']>;
  isManual?: Maybe<Scalars['Boolean']>;
};

export type ExchangeRateObject = {
  __typename?: 'ExchangeRateObject';
  exchangeRates?: Maybe<Array<Maybe<ExchangeRateDetails>>>;
  exchangeRatesDirty?: Maybe<Scalars['Boolean']>;
  exchangeRatesError?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WalletAmounts = {
  __typename?: 'WalletAmounts';
  walletId?: Maybe<Scalars['String']>;
  amounts?: Maybe<Array<Maybe<AmountLite>>>;
};

export enum TxnLineOperation {
  Withdraw = 'WITHDRAW',
  Deposit = 'DEPOSIT',
  Buy = 'BUY',
  Sell = 'SELL',
  Fee = 'FEE',
  Transfer = 'TRANSFER',
}
export enum TxnTypes {
  Bitcoin = 1,
  Ethereum = 10,
  EthereumERC20 = 11,
  EOS = 20,
  ExchangeTransfer = 30,
  ExchangeTrade = 31,
  Manual = 50,
  AccountBasedBlockchainTxn = 100,
  Pointer = 200,
}
export type TxnLine = {
  __typename?: 'TxnLine';
  id: Scalars['String'];
  subId?: Maybe<Scalars['String']>;
  dateTimeSEC: Scalars['Int'];
  operation: TxnLineOperation;
  asset: Scalars['String'];
  amount: Scalars['String'];
  exchangeRate?: Maybe<Scalars['String']>;
  valueInBaseCurrency?: Maybe<Scalars['String']>;
  walletId: Scalars['String'];
  walletName?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  feeAmount?: Maybe<Scalars['String']>;
  feeAsset?: Maybe<Scalars['String']>;
  feeExchangeRate?: Maybe<Scalars['String']>;
  feeValueInBaseCurrency?: Maybe<Scalars['String']>;
  t?: TxnTypes;
};

export type TransactionLineView = {
  __typename?: 'TransactionLineView';
  transactionId: Scalars['String'];
  line: Maybe<Scalars['Int']>;
  operation: TxnLineOperation;
  amount: Scalars['String'];
  amountCurrencyId: Scalars['String'];
  amountCurrencyName: Scalars['String'];
  fairMarketValue: Scalars['String'];
  fairMarketValueCurrencyId: Scalars['String'];
  fairMarketValueCurrencyName: Scalars['String'];
  walletId: Scalars['String'];
  from: Scalars['String'];
  to: Scalars['String'];
  category: Scalars['String'];
  networkId: Scalars['String'];
};

export type TxnLineLite = {
  __typename?: 'TxnLineLite';
  id: Scalars['String'];
  txnLineId?: Maybe<Scalars['Int']>;
  subId?: Maybe<Scalars['String']>;
  dateTimeSEC: Scalars['Int'];
  operation: TxnLineOperation;
  assetId?: Maybe<Scalars['String']>;
  amount: Scalars['String'];
  walletId: Scalars['String'];
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  feeAmount?: Maybe<Scalars['String']>;
  feeAssetId?: Maybe<Scalars['String']>;
};

export type TxnLog = {
  __typename?: 'TxnLog';
  type?: Maybe<Scalars['String']>;
  from?: Maybe<AddressPointer>;
  to?: Maybe<AddressPointer>;
  amount?: Maybe<Scalars['BigNumber']>;
  asset?: Maybe<AssetPointer>;
  displayAmount?: Maybe<Scalars['BigNumber']>;
};

export type AssetPointer = {
  __typename?: 'AssetPointer';
  type?: Maybe<Scalars['String']>;
  networkId?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

export type AddressPointer = {
  __typename?: 'AddressPointer';
  networkId?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type TxnViewLink = {
  __typename?: 'TxnViewLink';
  link: Scalars['String'];
  name: Scalars['String'];
};

export type ExchangeRateDetails = {
  __typename?: 'ExchangeRateDetails';
  coin: Scalars['String'];
  coinUnit: Scalars['String'];
  fiat: Fiat;
  rate: Scalars['BigNumber'];
  failedOrIncomplete?: Maybe<Scalars['Boolean']>;
  priceId?: Maybe<Scalars['String']>;
};

export type TransactionDetail = {
  __typename?: 'TransactionDetail';
  from?: Maybe<Array<Maybe<Scalars['String']>>>;
  to?: Maybe<Array<Maybe<Scalars['String']>>>;
  amounts?: Maybe<Array<Maybe<Amount>>>;
};

export type TransactionActor = {
  __typename?: 'TransactionActor';
  address: Scalars['String'];
  amount?: Maybe<Amount>;
};

export type TransactionActorLite = {
  __typename?: 'TransactionActorLite';
  address: Scalars['String'];
  amount?: Maybe<AmountLite>;
};

export type OrgInvitation = {
  __typename?: 'OrgInvitation';
  id: Scalars['ID'];
  email: Scalars['String'];
  userName: Scalars['String'];
  orgId: Scalars['ID'];
  byUserId: Scalars['ID'];
  orgName?: Maybe<Scalars['String']>;
};

export type SpecificIdentificationSelectionStrategy = {
  __typename?: 'SpecificIdentificationSelectionStrategy';
  spendingStrategy?: Maybe<SpecificIdentificationCoinSpendingStrategy>;
  selectionPreference?: Maybe<SpecificIdentificationSelectionPreference>;
};

export type SpecificIdentificationStrategyRange = {
  __typename?: 'SpecificIdentificationStrategyRange';
  identificationStrategyRanked: Array<Maybe<SpecificIdentificationSelectionStrategy>>;
  beginSEC?: Maybe<Scalars['BigNumber']>;
  endSEC?: Maybe<Scalars['BigNumber']>;
};

export type TaxStartingBalanceExchangeRates = {
  __typename?: 'TaxStartingBalanceExchangeRates';
  ticker: Scalars['String'];
  averageCost: Scalars['String'];
};

export type SpecificIdentification = {
  __typename?: 'SpecificIdentification';
  type: TaxStrategyType;
  grouping: TaxGroupingType;
  excludeCoins?: Maybe<Array<Maybe<Coins>>>;
  taxStartDateSEC?: Maybe<Scalars['BigNumber']>;
  startingBalancesExchangeRates?: Maybe<Array<Maybe<TaxStartingBalanceExchangeRates>>>;
  specificIdentificationStrategyRanges: Array<Maybe<SpecificIdentificationStrategyRange>>;
};

export type Org = {
  __typename?: 'Org';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  wallets?: Maybe<Array<Maybe<Wallet>>>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  users?: Maybe<Array<Maybe<User>>>;
  invites?: Maybe<Array<Maybe<OrgInvitation>>>;
  accountingSetup?: Maybe<AccountingSetup>;
  subscriptionDetails?: Maybe<SubscriptionDetails>;
  provisionedAccountingUsers?: Maybe<Scalars['Int']>;
  provisionedWalletUsers?: Maybe<Scalars['Int']>;
  taxStrategy?: Maybe<SpecificIdentification>;
  taxStrategyType?: Maybe<TaxStrategyType>;
  pricingSettings?: Maybe<PricingSettings>;
  baseCurrency?: Maybe<Fiat>;
  taxConfig?: Maybe<TaxConfig>;
  sso?: Maybe<Sso>;
  userRoles?: Maybe<Array<Maybe<UserRole>>>;
  invoicingSettings?: Maybe<InvoicingSettings>;
  accountingConfig?: Maybe<AccountingConfig>;
  flags?: Maybe<Array<Maybe<FeatureFlag>>>;
  trialExpiry?: Maybe<Scalars['String']>;
  displayConfig?: Maybe<DisplayConfig>;
  tokenFilteringConfig?: Maybe<TokenFilteringRulesConfig>;
  hardCloseDate?: Maybe<Scalars['String']>;
  accountingInventoryGroupIds?: Maybe<Array<Scalars['String']>>;
  engineVersion?: Maybe<Scalars['Float']>;
};

export type AuditLogEntry = {
  __typename?: 'AuditLogEntry';
  id: Scalars['String'];
  orgId: Scalars['String'];
  userId: Scalars['String'];
  action: Scalars['String'];
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  timestamp: Scalars['Int'];
  userEmail: Scalars['String'];
  message: Scalars['String'];
  category: Scalars['String'];
  name: Scalars['String'];
  detail?: Maybe<Scalars['String']>;
};

export type AuditLogType = {
  __typename?: 'AuditLogType';
  category: Scalars['String'];
  name: Scalars['String'];
  entityType: Scalars['String'];
  action: Scalars['String'];
};

export type AuditLogResponse = {
  __typename?: 'AuditLogResponse';
  items: Array<Maybe<AuditLogEntry>>;
  previousPageToken?: Maybe<Scalars['String']>;
  nextPageToken?: Maybe<Scalars['String']>;
};

export type AuditLogFilter = {
  userId?: InputMaybe<Scalars['String']>;
  userEmail?: InputMaybe<Scalars['String']>;
  action?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<Scalars['String']>;
  beforeTimestamp?: InputMaybe<Scalars['Int']>;
  afterTimestamp?: InputMaybe<Scalars['Int']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  message?: InputMaybe<Scalars['String']>;
};

export type BlockEvaluator = {
  __typename?: 'BlockEvaluator';
  blockNumber: Scalars['String'];
  evaluator: Scalars['String'];
};

export type TokenFilteringRulesConfig = {
  __typename?: 'TokenFilteringRulesConfig';
  allowedContractAddresses?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedDeployedByAddresses?: Maybe<Array<Maybe<Scalars['String']>>>;
  disabledDeployedByAddresses?: Maybe<Array<Maybe<Scalars['String']>>>;
  disabledContractAddresses?: Maybe<Array<Maybe<Scalars['String']>>>;
  erc20FilterThreshold?: Maybe<Scalars['String']>;
  filteredAddresses?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserRole = {
  __typename?: 'UserRole';
  userId: Scalars['String'];
  role: Scalars['Int'];
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type PricingSettings = {
  __typename?: 'PricingSettings';
  preferredExchange?: Maybe<Scalars['String']>;
};

export type SubscriptionDetails = {
  __typename?: 'SubscriptionDetails';
  accountingSeats?: Maybe<Scalars['Int']>;
  walletSeats?: Maybe<Scalars['Int']>;
};

export type TaxConfig = {
  __typename?: 'TaxConfig';
  capitalizeTradingFees: Scalars['Boolean'];
  wrappingTreatments?: Maybe<Scalars['JSON']>;
};

export type DisplayConfig = {
  __typename?: 'DisplayConfig';
  useOrgTimezone?: Maybe<Scalars['Boolean']>;
};

export type Test = {
  __typename?: 'Test';
  t?: Maybe<Scalars['String']>;
};

export type Task = {
  __typename?: 'Task';
  id: Scalars['ID'];
  completed?: Maybe<Scalars['Boolean']>;
  orgId?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  walletId?: Maybe<Scalars['String']>;
};

export type Import = {
  __typename?: 'Import';
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  createdMS?: Maybe<Scalars['Float']>;
  fileUploadUrl: Scalars['String'];
  fileUploadExpiresAt: Scalars['Int'];
  previewLines?: Maybe<Array<Maybe<ImportPreviewLineRecord>>>;
  validationErrors?: Maybe<Array<Maybe<ValidationErrors>>>;
  importErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
  progress?: Maybe<Scalars['Float']>;
  createdByUser?: Maybe<UserPointer>;
};

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  index?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ValueType {
  Fiat = 'Fiat',
  Coin = 'Coin',
}

export type Value = {
  __typename?: 'Value';
  type: ValueType;
  amount: Scalars['Float'];
  ticker: Scalars['String'];
  fiat?: Maybe<Fiat>;
  coin?: Maybe<Scalars['String']>;
  coinUnit?: Maybe<Scalars['String']>;
};

export type ImportPreviewLineRecord = {
  __typename?: 'ImportPreviewLineRecord';
  index?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasErrors?: Maybe<Scalars['Boolean']>;
  data?: Maybe<ImportPreviewLine>;
};

export type ImportPreviewLine = {
  __typename?: 'ImportPreviewLine';
  id: Scalars['ID'];
  blockchainId?: Maybe<Scalars['String']>;
  transactionType: Scalars['String'];
  timeISO: Scalars['String'];
  accountId: Scalars['String'];
  amount: Scalars['BigNumber'];
  amountTicker: Scalars['String'];
  cost?: Maybe<Scalars['BigNumber']>;
  costTicker?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['BigNumber']>;
  feeTicker?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  remoteContactId?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
};

export type TransactionFilter = {
  categorizationFilter?: InputMaybe<CategorizationFilter>;
  reconciliationFilter?: InputMaybe<ReconciliationFilter>;
  taxReconciliationFilter?: InputMaybe<ReconciliationFilter>;
  accountingDetails?: InputMaybe<AccountingTransactionFilter>;
  walletFilter?: InputMaybe<Scalars['String']>;
  ignoreFilter?: InputMaybe<IgnoreFilter>;
  beforeDateTime?: InputMaybe<Scalars['Int']>;
  afterDateTime?: InputMaybe<Scalars['Int']>;
  pivotDate?: InputMaybe<Scalars['String']>;
  addresses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchTokens?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  errored?: InputMaybe<Scalars['Boolean']>;
};

export enum AccountingTransactionType {
  AccountTransfer = 'AccountTransfer',
}

export type AccountingTransactionFilter = {
  type?: InputMaybe<AccountingTransactionType>;
  taxable?: InputMaybe<Scalars['Boolean']>;
  taxDetailsMissing?: InputMaybe<Scalars['Boolean']>;
  isTrade?: InputMaybe<Scalars['Boolean']>;
};

export type Pagination = {
  toAfter: Scalars['Boolean'];
  at: Scalars['ID'];
  limit: Scalars['Int'];
};

export type TransactionsResult = {
  __typename?: 'TransactionsResult';
  txns?: Maybe<Array<Maybe<Transaction>>>;
  olderPageToken?: Maybe<Scalars['String']>;
  newerPageToken?: Maybe<Scalars['String']>;
};

export type TransactionCounts = {
  __typename?: 'TransactionCounts';
  all: Scalars['Int'];
  uncategorized: Scalars['Int'];
  unreconciled: Scalars['Int'];
};

export type TransactionsResultLite = {
  __typename?: 'TransactionsResultLite';
  txns: Array<Maybe<TransactionLite>>;
  olderPageToken?: Maybe<Scalars['String']>;
  newerPageToken?: Maybe<Scalars['String']>;
  coins: Array<Maybe<CoinMapping>>;
};

export type TransactionResult = {
  __typename?: 'TransactionResult';
  orgId: Scalars['String'];
  transactions: Array<Maybe<TransactionView>>;
  nextToken?: Maybe<Scalars['String']>;
  prevToken?: Maybe<Scalars['String']>;
};

export type CoinMapping = {
  __typename?: 'CoinMapping';
  currencyId: Scalars['String'];
  ticker: Scalars['String'];
  networkName: Scalars['String'];
  networkId: Scalars['String'];
};

export type Report = {
  __typename?: 'Report';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
};

export type ExchangeRatePointer = {
  __typename?: 'ExchangeRatePointer';
  currencyId: Scalars['String'];
  displayName: Scalars['String'];
  rate: Scalars['BigNumber'];
};

export type TaxStrategySettings = {
  strategy: TaxStrategyType;
};

export enum SpecificIdentificationCoinSpendingStrategy {
  ShortTermGain = 'ShortTermGain',
  ShortTermLoss = 'ShortTermLoss',
  LongTermGain = 'LongTermGain',
  LongTermLoss = 'LongTermLoss',
}

export enum SpecificIdentificationSelectionPreference {
  Minimize = 'Minimize',
  Maximize = 'Maximize',
}

export type SpecificIdentificationStrategyInput = {
  spendingStrategy?: InputMaybe<SpecificIdentificationCoinSpendingStrategy>;
  selectionPreference?: InputMaybe<SpecificIdentificationSelectionPreference>;
};

export type TaxConfigInput = {
  capitalizeFees: Scalars['Boolean'];
  wrappingTreatments?: InputMaybe<Scalars['JSON']>;
};

export type DisplayConfigInput = {
  useOrgTimezone?: InputMaybe<Scalars['Boolean']>;
};

export type ExchangeRateItem = {
  __typename?: 'ExchangeRateItem';
  ticker: Scalars['String'];
  rate: Scalars['Float'];
};

export type TaxScenarioResult = {
  __typename?: 'TaxScenarioResult';
  exchangeRatesToBaseCurrency?: Maybe<Array<Maybe<ExchangeRateItem>>>;
  exchangeRatePointers?: Maybe<Array<Maybe<ExchangeRatePointer>>>;
  bucketResults: Array<Maybe<TaxBucketResult>>;
  lines: Array<Maybe<TaxLine>>;
  summaryLines: Array<Maybe<TaxLine>>;
  matchSuccessful: Scalars['Boolean'];
  totalRealizedShortTermGainLoss?: Maybe<Scalars['Float']>;
  totalRealizedLongTermGainLoss?: Maybe<Scalars['Float']>;
  totalRealizedUndatedGainLoss?: Maybe<Scalars['Float']>;
  totalUnrealizedGainLoss?: Maybe<Scalars['Float']>;
  totalExternalUnrealizedGainLoss?: Maybe<Scalars['Float']>;
  totalImpairmentExpense?: Maybe<Scalars['Float']>;
  totalAdjustments?: Maybe<Scalars['Float']>;
  periodStartSEC?: Maybe<Scalars['Int']>;
  periodEndSEC?: Maybe<Scalars['Int']>;
  largeFileDownloadUrl?: Maybe<Scalars['String']>;
  actionsDownloadUrl?: Maybe<Scalars['String']>;
};

export type TaxBucketResult = {
  __typename?: 'TaxBucketResult';
  ticker: Scalars['String'];
  realizedShortTermGainLoss?: Maybe<Scalars['BigNumber']>;
  realizedLongTermGainLoss?: Maybe<Scalars['BigNumber']>;
  realizedUndatedGainLoss?: Maybe<Scalars['BigNumber']>;
  unrealizedGainLoss?: Maybe<Scalars['BigNumber']>;
  unspentAmount: Value;
  unspentCostBasis: Scalars['BigNumber'];
  matchedSuccessfully: Scalars['Boolean'];
  adjustments?: Maybe<Scalars['BigNumber']>;
  impairmentExpense?: Maybe<Scalars['BigNumber']>;
  carryingValue?: Maybe<Scalars['BigNumber']>;
  carryingExchangeRate?: Maybe<Scalars['BigNumber']>;
};

export type TaxLine = {
  __typename?: 'TaxLine';
  taxEventId: Scalars['String'];
  timeSEC: Scalars['Int'];
  value: Value;
  lineType: TaxLineType;
  matchSuccessful: Scalars['Boolean'];
  cost?: Maybe<Scalars['Float']>;
  cumulativeCost?: Maybe<Scalars['Float']>;
  impairedCumulativeCost?: Maybe<Scalars['Float']>;
  costRelieved?: Maybe<Scalars['Float']>;
  proceeds?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  realizedGainLoss?: Maybe<TaxLineGainLoss>;
  unrealizedGainLoss?: Maybe<Scalars['Float']>;
  externalUnrealizedGainLoss?: Maybe<Scalars['Float']>;
  runningBalance?: Maybe<Value>;
  runningGainLoss?: Maybe<TaxLineGainLoss>;
  runningUnrealizedGainLoss?: Maybe<Scalars['Float']>;
  runningExternalUnrealizedGainLoss?: Maybe<Scalars['Float']>;
  matchErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
  matches?: Maybe<Array<Maybe<TaxMatch>>>;
  adjustments?: Maybe<Scalars['Float']>;
  impairmentExpense?: Maybe<Scalars['Float']>;
  carryingValue?: Maybe<Scalars['Float']>;
  carryingExchangeRate?: Maybe<Scalars['Float']>;
};

export enum TaxLineType {
  Acquired = 'Acquired',
  Disposed = 'Disposed',
  TrackedTransfer = 'TrackedTransfer',
  ExternalDisposition = 'ExternalDisposition',
}

export type TaxMatch = {
  __typename?: 'TaxMatch';
  acquiredTaxEventId: Scalars['String'];
  disposedTaxEventId: Scalars['String'];
  matchedAmount: Value;
  shortTermGainLossAmount: Scalars['BigNumber'];
  longTermGainLossAmount: Scalars['BigNumber'];
  costRelieved: Scalars['BigNumber'];
  acquiredDateTimeSEC: Scalars['Int'];
  disposedDateTimeSEC: Scalars['Int'];
};

export type TaxLineGainLoss = {
  __typename?: 'TaxLineGainLoss';
  shortTermGainLoss?: Maybe<Scalars['BigNumber']>;
  longTermGainLoss?: Maybe<Scalars['BigNumber']>;
  undatedGainLoss?: Maybe<Scalars['BigNumber']>;
};

export type Bill = {
  __typename?: 'Bill';
  id: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Fiat>;
  displayAmount?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  vendor?: Maybe<Contact>;
  category?: Maybe<Category>;
  accountingConnectionId?: Maybe<Scalars['String']>;
};

export type FeeEstimate = {
  __typename?: 'FeeEstimate';
  coin: Coins;
  feeUnit?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type Root = {
  __typename?: 'Root';
  id: Scalars['ID'];
  path: Scalars['String'];
  make: HardwareDevices;
  name: Scalars['String'];
  assetTag?: Maybe<Scalars['String']>;
};

export type Exchange = {
  __typename?: 'Exchange';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FiatType = {
  __typename?: 'FiatType';
  id: Scalars['String'];
  name: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
};

export enum BulkPaymentStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Paid = 'Paid',
}

export type BulkPaymentRecord = {
  __typename?: 'BulkPaymentRecord';
  id: Scalars['String'];
  created: Scalars['Int'];
  createdBy: Scalars['String'];
  status?: Maybe<BulkPaymentStatus>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  bulkPayment: BulkPayment;
};

export type BulkPayment = {
  __typename?: 'BulkPayment';
  name: Scalars['String'];
  coin: Coins;
  total: Scalars['BigNumber'];
  payments?: Maybe<Array<Maybe<BulkPaymentLine>>>;
  metaErrors: Array<Maybe<Scalars['String']>>;
  hasErrors: Scalars['Boolean'];
};

export type BulkPaymentLine = {
  __typename?: 'BulkPaymentLine';
  address: Scalars['String'];
  amount: Scalars['BigNumber'];
  memo: Scalars['String'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WalletPermission = {
  __typename?: 'WalletPermission';
  userId: Scalars['ID'];
  roles?: Maybe<Scalars['Int']>;
};

export type AddResponse = {
  __typename?: 'AddResponse';
  error?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type WalletTransaction = {
  __typename?: 'WalletTransaction';
  id: Scalars['ID'];
  bitcoinTxn?: Maybe<BitcoinTransaction>;
  accountingDetails?: Maybe<Array<Maybe<AccountingDetails>>>;
};

export type BitcoinTransaction = {
  __typename?: 'BitcoinTransaction';
  outputScript?: Maybe<Scalars['String']>;
  outputNumber?: Maybe<Scalars['Int']>;
  inputs?: Maybe<Array<Maybe<BitcoinTxnInput>>>;
  txn?: Maybe<Scalars['String']>;
  txnObj?: Maybe<Scalars['String']>;
};

export type BitcoinTxnInput = {
  __typename?: 'BitcoinTxnInput';
  txnId?: Maybe<Scalars['String']>;
  txnHash?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  redeemScript?: Maybe<Scalars['String']>;
  cosignerInfo?: Maybe<CosignerInfo>;
  value?: Maybe<Scalars['BigNumber']>;
  txn?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type CosignerInfo = {
  __typename?: 'CosignerInfo';
  cosignerIndex?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
};

export type Result = {
  __typename?: 'Result';
  succeeded?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserInput = {
  emailContactPreferences?: InputMaybe<Array<InputMaybe<EmailContactPreference>>>;
  displayName?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  tosAcceptance?: InputMaybe<TosAcceptanceInput>;
};

export type TosAcceptanceInput = {
  acceptedOn?: InputMaybe<Scalars['Int']>;
  tosVersion?: InputMaybe<Scalars['String']>;
};

export type OrgUserInput = {
  assertedIdentity?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['ID']>;
  userID?: InputMaybe<Scalars['ID']>;
  role?: InputMaybe<Scalars['Int']>;
  walletIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum TransactionType {
  Expense = 'Expense',
  Income = 'Income',
}

export enum StakingTxnType {
  Staking = 'Staking',
  Unstaking = 'Unstaking',
  Balancing = 'Balancing',
}

export enum AssetStakingTxnType {
  LiquidityPoolAdd = 'LiquidityPoolAdd',
  LiquidityPoolRemove = 'LiquidityPoolRemove',
  Stake = 'Stake',
  Unstake = 'Unstake',
  Balance = 'Balance',
  VaultDeposit = 'VaultDeposit',
  VaultWithdraw = 'VaultWithdraw',
  Repayment = 'Repayment',
  Borrow = 'Borrow',
}

export enum TransferDirection {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing',
}

export enum InitialSyncMethods {
  FullReconcile = 'FullReconcile',
  BalanceExport = 'BalanceExport',
}

export type AccountingSetupInput = {
  initialSyncMethod: InitialSyncMethods;
  isFinished: Scalars['Boolean'];
  isReconciled?: InputMaybe<Scalars['Boolean']>;
  balanceExportStartDate?: InputMaybe<Scalars['String']>;
  balanceExportReportId?: InputMaybe<Scalars['String']>;
};

export type UserRoleInput = {
  userId: Scalars['String'];
  role?: InputMaybe<Scalars['Int']>;
};

export type ReportPointer = {
  __typename?: 'ReportPointer';
  reportId: Scalars['ID'];
  orgId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
};

export type CreateConnectionApiSecret = {
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  exchangeContactId: Scalars['String'];
};

export type CreateConnectionPrivateKey = {
  apiKey: Scalars['String'];
  privateKey: Scalars['String'];
  exchangeContactId: Scalars['String'];
};

export type CreateCoinbaseProConnection = {
  name?: InputMaybe<Scalars['String']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  apiPassphrase: Scalars['String'];
  exchangeContactId: Scalars['String'];
  isPrime?: InputMaybe<Scalars['Boolean']>;
};

export type CreateCoinbasePrimeConnection = {
  name?: InputMaybe<Scalars['String']>;
  apiKey: Scalars['String'];
  passphrase: Scalars['String'];
  signingKey: Scalars['String'];
  exchangeContactId: Scalars['String'];
};

export type CreateXeroConnection = {
  consumerKey: Scalars['String'];
  consumerSecret: Scalars['String'];
  accountCode: Scalars['String'];
  feeAccountCode: Scalars['String'];
  exchangeContactId: Scalars['String'];
};

export type CreateNetSuite = {
  subsidiary?: InputMaybe<Scalars['String']>;
  assetAccountCode: Scalars['String'];
  feeAccountCode: Scalars['String'];
  account: Scalars['String'];
  consumerKey: Scalars['String'];
  consumerSecret: Scalars['String'];
  tokenId: Scalars['String'];
  tokenSecret: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  accountMappingConfig?: InputMaybe<Scalars['JSON']>;
};

export type CreateSageIntacct = {
  companyId: Scalars['String'];
  entityId: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
  userPassword: Scalars['String'];
  assetAccountCode: Scalars['String'];
  sageDigitalAssetAccountCode: InputMaybe<Scalars['String']>;
  feeAccountCode: Scalars['String'];
  baseCurrency?: InputMaybe<Scalars['String']>;
};

export type ConnectionFlags = {
  inferVaultTradeMovements?: InputMaybe<Scalars['Boolean']>;
};

export type CreateConnectionInput = {
  createXero?: InputMaybe<CreateXeroConnection>;
  createBinance?: InputMaybe<CreateBinance>;
  createBitfinex?: InputMaybe<CreateConnectionApiSecret>;
  createCoinbasePro?: InputMaybe<CreateCoinbaseProConnection>;
  createKraken?: InputMaybe<CreateConnectionPrivateKey>;
  createItBit?: InputMaybe<CreateItBit>;
  createPaxos?: InputMaybe<CreatePaxos>;
  createBitstamp?: InputMaybe<CreateBitstamp>;
  createGemini?: InputMaybe<CreateConnectionApiSecret>;
  createBitGo?: InputMaybe<CreateBitGo>;
  createNyDig?: InputMaybe<CreateNyDig>;
  createBittrex?: InputMaybe<CreateBittrex>;
  createFtx?: InputMaybe<CreateFtx>;
  createPrimeTrust?: InputMaybe<CreatePrimeTrust>;
  createNetSuite?: InputMaybe<CreateNetSuite>;
  createCoinbaseCustody?: InputMaybe<CreateCoinbaseCustody>;
  createSageIntacct?: InputMaybe<CreateSageIntacct>;
  createSFOX?: InputMaybe<CreateSfox>;
  createAnchorage?: InputMaybe<CreateAnchorage>;
  createCoinbaseCommerce?: InputMaybe<CreateConnectionApiSecret>;
  createCoinbasePrime?: InputMaybe<CreateCoinbasePrimeConnection>;
  createFireblocks?: InputMaybe<CreateConnectionPrivateKey>;
  createCoinbaseRetail?: InputMaybe<CreateConnectionApiSecret>;
};

export enum Location {
  Standard = 'standard',
  Us = 'us',
}

export type CreateNyDig = {
  name?: InputMaybe<Scalars['String']>;
  exchangeContactId: Scalars['String'];
  s3Bucket: Scalars['String'];
  s3Prefix: Scalars['String'];
};

export type CreateBinance = {
  name?: InputMaybe<Scalars['String']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  exchangeContactId: Scalars['String'];
  location: Location;
};

export type CreateBittrex = {
  name?: InputMaybe<Scalars['String']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  exchangeContactId: Scalars['String'];
};

export type CreateFtx = {
  name?: InputMaybe<Scalars['String']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  location: Location;
  syncSubAccounts: Scalars['Boolean'];
  exchangeContactId: Scalars['String'];
};

export type CreatePrimeTrust = {
  name?: InputMaybe<Scalars['String']>;
  useJwt?: InputMaybe<Scalars['Boolean']>;
  jwt?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  accountId: Scalars['String'];
  exchangeContactId: Scalars['String'];
};

export type CreateSfox = {
  name?: InputMaybe<Scalars['String']>;
  apiKey: Scalars['String'];
  exchangeContactId: Scalars['String'];
};

export type CreateAnchorage = {
  name?: InputMaybe<Scalars['String']>;
  apiKey: Scalars['String'];
  exchangeContactId: Scalars['String'];
  flags?: InputMaybe<ConnectionFlags>;
};

export type CreateCoinbaseCustody = {
  name?: InputMaybe<Scalars['String']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  exchangeContactId: Scalars['String'];
};

export type CreatePaxos = {
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  scope: Scalars['String'];
  itBitUserId?: InputMaybe<Scalars['String']>;
  itBitKey?: InputMaybe<Scalars['String']>;
  itBitSecret?: InputMaybe<Scalars['String']>;
  exchangeContactId?: InputMaybe<Scalars['String']>;
};

export type CreateItBit = {
  userId: Scalars['String'];
  key: Scalars['String'];
  secret: Scalars['String'];
  exchangeContactId: Scalars['String'];
};

export type CreateBitstamp = {
  apiKey: Scalars['String'];
  secret: Scalars['String'];
  exchangeContactId: Scalars['String'];
};

export type CreateBitGo = {
  token: Scalars['String'];
  exchangeContactId: Scalars['String'];
};

export type ReconciliationResult = {
  __typename?: 'ReconciliationResult';
  txnId?: Maybe<Scalars['String']>;
  succeeded: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootInput = {
  hd: Scalars['String'];
  path: Scalars['String'];
  make: HardwareDevices;
  name: Scalars['String'];
  assetTag?: InputMaybe<Scalars['String']>;
};

export type BillInput = {
  id?: InputMaybe<Scalars['ID']>;
  dueDate?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  currency?: InputMaybe<Fiat>;
  coin?: InputMaybe<Coins>;
  walletId?: InputMaybe<Scalars['ID']>;
  accountingConnectionId?: InputMaybe<Scalars['ID']>;
};

export type AddressSpend = {
  address: Scalars['String'];
  amount: Scalars['BigNumber'];
  coin: Coins;
  unit: Units;
};

export type CreateTransactionInput = {
  addressSpends?: InputMaybe<Array<InputMaybe<AddressSpend>>>;
  transactionData?: InputMaybe<TransactionData>;
  fee?: InputMaybe<Scalars['BigNumber']>;
};

export type SignatureInput = {
  bitcoinSignatures?: InputMaybe<Array<InputMaybe<BitcoinSignatureInput>>>;
};

export type BitcoinSignatureInput = {
  inputIndex: Scalars['Int'];
  signature: Scalars['String'];
};

export type InviteInput = {
  email: Scalars['String'];
  userName: Scalars['String'];
  role?: Scalars['Int'];
};

export type ReportDetailsInput = {
  balanceReport?: InputMaybe<BalanceReportInput>;
  advancedBalanceReport?: InputMaybe<AdvancedBalanceReportInput>;
  gainLossReport?: InputMaybe<GainLossReportInput>;
  deFiRoiReport?: InputMaybe<DeFiRoiReportInput>;
  walletId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  saveReport: Scalars['Boolean'];
};

export type DeFiRoiReportInput = {
  overrideExchangeRates?: InputMaybe<Array<InputMaybe<ExchangeRateOverride>>>;
};

export type GainLossReportInput = {
  asOfDate?: InputMaybe<Scalars['String']>;
  taxStrategy?: InputMaybe<Scalars['String']>;
};

export type AdvancedBalanceReportInput = {
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  periodGrouping: ReportPeriodGrouping;
  reportGrouping?: InputMaybe<ReportGrouping>;
};

export enum ReportGrouping {
  None = 'none',
  Wallet = 'wallet',
}

export enum ReportPeriodGrouping {
  None = 'none',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
}

export type BalanceReportInput = {
  balanceOnDate: Scalars['String'];
  currency: Fiat;
  groupBy?: InputMaybe<Scalars['String']>;
  includeIgnored?: InputMaybe<Scalars['Boolean']>;
  overrideExchangeRates?: InputMaybe<Array<InputMaybe<ExchangeRateOverride>>>;
  deFiTreatment?: InputMaybe<BalanceReportDeFiTreatment>;
  emailReport?: InputMaybe<Scalars['String']>;
  reCheckDeFi?: InputMaybe<Scalars['Boolean']>;
  excludeNft?: InputMaybe<Scalars['Boolean']>;
  skipPricing?: InputMaybe<Scalars['Boolean']>;
  skipPricingSpam?: InputMaybe<Scalars['Boolean']>;
  exportTokenAddresses?: InputMaybe<Scalars['Boolean']>;
};

export enum BalanceReportDeFiTreatment {
  Exclude = 'exclude',
  Explicit = 'explicit',
  Infer = 'infer',
  Advanced = 'advanced',
}

export type ExchangeRateOverride = {
  currencyId: Scalars['String'];
  rate: Scalars['String'];
};

export enum TaxStrategyType {
  Fifo = 'FIFO',
  Lifo = 'LIFO',
  CostAverage = 'CostAverage',
  SpecificIdentification = 'SpecificIdentification',
}

export enum TaxGroupingType {
  GroupByWallet = 'GroupByWallet',
  GroupByOrg = 'GroupByOrg',
}

export type CostAverageOverride = {
  ticker: Scalars['String'];
  value: Scalars['Float'];
};

export type TaxSetupInput = {
  taxStrategy: TaxStrategyType;
  costAverageOverrides?: InputMaybe<Array<InputMaybe<CostAverageOverride>>>;
  costAverageOverrideEndDate?: InputMaybe<Scalars['String']>;
  taxStartDate?: InputMaybe<Scalars['String']>;
  startingBalanceDetails?: InputMaybe<Array<InputMaybe<StartingBalanceDetail>>>;
  defaultToZeroCostBasis?: InputMaybe<Scalars['Boolean']>;
  excludedCoins?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type StartingBalanceDetail = {
  ticker: Scalars['String'];
  averageCost: Scalars['String'];
};

export type OpResult = {
  __typename?: 'OpResult';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
};

export type SubscriptionDetailsInput = {
  accountingSeats: Scalars['Int'];
  walletSeats: Scalars['Int'];
};

export type SubscriptionResponse = {
  __typename?: 'SubscriptionResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type CreateCategoryInput = {
  connectionId: Scalars['String'];
  remoteId: Scalars['String'];
  name: Scalars['String'];
  type: CategoryType;
  description?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
};

export type SsoInput = {
  use: Scalars['Boolean'];
  requireSso?: InputMaybe<Scalars['Boolean']>;
  idpSignOnUrl?: InputMaybe<Scalars['String']>;
  idpIssuer?: InputMaybe<Scalars['String']>;
  idpCertificate?: InputMaybe<Scalars['String']>;
};

export type Sso = {
  __typename?: 'SSO';
  use: Scalars['Boolean'];
  requireSso?: Maybe<Scalars['Boolean']>;
  idpSignOnUrl: Scalars['String'];
  idpIssuer: Scalars['String'];
  idpCertificate: Scalars['String'];
};

export type InvoicingSettingsInput = {
  fromEmail: Scalars['String'];
  fromName?: InputMaybe<Scalars['String']>;
  bitcoinAddress?: InputMaybe<Scalars['String']>;
  bitcoinXPub?: InputMaybe<Scalars['String']>;
  bitcoinXPubPath?: InputMaybe<Scalars['String']>;
  ethAddress?: InputMaybe<Scalars['String']>;
  zecAddress?: InputMaybe<Scalars['String']>;
  usdcAddress?: InputMaybe<Scalars['String']>;
  usdtAddress?: InputMaybe<Scalars['String']>;
  avaxAddress?: InputMaybe<Scalars['String']>;
  alotAddress?: InputMaybe<Scalars['String']>;
};

export type InvoicingSettings = {
  __typename?: 'InvoicingSettings';
  fromEmail?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  bitcoinAddress?: Maybe<Scalars['String']>;
  bitcoinXPub?: Maybe<Scalars['String']>;
  bitcoinXPubPath?: Maybe<Scalars['String']>;
  ethAddress?: Maybe<Scalars['String']>;
  zecAddress?: Maybe<Scalars['String']>;
  usdcAddress?: Maybe<Scalars['String']>;
  usdtAddress?: Maybe<Scalars['String']>;
};

export type NetworkContactInput = {
  blockchain: Scalars['String'];
  contactId: Scalars['String'];
};
export type NetworkFeeCategoryInput = {
  blockchain: Scalars['String'];
  categoryId: Scalars['String'];
};

export type NetworkContact = {
  __typename?: 'NetworkContact';
  eth?: Maybe<Scalars['String']>;
};

export type SaveRole = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  description: Scalars['String'];
  scopes: Array<InputMaybe<Scalars['String']>>;
};

export enum SystemJobExecType {
  SysAdminOrgJobs = 'sysAdminOrgJobs',
}

export type SystemJobStep = {
  __typename?: 'SystemJobStep';
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<SystemJobStatus>;
  description?: Maybe<Scalars['String']>;
  startedSEC?: Maybe<Scalars['Int']>;
  completedSEC?: Maybe<Scalars['Int']>;
  successMessage?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SystemJobProgress = {
  __typename?: 'SystemJobProgress';
  numerator?: Maybe<Scalars['Int']>;
  denominator?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['String']>;
};

export type SystemJobParams = {
  __typename?: 'SystemJobParams';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SystemJob = {
  __typename?: 'SystemJob';
  type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  execType?: Maybe<SystemJobExecType>;
  createdByUser?: Maybe<UserPointer>;
  cancelledByUser?: Maybe<UserPointer>;
  createdSEC?: Maybe<Scalars['Int']>;
  statusUpdatedSEC?: Maybe<Scalars['Int']>;
  startedSEC?: Maybe<Scalars['Int']>;
  completedSEC?: Maybe<Scalars['Int']>;
  workflowId?: Maybe<Scalars['String']>;
  canCancel?: Maybe<Scalars['Boolean']>;
  status?: Maybe<SystemJobStatus>;
  steps?: Maybe<SystemJobStep>;
  progress?: Maybe<SystemJobProgress>;
  name?: Maybe<Scalars['String']>;
  params?: Maybe<Array<Maybe<SystemJobParams>>>;
};

export type SystemJobsResponse = {
  __typename?: 'SystemJobsResponse';
  nextPageToken?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<SystemJob>>>;
};

export type ValidateWalletRequest = {
  signature?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
};

export type BlockEvaluatorInput = {
  blockNumber: Scalars['String'];
  evaluator: Scalars['String'];
};

export type TokenFilterRule = {
  allowedContractAddresses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  allowedDeployedByAddresses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  disabledDeployedByAddresses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  disabledContractAddresses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  erc20FilterThreshold?: InputMaybe<Scalars['String']>;
  filteredAddresses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Rule = {
  transfer?: InputMaybe<TransferRuleInput>;
};

export type TransferRule = {
  __typename?: 'TransferRule';
  id: Scalars['String'];
  priority: Scalars['Int'];
  action: Action;
  name: Scalars['String'];
  disabled: Scalars['Boolean'];
  coin?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fromAddress?: Maybe<Scalars['String']>;
  toAddress?: Maybe<Scalars['String']>;
  valueRules?: Maybe<Array<RuleComparison>>;
  beforeDateSEC?: Maybe<Scalars['Int']>;
  afterDateSEC?: Maybe<Scalars['Int']>;
  walletId?: Maybe<Scalars['String']>;
  direction: TransactionDirection;
  autoReconcile?: Maybe<Scalars['Boolean']>;
  collapseValues?: Maybe<Scalars['Boolean']>;
  autoCategorizeFee?: Maybe<Scalars['Boolean']>;
  multiToken?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
  accountingConnectionId?: Maybe<Scalars['String']>;
  includesCurrency?: Maybe<Array<Scalars['String']>>;
  metadataRule?: Maybe<MetadataComparison>;
};

export type MetadataComparison = {
  operator: MetadataOperator;
  metadata: Array<MetadataPair>;
  txnRecordRule: Maybe<Scalars['Boolean']>;
};

export enum MetadataOperator {
  AND = 'AND',
  NAND = 'NAND',
  OR = 'OR',
  NOR = 'NOR',
  XOR = 'XOR',
}

export type MetadataPair = {
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type IgnoreAction = {
  __typename?: 'IgnoreAction';
  type: ActionType;
};

export type SimpleCategorizationAction = {
  __typename?: 'SimpleCategorizationAction';
  type: ActionType;
  categoryId: Scalars['String'];
  contactId: Scalars['String'];
  feeCategoryId?: Maybe<Scalars['String']>;
  feeContactId?: Maybe<Scalars['String']>;
  ignoreFailPricing?: Maybe<Scalars['Boolean']>;
};

export type DetailedCategorizationAction = {
  __typename?: 'DetailedCategorizationAction';
  type: ActionType;
  lines: Array<DetailedCategorizationActionLine>;
  ignoreFailPricing?: Maybe<Scalars['Boolean']>;
};

export type TradeCategorizationAction = {
  __typename?: 'TradeCategorizationAction';
  type: ActionType;
  tradeFeeContactId: Scalars['String'];
  ignoreFailPricing?: Maybe<Scalars['Boolean']>;
};

export type DeFiCategorizationAction = {
  __typename?: 'DeFiCategorizationAction';
  type: ActionType;
  deFiFeeContactId: Scalars['String'];
  deFiWalletId: Scalars['String'];
};

export type SimpleSplitCategorizationAction = {
  __typename?: 'SimpleSplitCategorizationAction';
  type: ActionType;
  splits: Array<Split>;
  feeSplits: Array<Split>;
};

export type InternalTransferCategorizationAction = {
  __typename?: 'InternalTransferCategorizationAction';
  type: ActionType;
  feeContactId: Scalars['String'];
  internalFeeContactId: Scalars['String'];
  ignoreFailPricing?: Maybe<Scalars['Boolean']>;
};

export type Action =
  | IgnoreAction
  | SimpleCategorizationAction
  | DetailedCategorizationAction
  | TradeCategorizationAction
  | SimpleSplitCategorizationAction
  | InternalTransferCategorizationAction
  | DeFiCategorizationAction;

export type ActionInput = {
  type: ActionType;
  categoryId?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  feeContactId?: InputMaybe<Scalars['String']>;
  feeCategoryId?: InputMaybe<Scalars['String']>;
  lines?: InputMaybe<Array<DetailedCategorizationActionLineInput>>;
  percentageSplits?: InputMaybe<Array<PercentageSplitCategorizationInput>>;
  feePercentageSplits?: InputMaybe<Array<PercentageSplitCategorizationInput>>;
  deFiWalletId?: InputMaybe<Scalars['String']>;
  ignoreFailPricing?: InputMaybe<Scalars['Boolean']>;
};

export enum ValueExtractor {
  Royalty = 'royalty',
  Commission = 'commission',
  Amount = 'amount',
  Fee = 'fee',
  Revenue = 'revenue',
  LrcFee = 'lrcFee',
  TotalFee = 'totalFee',
}

export enum AssetExtractor {
  Eth = 'ETH',
  Token = 'TOKEN',
  Coin = 'COIN',
  Fiat = 'FIAT',
}

export type DetailedCategorizationActionLineInput = {
  valueExtractor: ValueExtractor;
  assetExtractor: AssetExtractor;
  lineQualifierExtractor?: InputMaybe<Scalars['String']>;
  contactId: Scalars['String'];
  categoryId: Scalars['String'];
  metadataIds?: InputMaybe<Array<Scalars['String']>>;
};

export type PercentageSplitCategorizationInput = {
  percentage: Scalars['Int'];
  contactId: Scalars['String'];
  categoryId: Scalars['String'];
  metadataIds?: InputMaybe<Array<Scalars['String']>>;
};

export type DetailedCategorizationActionLine = {
  __typename?: 'DetailedCategorizationActionLine';
  valueExtractor: ValueExtractor;
  assetExtractor: AssetExtractor;
  lineQualifierExtractor?: Maybe<Scalars['String']>;
  contactId: Scalars['String'];
  categoryId: Scalars['String'];
  metadataIds?: Maybe<Array<Scalars['String']>>;
};

export type Split = PercentageSplit;

export type PercentageSplit = {
  __typename?: 'PercentageSplit';
  type: Scalars['String'];
  percentage: Scalars['Int'];
  contactId: Scalars['String'];
  categoryId: Scalars['String'];
  metadataIds?: Maybe<Array<Scalars['String']>>;
};

export type RulesPageResult = {
  __typename?: 'RulesPageResult';
  items?: Maybe<Array<Maybe<TransferRule>>>;
  nextPageToken?: Maybe<Scalars['String']>;
};

export type TransferRuleInput = {
  id?: InputMaybe<Scalars['String']>;
  priority: Scalars['Int'];
  action: ActionInput;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  disabled: Scalars['Boolean'];
  coin?: InputMaybe<Scalars['String']>;
  fromAddress?: InputMaybe<Scalars['String']>;
  toAddress?: InputMaybe<Scalars['String']>;
  valueRules?: InputMaybe<Array<RuleComparisonInput>>;
  beforeDateSEC?: InputMaybe<Scalars['Int']>;
  afterDateSEC?: InputMaybe<Scalars['Int']>;
  walletId?: InputMaybe<Scalars['String']>;
  direction: TransactionDirection;
  autoReconcile?: InputMaybe<Scalars['Boolean']>;
  collapseValues?: InputMaybe<Scalars['Boolean']>;
  autoCategorizeFee?: InputMaybe<Scalars['Boolean']>;
  multiToken?: InputMaybe<Scalars['Boolean']>;
  accountingConnectionId?: InputMaybe<Scalars['String']>;
  methodId?: InputMaybe<Scalars['String']>;
  includesCurrency?: InputMaybe<Array<Scalars['String']>>;
  metadataRule?: InputMaybe<MetadataComparison>;
};

export type RuleComparison = {
  __typename?: 'RuleComparison';
  comparison?: Maybe<Comparison>;
  value?: Maybe<Scalars['BigNumber']>;
};

export type RuleComparisonInput = {
  comparison?: InputMaybe<Comparison>;
  value?: InputMaybe<Scalars['BigNumber']>;
};

export enum Comparison {
  Eq = 'EQ',
  Lt = 'LT',
  Lte = 'LTE',
  Gt = 'GT',
  Gte = 'GTE',
}

export enum TransactionDirection {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  All = 'All',
  Na = 'NA',
  Empty = 'Empty',
}

export enum ActionType {
  Ignore = 'Ignore',
  SimpleCategorization = 'SimpleCategorization',
  DetailedCategorization = 'DetailedCategorization',
  TradeCategorization = 'TradeCategorization',
  SimpleSplitCategorization = 'SimpleSplitCategorization',
  InternalTransferCategorization = 'InternalTransferCategorization',
  DeFiCategorization = 'DeFiCategorization',
}

export type CreateTransactionResponse = {
  __typename?: 'CreateTransactionResponse';
  id: Scalars['String'];
};

export type TxnUpdateTransaction = {
  ManualTransaction?: InputMaybe<ManualTransaction>;
};
export type TxnCreateTransaction = {
  ManualTransaction?: InputMaybe<ManualTransaction>;
  TransferTransaction?: InputMaybe<TransferTransaction>;
  TradeTransaction?: InputMaybe<TradeTransaction>;
  BlockchainTransaction?: InputMaybe<BlockchainTransaction>;
};

export type BlockchainTransaction = {
  type: TransactionTypes;
  networkId: Scalars['String'];
  txnId: Scalars['String'];
  walletId: Scalars['String'];
  connectionId?: InputMaybe<Scalars['String']>;
  transfers: Array<InputMaybe<BlockchainMovement>>;
  subTransfers: Array<InputMaybe<BlockchainMovement>>;
  fees: Array<InputMaybe<TxnAmount>>;
  createdSEC: Scalars['Int'];
  isComplete: Scalars['Boolean'];
};

export type ManualTransaction = {
  type: TransactionTypes;
  remoteSystemId?: InputMaybe<RemoteSystemId>;
  transactionId?: InputMaybe<TransactionId>;
  connectionId?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Array<InputMaybe<TransactionMovementDetails>>>;
  to?: InputMaybe<Array<InputMaybe<TransactionMovementDetails>>>;
  walletId: Scalars['String'];
  walletName: Scalars['String'];
  source?: InputMaybe<Sources>;
  transactionType?: InputMaybe<ManualTxnTypes>;
  amounts?: InputMaybe<Array<InputMaybe<TxnAmount>>>;
  createdSEC: Scalars['Int'];
  isComplete: Scalars['Boolean'];
  contactId?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<TxnAmount>;
  isOnChain?: InputMaybe<Scalars['Boolean']>;
  isTaxExempt?: InputMaybe<Scalars['Boolean']>;
};

export type TransferTransaction = {
  type: TransactionTypes;
  remoteSystemId?: InputMaybe<RemoteSystemId>;
  transactionId?: InputMaybe<TransactionId>;
  connectionId?: InputMaybe<Scalars['String']>;
  walletId: Scalars['String'];
  walletName: Scalars['String'];
  source: Sources;
  transferType: ExchangeTransferType;
  transferAmount: TxnAmount;
  createdSEC: Scalars['Int'];
  isComplete: Scalars['Boolean'];
  contactId: Scalars['String'];
  meta?: InputMaybe<CryptoExchangeTransferMeta>;
  fee?: InputMaybe<TxnAmount>;
  isOnChain: Scalars['Boolean'];
};

export type TradeTransaction = {
  type: TransactionTypes;
  remoteSystemId?: InputMaybe<RemoteSystemId>;
  connectionId?: InputMaybe<Scalars['String']>;
  walletId: Scalars['String'];
  walletName: Scalars['String'];
  source: Sources;
  tradeType: TradeType;
  acquiredAssets: Array<InputMaybe<TxnAmount>>;
  disposedAssets: Array<InputMaybe<TxnAmount>>;
  fees: Array<InputMaybe<TxnAmount>>;
  createdSEC: Scalars['Int'];
  isComplete: Scalars['Boolean'];
  contactId?: InputMaybe<Scalars['String']>;
};

export type BlockchainMovement = {
  from: BlockchainAddressPointer;
  to: BlockchainAddressPointer;
  amount: Array<InputMaybe<TxnAmount>>;
  fees?: InputMaybe<Array<InputMaybe<TxnAmount>>>;
};

export type BlockchainAddressPointer = {
  networkId: Scalars['String'];
  address: Scalars['String'];
};

export type CryptoExchangeTransferMeta = {
  cryptoTxId?: InputMaybe<Scalars['String']>;
  fromAddress?: InputMaybe<Scalars['String']>;
  toAddress?: InputMaybe<Scalars['String']>;
  externalStatus?: InputMaybe<Scalars['String']>;
  exchangeId?: InputMaybe<Scalars['String']>;
  balance?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  holdingPeriodCompletionDate?: InputMaybe<Scalars['String']>;
};

export type TxnAmount = {
  type?: InputMaybe<Scalars['String']>;
  amount: Scalars['String'];
  ticker: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
};

export type TransactionMovementDetails = {
  address: Scalars['String'];
  amount: Scalars['String'];
  ticker: Scalars['String'];
};

export type RemoteSystemId = {
  remoteId: Scalars['String'];
  source?: InputMaybe<Sources>;
};

export type TransactionId = {
  networkId: Scalars['String'];
  txId: Scalars['String'];
};

export enum ExchangeTransferType {
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
}

export enum TradeType {
  Buy = 'buy',
  Sell = 'sell',
}

export enum ManualTxnTypes {
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
  Other = 'other',
}

export enum Sources {
  Binance = 'binance',
  CoinbasePro = 'coinbasePro',
  CoinbaseCommerce = 'coinbaseCommerce',
  CoinbaseCustody = 'coinbaseCustody',
  Bitfinex = 'bitfinex',
  Bittrex = 'bittrex',
  Kraken = 'kraken',
  ItBit = 'itBit',
  Bitstamp = 'bitstamp',
  BitGo = 'bitGo',
  NyDig = 'nyDig',
  Ftx = 'ftx',
  Gemini = 'gemini',
  PrimeTrust = 'primeTrust',
  Custom = 'custom',
}

export enum TransactionTypes {
  Trade = 'trade',
  Manual = 'manual',
  Blockchain = 'blockchain',
  Transfer = 'transfer',
}

export type AdvanceDeFiLineNonPost = {
  __typename?: 'AdvanceDeFiLineNonPost';
  type: Scalars['String'];
  sourceTicker: Scalars['String'];
  sourceAmount: Scalars['String'];
  isNonVaultTracking?: Maybe<Scalars['Boolean']>;
  walletId?: Maybe<Scalars['String']>;
};

export type AdvanceDeFiLinePost = {
  __typename?: 'AdvanceDeFiLinePost';
  type: Scalars['String'];
  contactId: Scalars['String'];
  categoryId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sourceTicker: Scalars['String'];
  sourceAmount: Scalars['String'];
  fiat: Scalars['String'];
  fiatAmount: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
  isNonVaultTracking?: Maybe<Scalars['Boolean']>;
  walletId?: Maybe<Scalars['String']>;
};

export type AdvanceDeFiLine = AdvanceDeFiLineNonPost | AdvanceDeFiLinePost;

export type AdvanceDeFiItem = {
  __typename?: 'AdvanceDeFiItem';
  defiWalletId: Scalars['ID'];
  lines: Array<AdvanceDeFiLine>;
};

export type AdvanceDeFiTransactionDetail = {
  __typename?: 'AdvanceDeFiTransactionDetail';
  items: Array<AdvanceDeFiItem>;
  exchangeRates: Array<ExchangeRateDetails>;
  fees?: Maybe<Array<DetailedFee>>;
};

export type RequestCreateOrganizations = {
  name: Scalars['String'];
  test: Scalars['Boolean'];
  timezone: Scalars['String'];
  usePriceService?: InputMaybe<Scalars['Boolean']>;
  currency: Scalars['String'];
};

export type ResponseCreateOrganization = {
  __typename?: 'ResponseCreateOrganization';
  orgId: Scalars['String'];
};

export type IsValidAddress = {
  __typename?: 'IsValidAddress';
  isValid?: Maybe<Scalars['Boolean']>;
};

export type DisconnectResponse = {
  __typename?: 'DisconnectResponse';
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
};

export type ReconnectTokenResponse = {
  __typename?: 'ReconnectTokenResponse';
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
};

export enum NetsuiteCustomFieldRemoteType {
  IsoDate = 'isoDate',
  String = 'string',
  Float = 'float',
  Integer = 'integer',
  Ref = 'ref',
}

export enum NetsuiteCustomFieldBodyAccessor {
  TxnDate = 'txnDate',
  PaymentId = 'paymentId',
  ToWalletAddress = 'toWalletAddress',
  FromWalletAddress = 'fromWalletAddress',
  TxnId = 'txnId',
  ExchangeRate = 'exchangeRate',
  CryptoTicker = 'cryptoTicker',
  CryptoAmount = 'cryptoAmount',
}

export enum NetsuiteCustomFieldLineAccessor {
  ExchangeRate = 'exchangeRate',
  CryptoTicker = 'cryptoTicker',
  CryptoAmount = 'cryptoAmount',
}

export type NetsuiteBodyCustomField = {
  remoteId: Scalars['String'];
  remoteType: NetsuiteCustomFieldRemoteType;
  accessor: NetsuiteCustomFieldBodyAccessor;
  referenceMapping?: [{ key: string; value: string }];
};

export type NetsuiteLineCustomField = {
  remoteId: Scalars['String'];
  remoteType: NetsuiteCustomFieldRemoteType;
  accessor: NetsuiteCustomFieldLineAccessor;
  referenceMapping?: [{ key: string; value: string }];
};

export type NetsuiteCustomFieldAccessor = {
  key?: string | null;
  remoteId?: string | null;
  remoteType: string;
  metadataLabel: string;
  type?: string | null;
  referenceMapping?: [{ key: string; value: string }];
};

export type NetsuiteMetadataAccessorInput = {
  type: string;
  path: string;
  accessors: NetsuiteCustomFieldAccessor[];
};

export type NetsuiteSavedSearchInput = {
  type: string;
  savedSearchId: string;
  metadataAccessors: NetsuiteMetadataAccessorInput[];
};

export type NetsuiteSavedSearchesInput = {
  bills?: NetsuiteSavedSearchInput;
  invoices?: NetsuiteSavedSearchInput;
  vendors?: NetsuiteSavedSearchInput;
  customers?: NetsuiteSavedSearchInput;
};

// export type NetsuiteCustomRecordType = 'wallet';

export type NetsuiteCustomRecordItem = {
  remoteId: string;
  name: string;
  remoteType: string;
  extractRefId?: boolean;
  referenceMapping?: [{ key: string; value: string }];
};

export type NetsuiteCustomRecordInput = {
  type: string; // NetsuiteCustomRecordType;
  remoteId: string;
  name: string;
  scriptId: string;
  items: NetsuiteCustomRecordItem[];
};

export type UpdateNetsuiteAccountingConnectionInput = {
  concurrencyLimit?: InputMaybe<Scalars['Int']>;
  currencyRemoteId?: InputMaybe<Scalars['String']>;
  subsidiary?: InputMaybe<Scalars['String']>;
  customBodyFields?: InputMaybe<Array<NetsuiteBodyCustomField>>;
  customLineFields?: InputMaybe<Array<NetsuiteLineCustomField>>;
  defaultLocationId?: InputMaybe<Scalars['String']>;
  defaultDepartmentId?: InputMaybe<Scalars['String']>;
  defaultRevenueDepartmentId?: InputMaybe<Scalars['String']>;
  defaultExpenseDepartmentId?: InputMaybe<Scalars['String']>;
  defaultAssetDepartmentId?: InputMaybe<Scalars['String']>;
  defaultLiabilityDepartmentId?: InputMaybe<Scalars['String']>;
  savedSearches?: NetsuiteSavedSearchesInput | null;
  customRecords?: NetsuiteCustomRecordInput[] | null;
  defaultClassId?: InputMaybe<Scalars['String']>;
};

export type AccountingConnectionSpecificFields = {
  netsuite?: InputMaybe<UpdateNetsuiteAccountingConnectionInput>;
};

export enum Providers {
  Xero = 'Xero',
  QuickBooks = 'QuickBooks',
  NetSuite = 'NetSuite',
  SageIntacct = 'SageIntacct',
  Manual = 'Manual',
  Binance = 'Binance',
  Bitfinex = 'Bitfinex',
  Bittrex = 'Bittrex',
  Bitstamp = 'Bitstamp',
  Ftx = 'Ftx',
  Kraken = 'Kraken',
  ItBit = 'ItBit',
  Gemini = 'Gemini',
  BitGo = 'BitGo',
  Paxos = 'Paxos',
  NyDig = 'NyDig',
  Sfox = 'SFOX',
  PrimeTrust = 'PrimeTrust',
  CoinbasePro = 'CoinbasePro',
  CoinbaseCustody = 'CoinbaseCustody',
  CoinbaseCommerce = 'CoinbaseCommerce',
  Anchorage = 'Anchorage',
  CoinbasePrime = 'CoinbasePrime',
  Fireblocks = 'Fireblocks',
  CoinbaseRetail = 'CoinbaseRetail',
  Request = 'Request',
  CryptoDotCom = 'CryptoDotCom',
  Circle = 'Circle',
  Foundry = 'Foundry',
}

export enum ConnectionStatus {
  Ok = 'OK',
  Errored = 'Errored',
  Disabled = 'Disabled',
}

export enum ConnectionCategory {
  AccountingConnection = 'AccountingConnection',
  ExchangeConnection = 'ExchangeConnection',
  ExchangeConnectionPointer = 'ExchangeConnectionPointer',
}

export type Connection = {
  __typename?: 'Connection';
  id: Scalars['ID'];
  status: ConnectionStatus;
  provider: Providers;
  lastSyncSEC?: Maybe<Scalars['Int']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSetupComplete?: Maybe<Scalars['Boolean']>;
  syncStatus?: Maybe<ConnectionSyncStatus>;
  syncResults?: Maybe<Array<Maybe<ConnectionSyncResult>>>;
  name?: Maybe<Scalars['String']>;
  accountCode?: Maybe<Scalars['String']>;
  feeAccountCode?: Maybe<Scalars['String']>;
  connectionSpecificFields?: Maybe<Scalars['JSON']>;
  category?: Maybe<ConnectionCategory>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export enum JobStatus {
  Success = 'Success',
  NotSynced = 'NotSynced',
  SuccessWithWarnings = 'SuccessWithWarnings',
  Disabled = 'Disabled',
  Errored = 'Errored',
}

export type ConnectionSyncStatus = {
  __typename?: 'ConnectionSyncStatus';
  status: JobStatus;
  lastSyncCompletedSEC?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  warnings?: Maybe<Array<Maybe<Scalars['String']>>>;
  isRunning: Scalars['Boolean'];
};

export type ConnectionSyncResult = {
  __typename?: 'ConnectionSyncResult';
  status: JobStatus;
  completedSEC: Scalars['Int'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  warnings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Search = {
  __typename?: 'Search';
  facets: QueryFacets;
  exports: Array<Maybe<QueryExport>>;
  createExport: Scalars['String'];
};

export type SearchFacetsArgs = {
  orgId: Scalars['ID'];
};

export type SearchExportsArgs = {
  orgId: Scalars['ID'];
};

export type SearchCreateExportArgs = {
  orgId: Scalars['ID'];
};

export type QueryFacets = {
  __typename?: 'QueryFacets';
  wallets?: Maybe<Array<Maybe<QueryFacet>>>;
  assets?: Maybe<Array<Maybe<QueryFacet>>>;
  created?: Maybe<Array<Maybe<QueryFacet>>>;
};

export type QueryFacet = {
  __typename?: 'QueryFacet';
  key: Scalars['String'];
  docCount: Scalars['Int'];
};

export type QueryExport = {
  __typename?: 'QueryExport';
  name: Scalars['String'];
  downloadUrl: Scalars['String'];
};

export type InvoiceLine = {
  __typename?: 'InvoiceLine';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitAmount?: Maybe<Scalars['Float']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  type: InvoiceType;
  title: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  lines?: Maybe<Array<Maybe<InvoiceLine>>>;
  date?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  dueAmount?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  hasMatchedTransactions?: Maybe<Scalars['Boolean']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  lastUpdatedSEC?: Maybe<Scalars['Int']>;
};

export type InvoiceTransactionDetail = {
  __typename?: 'InvoiceTransactionDetail';
  invoices: Array<Maybe<InvoicePaymentLine>>;
  exchangeRates: Array<Maybe<ExchangeRateDetails>>;
  totalAmount: Scalars['BigNumber'];
  fees?: Maybe<Array<DetailedFee>>;
};

export type InvoicePaymentLine = {
  __typename?: 'InvoicePaymentLine';
  invoiceId: Scalars['ID'];
  amount: Scalars['BigNumber'];
  ticker: Scalars['String'];
  contactId: Scalars['ID'];
  forex?: Maybe<Forex>;
  coin?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  coinAmount?: Maybe<Scalars['BigNumber']>;
  walletId?: Maybe<Scalars['String']>;
  walletDisabled?: Maybe<Scalars['Boolean']>;
};

export type Forex = {
  __typename?: 'Forex';
  categoryId: Scalars['ID'];
  fiat: Fiat;
  amount: Scalars['BigNumber'];
};

export enum InvoiceType {
  Receiving = 'Receiving',
  Paying = 'Paying',
}

export enum InvoiceStatus {
  Draft = 'Draft',
  AwaitingApproval = 'AwaitingApproval',
  AwaitingPayment = 'AwaitingPayment',
  Paid = 'Paid',
  Rejected = 'Rejected',
  Other = 'Other',
}

export type TransactionData = {
  simple?: InputMaybe<SimpleTransactionInput>;
  transfer?: InputMaybe<TransferTransactionInput>;
  accountTransfer?: InputMaybe<AccountTransferTransactionInput>;
  staking?: InputMaybe<StakingTransactionInput>;
  assetStaking?: InputMaybe<AssetStakingTransactionInput>;
  deFiTransfer?: InputMaybe<DeFiTransferTransactionInput>;
  multivalue?: InputMaybe<MultiValueTransactionInput>;
  invoice?: InputMaybe<InvoiceTransactionInput>;
  trade?: InputMaybe<TradeTransactionInput>;
  advanceDeFi?: InputMaybe<AdvanceDeFiTransactionInput>;
  accountingConnectionId: Scalars['ID'];
  opsPreTransaction?: InputMaybe<PreCategorizeOpsTransactionInput>;
  editEtag?: Scalars['String'];
};

export enum CostBasisType {
  ExchangeRate = 'ExchangeRate',
  Invoice = 'Invoice',
  Manual = 'Manual',
  NoConversion = 'NoConversion',
}

export type CostBasisInput = {
  cost: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  currency: Scalars['String'];
  invoiceId?: InputMaybe<Scalars['ID']>;
  notes?: InputMaybe<Scalars['String']>;
  costBasisType: CostBasisType;
};

export enum ExchangeRateSource {
  Automatic = 'Automatic',
  Manual = 'Manual',
  Invoice = 'Invoice',
}

export enum InvoiceInputType {
  Bill = 'Bill',
  Invoice = 'Invoice',
}

export type ExchangeRateInput = {
  coin: Scalars['String'];
  unit: Scalars['String'];
  fiat: Scalars['String'];
  rate: Scalars['BigNumber'];
  source?: InputMaybe<ExchangeRateSource>;
  priceId?: InputMaybe<Scalars['String']>;
};

export type UncheckedAmountInput = {
  value: Scalars['BigNumber'];
  coin: Scalars['String'];
  unit: Scalars['String'];
};

export type FeeItemInput = {
  amount?: InputMaybe<UncheckedAmountInput>;
  costBasis: CostBasisInput;
};

export type SimpleTransactionInput = {
  description: Scalars['String'];
  categoryId: Scalars['ID'];
  contactId: Scalars['ID'];
  costBasis: CostBasisInput;
  exchangeRates?: InputMaybe<Array<ExchangeRateInput>>;
  fee?: InputMaybe<FeeItemInput>;
};

export type Fee = {
  amount: Scalars['Float'];
  feeContactId: Scalars['ID'];
};

export type TransferTransactionInput = {
  notes: Scalars['String'];
  feeCostBasis?: InputMaybe<CostBasisInput>;
  feeContactId: Scalars['String'];
};

export type UncheckedValueBase = {
  value: Scalars['BigNumber'];
  coin: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
};

export type AccountTransferTransactionInput = {
  description: Scalars['String'];
  otherAccountId: Scalars['ID'];
  direction: TransferDirection;
  notionalTransferValue: UncheckedValueBase;
  notionalFeeValue?: InputMaybe<UncheckedValueBase>;
  feeContactId?: InputMaybe<Scalars['String']>;
  exchangeRates?: InputMaybe<Array<InputMaybe<ExchangeRateInput>>>;
  metadata?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type StakingTransactionInput = {
  description: Scalars['String'];
  categoryId: Scalars['ID'];
  contactId: Scalars['ID'];
  costBasis: CostBasisInput;
  stakingTxnType?: InputMaybe<StakingTxnType>;
};

export type ValueInput = {
  value: Scalars['BigNumber'];
  symbol: Scalars['String'];
};

export type AssetStakingPoolDetailsInput = {
  addressPoolTokens: Scalars['BigNumber'];
  totalPoolTokens: Scalars['BigNumber'];
  percentOfPool: Scalars['BigNumber'];
};

export type BlockPointerInput = {
  networkId: Scalars['String'];
  blockId: Scalars['String'];
};

export type AddressPointerInput = {
  networkId: Scalars['String'];
  address: Scalars['String'];
};

export type DetailedMultiFeeInput = {
  feeItems?: InputMaybe<Array<FeeItemInput>>;
  feeContactId: Scalars['String'];
};

export type AssetStakingTransactionInput = {
  type: AssetStakingTxnType;
  stakedAssets: Array<ValueInput>;
  withdrawnAssets?: InputMaybe<Array<ValueInput>>;
  totalHoldings?: InputMaybe<Array<ValueInput>>;
  recognizedRevenue?: InputMaybe<Array<MultiValueTransactionItemInput>>;
  poolDetails?: InputMaybe<AssetStakingPoolDetailsInput>;
  block: BlockPointerInput;
  holdingAddress: AddressPointerInput;
  stakingTarget: AddressPointerInput;
  exchangeRates: Array<ExchangeRateInput>;
  fee?: InputMaybe<DetailedMultiFeeInput>;
};

export type DeFiTransferTransactionInput = {
  stakingTarget: AddressPointerInput;
  fromAddress: AddressPointerInput;
  toAddress: AddressPointerInput;
  fromTotalHoldings: Array<ValueInput>;
  toTotalHoldings: Array<ValueInput>;
  withdrawnAssets?: InputMaybe<Array<InputMaybe<ValueInput>>>;
  recognizedRevenue?: InputMaybe<Array<InputMaybe<MultiValueTransactionItemInput>>>;
  poolDetails?: InputMaybe<AssetStakingPoolDetailsInput>;
  block: BlockPointerInput;
  exchangeRates: Array<ExchangeRateInput>;
  fee?: InputMaybe<DetailedMultiFeeInput>;
};

export type AmountInput = {
  value: Scalars['String'];
  coin: Coins;
  unit: Units;
};

export type BulkCategorizeTransactionsDTO = {
  categorization: {
    multivalue?: {
      txnIds: Array<Scalars['String']>;
      feeContactId: Scalars['String'];
      feeCategoryId: Scalars['String'];
      sendContactId: Scalars['String'];
      sendCategoryId: Scalars['String'];
      receiveContactId: Scalars['String'];
      receiveCategoryId: Scalars['String'];
    };
    trade?: {
      txnIds: string[];
      feeContactId: Scalars['String'];
      feeCategoryId: Scalars['String'];
    };
    transfer?: {
      txnIds: string[];
      feeContactId: Scalars['String'];
      feeCategoryId: Scalars['String'];
    };
    accountingConnectionId: Scalars['String'];
  };
  options?: {
    overwriteExistingCategorization?: InputMaybe<Scalars['Boolean']>;
  };
};

export type PreCategorizeOpsTransactionInput = {
  name: Scalars['String'];
  payment: MultiValueTransactionInput;
  networkId: Scalars['String'];
  tokenContract: Scalars['String'];
  tokenTicker: Scalars['String'];
  status?: InputMaybe<PaymentStatusInput>;
  transactionId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

export type PaymentStatusInput = {
  created: Scalars['String'];
  sent?: InputMaybe<Scalars['String']>;
  synced?: InputMaybe<Scalars['Boolean']>;
};

export type MultiValueTransactionInput = {
  items: Array<MultiValueTransactionItemInput>;
  exchangeRates: Array<ExchangeRateInput>;
  metadataIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MultiValueTransactionItemInput = {
  contactId: Scalars['String'];
  transactionType: TransactionType;
  lines: Array<MultiValueTransactionLineInput>;
  metadataIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MultiValueTransactionLineInput = {
  categoryId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  sourceTicker: Scalars['String'];
  sourceAmount: Scalars['String'];
  txnLineId?: InputMaybe<Scalars['Int']>;
  walletId?: InputMaybe<Scalars['String']>;
  fiat: Scalars['String'];
  fiatAmount: Scalars['String'];
  metadataIds?: InputMaybe<Array<Scalars['ID']>>;
  address?: InputMaybe<Scalars['String']>;
  forceZeroGainLoss?: InputMaybe<Scalars['Boolean']>;
};

export type TradeAssetInput = {
  asset: UncheckedAmountInput;
  fairMarketValue: CostBasisInput;
};

export type TradeFeeInput = {
  assets: Array<TradeAssetInput>;
  feeContactId: Scalars['String'];
  walletId?: InputMaybe<Scalars['String']>;
};

export type TradeTransactionInput = {
  acquiredAssets: Array<TradeAssetInput>;
  disposedAssets: Array<TradeAssetInput>;
  exchangeRates: Array<ExchangeRateInput>;
  tradeFee?: InputMaybe<TradeFeeInput>;
  carryForwardCostBasis?: InputMaybe<Scalars['Boolean']>;
};

export type DetailedFeeInput = {
  amount?: InputMaybe<UncheckedAmountInput>;
  costBasis: CostBasisInput;
  feeContactId: Scalars['String'];
  walletId?: InputMaybe<Scalars['String']>;
};

export type ForexInput = {
  categoryId: Scalars['ID'];
  fiat: Fiat;
  amount: Scalars['BigNumber'];
};

export type InvoiceTransactionInput = {
  invoices: Array<InvoicePaymentLineInput>;
  exchangeRates: Array<ExchangeRateInput>;
  totalAmount: Scalars['BigNumber'];
  fees?: InputMaybe<Array<DetailedFeeInput>>;
  type: InvoiceInputType;
};

export type InvoicePaymentLineInput = {
  invoiceId?: InputMaybe<Scalars['ID']>;
  amountPaid: Scalars['BigNumber'];
  ticker: Scalars['String'];
  contactId: Scalars['ID'];
  sourceTicker: Scalars['String'];
  sourceAmount: Scalars['BigNumber'];
  forex?: InputMaybe<ForexInput>;
  walletId?: InputMaybe<Scalars['String']>;
  walletDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AdvanceDeFiTransactionInput = {
  items: Array<AdvanceDeFiItemInput>;
  exchangeRates: Array<ExchangeRateInput>;
  fees?: InputMaybe<Array<DetailedFeeInput>>;
};

export type AdvanceDeFiItemInput = {
  defiWalletId: Scalars['String'];
  postingLines?: InputMaybe<Array<AdvanceDeFiLinePostInput>>;
  nonpostingLines?: InputMaybe<Array<AdvanceDeFiLineNonPostInput>>;
};

export type AdvanceDeFiLineNonPostInput = {
  sourceTicker: Scalars['String'];
  sourceAmount: Scalars['String'];
  isNonVaultTracking?: InputMaybe<Scalars['Boolean']>;
};

export type AdvanceDeFiLinePostInput = {
  contactId: Scalars['String'];
  categoryId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  sourceTicker: Scalars['String'];
  sourceAmount: Scalars['String'];
  fiat: Scalars['String'];
  fiatAmount: Scalars['String'];
  metadataIds?: InputMaybe<Array<Scalars['ID']>>;
  isNonVaultTracking?: InputMaybe<Scalars['Boolean']>;
};

export type CreateContactInput = {
  connectionId: Scalars['String'];
  remoteId: Scalars['String'];
  name: Scalars['String'];
  type: ContactType;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
};

export type ContactAddressInput = {
  coin?: InputMaybe<Coins>;
  address: Scalars['String'];
  memo?: InputMaybe<Scalars['String']>;
  defaultRevenueCategoryId?: InputMaybe<['String']>;
  defaultExpenseCategoryId?: InputMaybe<['String']>;
  networkId: Scalars['String'];
};

export type UpdateContactInput = {
  id: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  connectionId?: InputMaybe<Scalars['String']>;
  defaultRevenueCategoryId?: InputMaybe<['String']>;
  defaultExpenseCategoryId?: InputMaybe<['String']>;
};

export enum MetaType {
  Segment = 'segment',
  Other = 'other',
}

export type Metadata = {
  __typename?: 'Metadata';
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  source: Scalars['String'];
  metaType: MetaType;
  name: Scalars['String'];
  remoteType: Scalars['String'];
  connectionId: Scalars['ID'];
};

export type Price = {
  __typename?: 'Price';
  price?: Maybe<Scalars['Float']>;
  timestampSec?: Maybe<Scalars['Int']>;
};

export enum PricingStatus {
  PricedSuccessfully = 'PricedSuccessfully',
  UnableToPrice = 'UnableToPrice',
  DownstreamServiceFailure = 'DownstreamServiceFailure',
}

export enum PriceDetailType {
  Candlestick = 'Candlestick',
  Override = 'Override',
  CoarseGrain = 'CoarseGrain',
}

export type PriceDetail = {
  price: Scalars['BigNumber'];
  type: PriceDetailType;
};

export type PriceDetailCandlestick = PriceDetail & {
  __typename?: 'PriceDetailCandlestick';
  price: Scalars['BigNumber'];
  type: PriceDetailType;
  open: Scalars['BigNumber'];
  close: Scalars['BigNumber'];
  high: Scalars['BigNumber'];
  low: Scalars['BigNumber'];
  volume?: Maybe<Scalars['BigNumber']>;
};

export type PriceDetailOverride = PriceDetail & {
  __typename?: 'PriceDetailOverride';
  price: Scalars['BigNumber'];
  type: PriceDetailType;
};

export type PriceDetailCoarseGrain = PriceDetail & {
  __typename?: 'PriceDetailCoarseGrain';
  price: Scalars['BigNumber'];
  type: PriceDetailType;
};

export type HistoricPriceResult = {
  __typename?: 'HistoricPriceResult';
  hasMore: Scalars['Boolean'];
  nextPageToken?: Maybe<Scalars['String']>;
  prices: Array<HistoricPrice>;
};

export enum PricingStepType {
  FallThrough = 'FallThrough',
  Match = 'Match',
}

export type PricingStep = {
  __typename?: 'PricingStep';
  detail?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  type: PricingStepType;
};

export type HistoricPrice = {
  timestampSEC: Scalars['Int'];
  status: PricingStatus;
  steps?: Maybe<Array<PricingStep>>;
};

export type HistoricPriceSuccess = HistoricPrice & {
  __typename?: 'HistoricPriceSuccess';
  timestampSEC: Scalars['Int'];
  price: PriceDetail;
  status: PricingStatus;
  steps: Array<PricingStep>;
};

export type HistoricPriceFailure = HistoricPrice & {
  __typename?: 'HistoricPriceFailure';
  timestampSEC: Scalars['Int'];
  status: PricingStatus;
  steps: Array<PricingStep>;
};

export enum PricingRuleApplication {
  BeforeDefault = 'BeforeDefault',
  AfterDefault = 'AfterDefault',
  SuppressDefault = 'SuppressDefault',
  Default = 'Default',
}

export enum PricingRuleActionType {
  UseDefault = 'UseDefault',
  PriceOverride = 'PriceOverride',
  OrderedSourceSearch = 'OrderedSourceSearch',
  PriceThroughAsset = 'PriceThroughAsset',
  RateTableLookup = 'RateTableLookup',
}

export enum PricingRuleActionSourceType {
  Binance = 'Binance',
  Coinbase = 'Coinbase',
  CoinGecko = 'CoinGecko',
  CryptoCompare = 'CryptoCompare',
  CoinMarketCap = 'CoinMarketCap',
  Fixer = 'Fixer',
  RateTable = 'RateTable',
}

export enum PricingRuleActionSourceExchange {
  None = 'None',
  Coinbase = 'Coinbase',
  Binance = 'Binance',
  BinanceUs = 'BinanceUS',
  Bitfinex = 'Bitfinex',
  Ftx = 'FTX',
  Ftxus = 'FTXUS',
  Kraken = 'Kraken',
  Gemini = 'Gemini',
  HuobiGlobal = 'HuobiGlobal',
  Poloniex = 'Poloniex',
  Kucoin = 'Kucoin',
  Bitvavo = 'Bitvavo',
  Bit_com = 'Bit.com',
  MEXC = 'MEXC',
}

export type PricingRuleActionSourceInput = {
  type: PricingRuleActionSourceType;
  exchange?: InputMaybe<PricingRuleActionSourceExchange>;
  conversionAssetTicker?: Maybe<Scalars['String']>;
  conversionPricingDirective?: Maybe<ConversionPricingDirective>;
  fiatToCoinTickerMapping?: InputMaybe<Scalars['JSON']>;
  rateTableId?: Maybe<Scalars['String']>;
  sourceLocatorId?: Maybe<Scalars['String']>;
  sourceLocator?: Maybe<Scalars['String']>;
};

export type PricingRuleActionInput = {
  type: PricingRuleActionType;
  price?: InputMaybe<Scalars['Float']>;
  sourceOrdering?: InputMaybe<Array<InputMaybe<PricingRuleActionSourceInput>>>;
  overrideTicker?: InputMaybe<Scalars['String']>;
  percentage?: InputMaybe<Scalars['String']>;
};

export type PricingRuleInput = {
  id?: InputMaybe<Scalars['String']>;
  assetId: Scalars['String'];
  priority: Scalars['Int'];
  application: PricingRuleApplication;
  action: PricingRuleActionInput;
  fromSEC: Scalars['BigNumber'];
  toSEC?: InputMaybe<Scalars['BigNumber']>;
};

export type PricingRuleActionSource = {
  __typename?: 'PricingRuleActionSource';
  type: PricingRuleActionSourceType;
  exchange?: Maybe<PricingRuleActionSourceExchange>;
  conversionAssetTicker?: Maybe<Scalars['String']>;
  conversionPricingDirective?: Maybe<ConversionPricingDirective>;
  fiatToCoinTickerMapping?: Maybe<Scalars['JSON']>;
  rateTableId?: Maybe<Scalars['String']>;
  sourceLocatorId?: Maybe<Scalars['String']>;
  sourceLocator?: Maybe<Scalars['String']>;
};

export type ConversionPricingDirective = Maybe<Scalars['JSON']>;

export type PricingRuleAction = {
  __typename?: 'PricingRuleAction';
  type: PricingRuleActionType;
  price?: Maybe<Scalars['Float']>;
  sourceOrdering?: Maybe<Array<Maybe<PricingRuleActionSource>>>;
  overrideTicker?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['String']>;
};

export type PricingRule = {
  __typename?: 'PricingRule';
  id: Scalars['String'];
  assetId: Scalars['String'];
  ticker?: Maybe<Scalars['String']>;
  priority: Scalars['Int'];
  application: PricingRuleApplication;
  action: PricingRuleAction;
  fromSEC: Scalars['BigNumber'];
  toSEC: Scalars['BigNumber'];
};

export enum PricingRuleResolution {
  Minute = 'Minute',
  Minute5 = 'Minute5',
  Minute10 = 'Minute10',
  Minute15 = 'Minute15',
  Hour = 'Hour',
  Hour12 = 'Hour12',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export enum PricingMethodology {
  Open = 'Open',
  Close = 'Close',
  High = 'High',
  Low = 'Low',
}

export type DefaultPricingRuleInput = {
  resolution: PricingRuleResolution;
  action: PricingRuleActionInput;
  methodology?: InputMaybe<PricingMethodology>;
};

export type DefaultPricingRule = {
  __typename?: 'DefaultPricingRule';
  resolution: PricingRuleResolution;
  action: PricingRuleAction;
  methodology?: Maybe<PricingMethodology>;
};

export type JobResponse = {
  __typename?: 'JobResponse';
  items: Array<Maybe<Job>>;
  nextPageToken?: Maybe<Scalars['String']>;
};

export enum SystemJobStatus {
  New = 'new',
  Running = 'running',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Succeeded = 'succeeded',
  SucceededWithWarnings = 'succeededWithWarnings',
}

export type JobStep = {
  __typename?: 'JobStep';
  id: Scalars['ID'];
  status: SystemJobStatus;
  description: Scalars['String'];
  startedSEC?: Maybe<Scalars['Int']>;
  endedSEC?: Maybe<Scalars['Int']>;
  successMessage?: Maybe<Scalars['String']>;
  errorMessages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Job = {
  __typename?: 'Job';
  id: Scalars['ID'];
  createdBy?: Maybe<UserPointer>;
  status: SystemJobStatus;
  createSEC?: Maybe<Scalars['Int']>;
  startedSEC?: Maybe<Scalars['Int']>;
  endedSEC?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  steps: Array<Maybe<JobStep>>;
};

export type RegisterLine = {
  __typename?: 'RegisterLine';
  transactionId: Scalars['String'];
  createdSEC: Scalars['Int'];
  memo: Scalars['String'];
  hasDiscussion: Scalars['Boolean'];
  ticker: Scalars['String'];
  paymentAmount?: Maybe<Scalars['BigNumber']>;
  depositAmount?: Maybe<Scalars['BigNumber']>;
  balance: Scalars['BigNumber'];
};

export type RegisterResponse = {
  __typename?: 'RegisterResponse';
  items: Array<Maybe<RegisterLine>>;
};

export type RegisterFilter = {
  walletId: Scalars['ID'];
  currencyId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
};

export type WalletInput = {
  name: Scalars['String'];
  type: Scalars['String'];
  subsidiaryId?: InputMaybe<Scalars['String']>;
  accountingConnection?: InputMaybe<Scalars['String']>;
  defaultInflowContact?: InputMaybe<Scalars['String']>;
  defaultInflowCategory?: InputMaybe<Scalars['String']>;
  defaultOutflowContact?: InputMaybe<Scalars['String']>;
  defaultOutflowCategory?: InputMaybe<Scalars['String']>;
  defaultFeeContact?: InputMaybe<Scalars['String']>;
  defaultFeeCategory?: InputMaybe<Scalars['String']>;
  watches?: InputMaybe<Array<InputMaybe<Watch>>>;
  watch?: InputMaybe<Watch>;
  eh?: InputMaybe<EnterpriseWalletInput>;
  multisig?: InputMaybe<MultisigWallet>;
  hardware?: InputMaybe<HardwareWallet>;
  manual?: InputMaybe<ManualWallet>;
  user?: InputMaybe<UserWallet>;
  addressBasedBlockchain?: InputMaybe<AddressWallet>;
  accountBasedBlockchain?: InputMaybe<NetworkAddressWallet>;
  group?: InputMaybe<GroupWallet>;
  lrcMarketplace?: InputMaybe<LrcMarketplaceWallet>;
  defi?: InputMaybe<DefiWalletInput>;
  flags?: InputMaybe<WalletFlags>;
};

export type EnterpriseWalletInput = {
  cosigners: Array<InputMaybe<Scalars['ID']>>;
  minCosigners: Scalars['Int'];
};

export type DefiWalletInput = {
  connectionId?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  networkId: Scalars['String'];
  walletAddress: Scalars['String'];
  vaultAddress: Scalars['String'];
  tokenId?: InputMaybe<Scalars['String']>;
  isSyncEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type LrcMarketplaceWallet = {
  description?: InputMaybe<Scalars['String']>;
  affiliateId: Scalars['Int'];
  submitterId: Scalars['Int'];
  rollupConfig?: InputMaybe<RollupConfig>;
  groupId?: InputMaybe<Scalars['String']>;
  connectionId?: InputMaybe<Scalars['String']>;
};

export enum RollupSelector {
  Revenue = 'revenue',
  Fees = 'fees',
  Custom = 'custom',
}

export enum RollupPeriod {
  Hour = 'hour',
  Day = 'day',
  Month = 'month',
}

export type RollupConfig = {
  rollupSelector: RollupSelector;
  rollupPeriod: RollupPeriod;
};

export type AddressWallet = {
  address: Scalars['String'];
  rollupConfig?: InputMaybe<RollupConfig>;
};

export type NetworkAddressWallet = {
  address: Scalars['String'];
  networkId: Scalars['String'];
  rollupConfig?: InputMaybe<RollupConfig>;
  metadata?: InputMaybe<Scalars['JSON']>;
};

export type UserWallet = {
  networkId: Scalars['String'];
  address: Scalars['String'];
  connectType: Scalars['String'];
};

export type ManualWallet = {
  description?: InputMaybe<Scalars['String']>;
};

export type GroupWallet = {
  description?: InputMaybe<Scalars['String']>;
  children?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum MultisigWalletMode {
  ElectrumLegacyBtc = 'ElectrumLegacyBTC',
  ElectrumP2ShSegwitBtc = 'ElectrumP2SHSegwitBTC',
  ElectrumSegwitBtc = 'ElectrumSegwitBTC',
  Bitcoincom = 'Bitcoincom',
  Custom = 'custom',
}

export enum DerivationModes {
  Legacy = 'legacy',
  P2shSegwit = 'p2shSegwit',
  NativeSegwit = 'nativeSegwit',
}

export type MultisigWallet = {
  mode: MultisigWalletMode;
  minCosigners: Scalars['Int'];
  hds: Array<InputMaybe<Scalars['String']>>;
  customUseChange?: InputMaybe<Scalars['Boolean']>;
  customSortPublicKeys?: InputMaybe<Scalars['Boolean']>;
  customPrependPath?: InputMaybe<Scalars['String']>;
  customDerivationMode?: InputMaybe<DerivationModes>;
};

export enum HardwareDevices {
  Ledger = 'Ledger',
  Trezor = 'Trezor',
}

export type HardwareWallet = {
  coin?: InputMaybe<Scalars['String']>;
  deviceType: HardwareDevices;
  path: Scalars['String'];
  address?: InputMaybe<Scalars['String']>;
  hd?: InputMaybe<Scalars['String']>;
  derivationKey?: InputMaybe<Scalars['String']>;
};

export type Watch = {
  type: Scalars['String'];
  coin: Coins;
  address?: InputMaybe<Scalars['String']>;
  addresses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  derivationKey?: InputMaybe<Scalars['String']>;
};

export type WalletPermissionInput = {
  userId: Scalars['String'];
  role: Scalars['Int'];
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export type InventoryGroup = {
  id?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
  walletIdsToInventoryIds?: Maybe<Array<WalletIdToInventoryId>>;
  inventoryIdsToGlAccountIds?: Maybe<Array<inventoryIdToGlAccountId>>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  accountingConnectionIds?: Maybe<Array<Scalars['String']>>;
};

export type inventoryIdToGlAccountId = {
  inventoryId: Scalars['String'];
  glAccountId: Scalars['String'];
};

export type WalletIdToInventoryId = {
  walletId: Scalars['String'];
  inventoryId: Scalars['String'];
};

export type Inventory = {
  id?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
  inventoryGroupId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export interface WalletsFlags {
  solanaInflationStakingRewardsEnabled?: Maybe<Scalars['Boolean']>;
  solanaInflationStakingRewardsStartTime?: Maybe<Scalars['Int']>;
  solanaInflationStakingRewardsEndTime?: Maybe<Scalars['Int']>;
  solanaHistoricalAccountEnabled?: Maybe<Scalars['Boolean']>;
  syncStartDateSEC?: Maybe<Scalars['Int']>;
  syncEndDateSEC?: Maybe<Scalars['Int']>;
  syncerVersion?: Maybe<Scalars['Int']>;
  upsertV2?: Maybe<Scalars['Boolean']>;
  trackingMode?: Maybe<Scalars['String']>;
}

export enum ScopeLiterals {
  Manage = 'urn:orgs:$orgId:**',

  DashboardRead = 'urn:orgs:$orgId:dashboard:read',
  DeFiDashboardRead = 'urn:orgs:$orgId:defi:read',

  WalletsCreate = 'urn:orgs:$orgId:wallets:create',
  WalletsRead = 'urn:orgs:$orgId:wallets:read',
  WalletsUpdate = 'urn:orgs:$orgId:wallets:update',
  WalletsDelete = 'urn:orgs:$orgId:wallets:delete',

  ConnectionsCreate = 'urn:orgs:$orgId:connections:create',
  ConnectionsRead = 'urn:orgs:$orgId:connections:read',
  ConnectionsUpdate = 'urn:orgs:$orgId:connections:update',
  ConnectionsDelete = 'urn:orgs:$orgId:connections:delete',

  TransactionsCreate = 'urn:orgs:$orgId:wallets:$walletId:transactions:create',
  TransactionsRead = 'urn:orgs:$orgId:wallets:$walletId:transactions:read',
  TransactionsUpdate = 'urn:orgs:$orgId:wallets:$walletId:transactions:update',
  TransactionsDelete = 'urn:orgs:$orgId:wallets:$walletId:transactions:delete',

  TransactionCategorizeRead = 'urn:orgs:$orgId:wallets:$walletId:transaction-categorize:read',
  TransactionCategorizeUpdate = 'urn:orgs:$orgId:wallets:$walletId:transaction-categorize:update',

  TransactionReconcileRead = 'urn:orgs:$orgId:wallets:$walletId:transaction-reconcile:read',
  TransactionReconcileUpdate = 'urn:orgs:$orgId:wallets:$walletId:transaction-reconcile:update',

  TransactionRegisterRead = 'urn:orgs:$orgId:reports:register:read',

  RulesCreate = 'urn:orgs:$orgId:wallets:$walletId:rules:create',
  RulesRead = 'urn:orgs:$orgId:wallets:$walletId:rules:read',
  RulesUpdate = 'urn:orgs:$orgId:wallets:$walletId:rules:update',
  RulesDelete = 'urn:orgs:$orgId:wallets:$walletId:rules:delete',

  TransactionPricingUpdate = 'urn:orgs:$orgId:wallets:$walletId:transaction-pricing:update',

  ImportCreate = 'urn:orgs:$orgId:transaction-import:create',
  ImportRead = 'urn:orgs:$orgId:transaction-import:read',

  ReportsAll = 'urn:orgs:$orgId:reports:*:read',
  ReportsBalanceRead = 'urn:orgs:$orgId:reports:balance:read',
  ReportsJournalRead = 'urn:orgs:$orgId:reports:journal:read',
  ReportsExportRead = 'urn:orgs:$orgId:reports:export:read',
  ReportsLedgerRead = 'urn:orgs:$orgId:reports:ledger:read',
  ReportsBalanceCheckRead = 'urn:orgs:$orgId:reports:ledger:read',

  CategoriesCreate = 'urn:orgs:$orgId:categories:create',
  CategoriesRead = 'urn:orgs:$orgId:categories:read',
  CategoriesUpdate = 'urn:orgs:$orgId:categories:update',
  CategoriesDelete = 'urn:orgs:$orgId:categories:delete',

  ContactsCreate = 'urn:orgs:$orgId:contacts:create',
  ContactsRead = 'urn:orgs:$orgId:contacts:read',
  ContactsUpdate = 'urn:orgs:$orgId:contacts:update',
  ContactsDelete = 'urn:orgs:$orgId:contacts:delete',

  CostBasisCreate = 'urn:orgs:$orgId:costBasis:create',
  CostBasisRead = 'urn:orgs:$orgId:costBasis:read',
  CostBasisUpdate = 'urn:orgs:$orgId:costBasis:update',
  CostBasisDelete = 'urn:orgs:$orgId:costBasis:delete',

  PeriodCloseRead = 'urn:orgs:$orgId:periodClose:read',
  PeriodCloseCreate = 'urn:orgs:$orgId:periodClose:create',
  PeriodCloseUpdate = 'urn:orgs:$orgId:periodClose:update',
  PeriodCloseDelete = 'urn:orgs:$orgId:periodClose:delete',

  CoinInventoryRead = 'urn:orgs:$orgId:coinInventory:read',

  GainLossCreate = 'urn:orgs:$orgId:gain-loss:create',
  GainLossRead = 'urn:orgs:$orgId:gain-loss:read',
  GainLossDelete = 'urn:orgs:$orgId:gain-loss:delete',

  InventoryViewCreate = 'urn:orgs:$orgId:inventory-views:create',
  InventoryViewList = 'urn:orgs:$orgId:inventory-views:list', // This is a new pattern to account for permission restriction to view a single view
  InventoryViewRead = 'urn:orgs:$orgId:inventory-views:read',
  InventoryViewUpdate = 'urn:orgs:$orgId:inventory-views:update',
  InventoryViewDelete = 'urn:orgs:$orgId:inventory-views:delete',

  InvoicesCreate = 'urn:orgs:$orgId:invoices:create',
  InvoicesRead = 'urn:orgs:$orgId:invoices:read',
  InvoicesUpdate = 'urn:orgs:$orgId:invoices:update',
  InvoicesDelete = 'urn:orgs:$orgId:invoices:delete',

  BillsCreate = 'urn:orgs:$orgId:bills:create',
  BillsRead = 'urn:orgs:$orgId:bills:read',
  BillsUpdate = 'urn:orgs:$orgId:bills:update',
  BillsDelete = 'urn:orgs:$orgId:bills:delete',

  CryptoBillsCreate = 'urn:orgs:$orgId:crypto-bills:create',
  CryptoBillsRead = 'urn:orgs:$orgId:crypto-bills:read',
  CryptoBillsUpdate = 'urn:orgs:$orgId:crypto-bills:update',
  CryptoBillsDelete = 'urn:orgs:$orgId:crypto-bills:delete',

  BillPaymentsCreate = 'urn:orgs:$orgId:billPayments:create',
  BillPaymentsRead = 'urn:orgs:$orgId:billPayments:read',
  BillPaymentsUpdate = 'urn:orgs:$orgId:billPayments:update',
  BillPaymentsDelete = 'urn:orgs:$orgId:billPayments:delete',

  PricingHistoryRead = 'urn:orgs:$orgId:pricing:read',

  PricingRulesCreate = 'urn:orgs:$orgId:pricingRules:create',
  PricingRulesRead = 'urn:orgs:$orgId:pricingRules:read',
  PricingRulesUpdate = 'urn:orgs:$orgId:pricingRules:update',

  OrgRead = 'urn:orgs:$orgId:org:read',
  OrgUpdate = 'urn:orgs:$orgId:org:update',

  SubsidiariesRead = 'urn:orgs:$orgId:subsidiaries:read',
  SubsidiariesCreate = 'urn:orgs:$orgId:subsidiaries:create',

  AccountingSetupRead = 'urn:orgs:$orgId:accountingSetup:read',
  AccountingSetupUpdate = 'urn:orgs:$orgId:accountingSetup:update',

  AccountingConnectionRead = 'urn:orgs:$orgId:accountingConnection:read',
  AccountingConnectionCreate = 'urn:orgs:$orgId:accountingConnection:create',
  AccountingConnectionUpdate = 'urn:orgs:$orgId:accountingConnection:update',

  WrappingTreatmentCreate = 'urn:orgs:$orgId:wrappingTreatment:create',
  WrappingTreatmentRead = 'urn:orgs:$orgId:wrappingTreatment:read',
  WrappingTreatmentUpdate = 'urn:orgs:$orgId:wrappingTreatment:update',
  WrappingTreatmentDelete = 'urn:orgs:$orgId:wrappingTreatment:delete',

  TaxStrategyRead = 'urn:orgs:$orgId:taxStrategy:read',
  TaxStrategyUpdate = 'urn:orgs:$orgId:taxStrategy:update',

  InvoicingSettingsRead = 'urn:orgs:$orgId:invoicingSettings:read',
  InvoicingSettingsUpdate = 'urn:orgs:$orgId:invoicingSettings:update',

  SystemJobsCreate = 'urn:orgs:$orgId:systemJobs:create',
  SystemJobsRead = 'urn:orgs:$orgId:systemJobs:read',

  TokenFilteringRead = 'urn:orgs:$orgId:tokenFiltering:read',
  TokenFilteringCreate = 'urn:orgs:$orgId:tokenFiltering:create',
  TokenFilteringUpdate = 'urn:orgs:$orgId:tokenFiltering:update',
  TokenFilteringDelete = 'urn:orgs:$orgId:tokenFiltering:delete',

  UsersCreate = 'urn:orgs:$orgId:users:create',
  UsersRead = 'urn:orgs:$orgId:users:read',
  UsersUpdate = 'urn:orgs:$orgId:users:update',
  UsersDelete = 'urn:orgs:$orgId:users:delete',

  RolesCreate = 'urn:orgs:$orgId:roles:create',
  RolesRead = 'urn:orgs:$orgId:roles:read',
  RolesUpdate = 'urn:orgs:$orgId:roles:update',
  RolesDelete = 'urn:orgs:$orgId:roles:delete',

  LongRunningTaskCreate = 'urn:long-running-tasks:create',

  WalletRead = 'urn:orgs:$orgId:wallets:$walletId:read:balance',

  SsoRead = 'urn:orgs:$orgId:sso:read',
  SsoUpdate = 'urn:orgs:$orgId:sso:update',

  Scim = 'urn:orgs:$orgId:scim',

  ApiCreate = 'urn:orgs:$orgId:api:create',
  ApiRead = 'urn:orgs:$orgId:api:read',
  ApiDelete = 'urn:orgs:$orgId:api:delete',

  DataExplore = 'urn:orgs:$orgId:data:read',

  DataReconciliationRead = 'urn:orgs:$orgId:data-reconciliation:read',
  DataReconciliationCreate = 'urn:orgs:$orgId:data-reconciliation:create',

  DataFeedsRead = 'urn:orgs:$orgId:data-feeds:read',
  DataFeedsCreate = 'urn:orgs:$orgId:data-feeds:create',

  DataRollupsRead = 'urn:orgs:$orgId:data-rollups:read',
  DataTransformsRead = 'urn:orgs:$orgId:data-transforms:read',
  DataRollupsCreate = 'urn:orgs:$orgId:data-rollups:create',

  DataLoadRead = 'urn:orgs:$orgId:data-load:read',
  DataLoadCreate = 'urn:orgs:$orgId:data-load:create',

  DataSchemasRead = 'urn:orgs:$orgId:data-schemas:read',
  DataSchemasCreate = 'urn:orgs:$orgId:data-schemas:create',

  DataImportRead = 'urn:orgs:$orgId:data-import:read',
  DataImportCreate = 'urn:orgs:$orgId:data-import:create',

  DataSourcesRead = 'urn:orgs:$orgId:data-sources:read',
  DataSourcesCreate = 'urn:orgs:$orgId:data-sources:create',

  ClientsRead = 'urn:orgs:$orgId:clients:read',
  Clients1099Read = 'urn:orgs:$orgId:clients-1099:read',

  OpsRead = 'urn:orgs:$orgId:ops:read',
  EnterprisePaymentsRead = 'urn:orgs:$orgId:enterprise-payments:read',
  ProjectRead = 'urn:orgs:$orgId:project:read',
}
